/**
 * This file contains the application's variables.
 *
 * Define color, sizes, etc. here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

export const colorPalette = {
  neutral: {
    50: '#F6F6FB',
    100: '#EFEFF6',
    200: '#D3D3DC',
    300: '#A0A2B3',
    400: '#717488',
    600: '#474B5E',
    700: '#101435',
    800: '#04092C',
    White: '#FFFFFF',
  },
  white: {
    50: '#F6F6FB',
    100: '#EFEFF6',
    200: '#D3D3DC',
    300: '#A0A2B3',
    400: '#717488',
    600: '#474B5E',
    700: '#101435',
    800: '#04092C',
    1000: '#FFFFFF',
    white: '#FFFFFF',
  },
  //primary - Indigo
  indigo: {
    100: '#F2F0FF',
    200: '#D9D6FD',
    300: '#A69FE2',
    350: '#8477D9',
    400: '#5D50CB',
    500: '#4038A6',
    600: '#312784',
    800: '#211A58',
    indigo: '#211A58',
  },
  magenta: {
    100: '#FBE5F3',
    200: '#F7B4DF',
    300: '#E07BBD',
    400: '#CE3197',
    600: '#8F1564',
    800: '#440C36',
    magenta: '#440C36',
  },
  red: {
    100: '#FEECEC',
    200: '#FFB1BA',
    300: '#FF7079',
    400: '#F93C3A',
    600: '#DC1818',
    800: '#9D0706',
    red: '#9D0706',
  },
  green: {
    100: '#E6FFED',
    200: '#BEF2CD',
    300: '#85E8A1',
    400: '#4AC76D',
    600: '#19AA61',
    800: '#008442',
    green: '#008442',
  },
  yellow: {
    100: '#FFF9D6',
    200: '#FFF099',
    300: '#FFE75C',
    400: '#FCD72D',
    600: '#FFC000',
    800: '#E8A93A',
    yellow: '#E8A93A',
  },
  cobalt: {
    100: '#D6E6FD',
    400: '#277CF4',
    600: '#0951B6',
    cobalt: '#E8A93A',
  },
};

/**
 * Colors
 */
export const Colors = {
  transparent: 'rgba(0,0,0,0)',
  background: colorPalette.neutral[700], //Background (i.e., App Background color)
  backgroundSecondary: colorPalette.neutral[600], //Secondary Background (i.e., Section Background color)
  primary: colorPalette.indigo[400], //Primary Highlight (Default Button, Icons)
  secondary: colorPalette.indigo[350], //Secondary Highlight (Event Dates, Navigation Icons)
  text: colorPalette.neutral.White, //Primary Text
  textSecondary: colorPalette.neutral[200], //Secondary Text (i.e., Form Labels, Helper Text, Inactive Nav Baretc.)
  disabled: colorPalette.neutral[300], //Disabled
  success: colorPalette.green[400], //Success/ Valid (i.e., Text/ Graphic Success)
  warning: colorPalette.yellow[400], //Error (i.e., Text/ Graphic Error)
  error: colorPalette.red[400], //Warning (i.e., Text/ Graphic Warning)
  inactive: colorPalette.neutral[300], //Favorite Icon Fill Color
  border: colorPalette.neutral[600], //Border Color
  solidWhite: colorPalette.neutral.White,
  inactiveSecondary: colorPalette.neutral[400],
  inactiveOpacity: 'rgba(71, 75, 94, 0.5)',
  activeOpacity: 'rgba(255, 255, 255, 0.7)',
  transparentBackground: colorPalette.indigo[800] + 'BB', // transparent background (i.e for sheets and menus background)
  transparentBackgroundSecondary: 'rgba(255, 255, 255, 0.1)', // transparent secondary background i.e. text container in shop card, search background color
  transparentBackgroundActive: 'rgba(255, 255, 255, 0.2)', // transparent active background i.e. text input is filled
  darkTransparency: 'rgba(4, 9, 44, 0.5)',
  ...colorPalette,
};

export const NavigationColors = {
  primary: colorPalette.indigo[300], // active and primary color are the same
  active: colorPalette.indigo[300],
  inactive: colorPalette.neutral[300],
  text: colorPalette.neutral.White,
  textInactive: colorPalette.neutral[300],
  notification: colorPalette.indigo[350],
  // background, card, and border have the same color
  background: colorPalette.neutral[700],
  card: colorPalette.neutral[700],
  border: colorPalette.neutral[700],
};

/**
 * FontSize
 */
export const FontSize = {
  xs: 8,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 18,
  xxl: 20,
  xxxl: 24,
  xxxxl: 32,
  xxxxxl: 40,
};

/**
 * Metrics Sizes
 */

const xxxs = 2;
const xxs = 4;
const xs = 8;
const sm = 12;
const md = 16;
const lg = 20;
const xl = 24;
const xxl = 28;
const xxxl = 32;
const xxxxl = 36;
const xxxxxl = 40;

export const MetricsSizes = {
  xxxs,
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  xxxl,
  xxxxl,
  xxxxxl,
};

export const AspectRatios = {
  landscape: 1.77,
};

export default {
  Colors,
  NavigationColors,
  FontSize,
  MetricsSizes,
  AspectRatios,
};
