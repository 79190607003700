import { View } from 'react-native';
import { LeagueSection } from './LeagueSection';

export function GameCenterLeagues() {
  return (
    <View>
      <LeagueSection />
    </View>
  );
}
