import { Image, StyleSheet, View } from 'react-native';
import Margin from './Margin';
import { colorPalette } from '../themes/variables';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import { Info } from './images';

export function GameCenterContainer({
  children,
  shortHeader = false,
  showRulesButton = false,
}: {
  children: React.ReactNode;
  shortHeader?: boolean;
  showRulesButton?: boolean;
}) {
  const tandcLink = 'https://gamecenter.vividseats.com/tandc';
  return (
    <View style={styles.mainContainer}>
      <Image
        source={{
          uri: 'https://media.vsstatic.com/image/upload/w_375,dpr_2,q_auto/v1685043110/Product/app/features/free-to-play/backgrounds/SpotlightBackgroundImage_tall2_y9edka_gxx6dc.png',
        }}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          maxWidth: 475,
          width: '100%',
          height: '100%',
          aspectRatio: 6.698,
          zIndex: 0,
        }}
      />
      {shortHeader ? (
        <>
          <Margin height={90} />
          <Image
            source={{
              uri: 'https://media.vsstatic.com/image/upload/w_375,dpr_2,q_auto/v1691898158/Product/app/features/game-center/headers/Logo-gc_uoacdm.png',
            }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: undefined,
              aspectRatio: 5.21,
              zIndex: 0,
            }}
          />
        </>
      ) : (
        <>
          <Margin height={210} />
          <Image
            source={{
              uri: 'https://media.vsstatic.com/image/upload/w_375,dpr_2,q_auto/v1691767627/Product/app/features/game-center/headers/game-center-2x_oqjeuj.png',
            }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: undefined,
              aspectRatio: 2.04,
              zIndex: 0,
            }}
          />
        </>
      )}
      {showRulesButton && (
        <BiggerTouchableOpacity
          onPress={() => {
            window.open(tandcLink);
          }}
          style={{
            position: 'absolute',
            top: 30,
            right: 12,
          }}
        >
          <Info width={24} height={24} tintColor="white" />
        </BiggerTouchableOpacity>
      )}
      <View style={{ width: '100%', paddingHorizontal: 16, paddingBottom: 32 }}>{children}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    flexDirection: 'column',
    maxWidth: 475,
    justifySelf: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 475,
  },
});
