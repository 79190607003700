import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ActivityIndicator, Animated, StyleSheet, TextInput, View } from 'react-native';
import { WindowContext, queryClient } from '../../App';
import { Entry, Play, PlayRequest, PickColors, Game } from '../../interfaces';
import { postLitePlay } from '../../api';
import { Check, Close } from './../images';
import DText from './../DText';
import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import { colorPalette } from '../../themes/variables';
import { GradientCountdownCircle } from './../GradientCountdownCircle';
import BiggerTouchableOpacity from './../BiggerTouchableOpacity';
import { SlideInView } from './../SlideInView';
import Margin from '../Margin';
import useInactivityDetector from './hooks/useInactivityDetector';

const padNumber = (num: number) => {
  return num < 10 ? '0' + num : num;
};

export function TriviaGameModal({
  game,
  setHasResults,
}: {
  game: Game;
  setHasResults: (results: boolean) => void;
}) {
  // How long each tick is in seconds
  const TICK_RATE = 1;
  // Amount of time before the game starts
  const getReadyTime = 3;
  const [goSeconds, setGoSeconds] = useState(getReadyTime);

  // Amount of time per question
  const questionTimeMax = 10;
  const [questionTime, setQuestionTime] = useState(questionTimeMax);

  // This gets set to true when the go seconds time is 0 (the game starts, which triggers question timers)
  const [startGame, setStartGame] = useState(false);

  // This is the play objecct that will eventually get filled and sent to the server
  const [play, setPlay] = useState<PlayRequest & { phone?: string }>({
    gameId: game.id,
    entries: [],
  });

  // This determines whether or not to show the countdown card at the beginning
  const [showCountdownCard, setShowCountdownCard] = useState(true);

  // This state manages whether or not the play record is being submitted to the server
  const [submitting, setSubmitting] = useState(false);
  // If there was an error submitting the play
  // const [submitError, _setSubmitError] = useState<string | null>(null);

  // The current question index to render, always start at index 0.
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  // The list of questions from the game definition
  const questions = useMemo(() => game.definition.questions, [game]);

  // The current question based on the index
  const currentQuestion = useMemo(
    () => questions[currentQuestionIndex],
    [currentQuestionIndex, questions],
  );

  // The answer options for that current question
  const answerOptions = useMemo(() => currentQuestion?.answerOptions, [currentQuestion]) || [];

  // How many total questions there are in this game
  const maxQuestions = questions.length;
  // The maximum index we can hit before the game is done
  const maxQuestionIndex = maxQuestions - 1;

  // This gets filled with the play entries as people answer (or timers run out)
  const [playEntries, setPlayEntries] = useState<Entry[]>([]);

  // This holds the scores for each question (true (question correct), false (question incorrect) or undefined (they didnt answer in time))
  const [scores, setScores] = useState<(boolean | undefined)[]>([]);

  const initialColors = answerOptions.reduce((acc: PickColors, _, index) => {
    acc[index.toString()] = {
      highlight: 'transparent',
      border: 'transparent',
      icon: <></>,
    };
    return acc;
  }, {});

  // This holds the colors for each answer option (highlight, border, icon)
  const [colors, setColors] = useState<PickColors>(initialColors);

  // This determines whether or not to lock the state of the game, so that people can't answer after the question is done
  const [gameLock, setGameLock] = useState(false);
  // This determines how long (in seconds) to lock the game state to show the result
  const GAME_LOCK_TIME = 1.25;

  const [gameResults, setGameResults] = useState<Play | null>(null);

  const windowValues = useContext(WindowContext);

  const optimisticGradePlay = useCallback(({ play, game }: { play: PlayRequest; game: Game }) => {
    // Step 1: Create the object without the total points
    const optimisticPlay = {
      ...play,
      entries: play.entries.map((entry) => {
        const question = game.definition.questions.find((q) => q.questionId === entry.questionId);
        const correctAnswerForCurrentQuestion = question?.answerId;
        const isRight = entry.answerId === correctAnswerForCurrentQuestion;
        return {
          ...entry,
          correct: isRight,
          points: isRight ? question?.points || 0 : 0,
        };
      }),
      // Stubbing out the rest of the play object
      id: 0,
      userId: '',
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      gameId: game.id,
      deletedAt: null,
      completedAt: new Date().toISOString(),
      points: 0, // Will be updated in step 2
    };

    // Step 2: Sum up the points and add/update the key
    optimisticPlay.points =
      optimisticPlay.entries.reduce((sum, entry) => sum + entry.points, 0) +
      (game.definition.defaultPointsForPlaying || 0);

    return optimisticPlay as Play;
  }, []);

  const gradePlay = useCallback(
    (play: PlayRequest) => {
      // Because the server takes time to respond, we want to show the results of the game while the server is processing the play record.
      const optimisticPlay = optimisticGradePlay({ play, game });
      setTimeout(() => {
        setGameResults(optimisticPlay);
        setHasResults(true);
      }, GAME_LOCK_TIME * 1000);
    },
    [game, optimisticGradePlay, setHasResults],
  );

  const submitPlay = useCallback(
    async (phoneNumber: string) => {
      setSubmitting(true);
      try {
        // Here the server is processing the play record
        play.phone = phoneNumber;
        const postPlayResponse = await postLitePlay(windowValues)({ play });
        if (postPlayResponse) {
          setSubmitting(false);
          // Now that the submission is done, refresh the data to update the challenge cards.
          await queryClient.refetchQueries(['LiteChallengeData']);
          await queryClient.refetchQueries(['GameCenterData-Lite']);
          return postPlayResponse;
        } else {
          // If there is no resposne, something probably went wrong, show error modal.
          setSubmitting(false);
          return 'error';
        }
      } catch (error) {
        // Something went horribly wrong, show error modal.
        setSubmitting(false);
        return 'error';
      }
    },
    [play, windowValues],
  );

  // This is the timer that counts down before the game starts (The Get Ready screen)
  useEffect(() => {
    const interval = setInterval(() => {
      if (startGame === false && gameResults === null) {
        setGoSeconds((previousSeconds) => previousSeconds - 1);
      } else {
        return;
      }
    }, TICK_RATE * 1000);

    return () => clearInterval(interval);
  }, [gameResults, startGame]);

  // After they answer (to grade) and whenever the current question changes, update the colors (to reset colors on a new question)
  const gradeQuestion = useCallback(() => {
    const answerOptions = currentQuestion?.answerOptions || [];
    const played = playEntries.find((play) => play.questionId === currentQuestion?.questionId);
    const gradedColors = {} as PickColors;

    if (!played) {
      answerOptions.map((_answerOption, index) => {
        gradedColors[index] = {
          highlight: 'transparent',
          border: 'transparent',
          icon: <></>,
        };
      });
    }
    // If its played, evaluate all the options
    if (played) {
      answerOptions.map((_answerOption, index) => {
        // If we're on the users option check if its correct or not
        const correctAnswerForCurrentQuestion = currentQuestion?.answerId;
        if (played.answerId === index.toString()) {
          const playersChoice = played.answerId;
          const isRight = playersChoice === correctAnswerForCurrentQuestion;
          if (isRight) {
            gradedColors[index] = {
              highlight: Colors.gameCenterQuestionRightHighlight,
              border: Colors.gameCenterQuestionRight,
              icon: <Check width={18} height={18} tintColor={Colors.gameCenterQuestionRight} />,
            };
          } else {
            gradedColors[index] = {
              highlight: Colors.gameCenterQuestionWrongHighlight,
              border: Colors.gameCenterQuestionWrong,
              icon: <Close width={18} height={18} tintColor={Colors.gameCenterQuestionWrong} />,
            };
          }
        } else if (index.toString() === correctAnswerForCurrentQuestion) {
          // If its not the users option, but its the correct answer, highlight it
          gradedColors[index] = {
            highlight: Colors.gameCenterQuestionRightHighlight,
            border: Colors.gameCenterQuestionRight,
            icon: <Check width={18} height={18} tintColor={Colors.gameCenterQuestionRight} />,
          };
        } else {
          // Don't grade incorrect answers
          gradedColors[index] = {
            highlight: 'transparent',
            border: 'transparent',
            icon: <></>,
          };
        }
      });
    }
    setColors(gradedColors);
  }, [playEntries, currentQuestion]);

  // As the seconds tick down, if the seconds are less than 0, hide the countdown card and start the game
  useEffect(() => {
    if (goSeconds < 0) {
      setShowCountdownCard(false);
      setStartGame(true);
    }
  }, [goSeconds]);

  // This is the timer that counts down for each question.
  // If the game is locked, its showing the results of the last question so don't reduce the time to a negative number, wait for the next question to start
  useEffect(() => {
    const interval = setInterval(() => {
      if (startGame && questionTime > 0 && !gameLock && gameResults === null) {
        setQuestionTime((previousTime) => previousTime - 1);
      }
    }, TICK_RATE * 1000);

    return () => clearInterval(interval);
  }, [startGame, gameLock, questionTime, gameResults]);

  const addEntry = useCallback(
    (entry: Entry) => {
      setGameLock(true);
      // Update the play entries list for other grading
      setPlayEntries((prevEntries) => {
        const updatedEntries = [...prevEntries, entry];

        // Whenever a play entry is added, append it to the play state that will get sent out in the postPlay request
        setPlay((prevPlay) => {
          const play = {
            ...prevPlay,
            entries: updatedEntries,
          };
          // If we're on the last play, submit the record here with the entries.
          if (currentQuestionIndex === maxQuestionIndex) {
            gradePlay(play);
          }
          return play;
        });
        return updatedEntries;
      });
      const correctAnswer = currentQuestion?.answerId;
      const isCorrect = entry.answerId === correctAnswer;
      setScores((prevScore) => [...prevScore, isCorrect]);
      setQuestionTime(0);
      gradeQuestion();

      setTimeout(() => {
        if (currentQuestionIndex === maxQuestionIndex) {
          return;
        }
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setQuestionTime(questionTimeMax);
        setGameLock(false);
      }, GAME_LOCK_TIME * 1000);
    },
    [currentQuestion?.answerId, currentQuestionIndex, gradeQuestion, maxQuestionIndex, gradePlay],
  );

  // When the question time hits 0, if we're on the last question, submit the play.
  // Otherwise they didnt answer before the timer ran out, so find the current play entry, check that it is undefined and then set it to "none"
  useEffect(() => {
    if (questionTime === 0) {
      const currentPlayEntry = playEntries.find(
        (entry) => entry.questionId === currentQuestion?.questionId,
      );
      if (currentPlayEntry?.answerId === undefined) {
        addEntry({
          questionId: currentQuestion?.questionId || '',
          answerId: 'none',
        });
        return;
      }
    }
    return;
  }, [questionTime, currentQuestion, addEntry, playEntries]);

  useEffect(() => {
    gradeQuestion();
  }, [playEntries, currentQuestionIndex, gradeQuestion]);
  if (gameResults) {
    return (
      <View>
        <Margin height={windowValues.isPhone ? 100 : 180} />
        <TriviaGameResultModal
          gameResults={gameResults}
          submit={submitPlay}
          setHasResults={setHasResults}
        />
      </View>
    );
  }

  const { isPhone } = windowValues;

  return (
    <View
      style={{
        alignItems: 'center',
        width: '100%',
        maxWidth: windowValues.isPhone ? 475 : '100%',
        paddingHorizontal: isPhone ? 8 : 36,
        backgroundColor: 'transparent',
        alignSelf: 'center',
      }}
    >
      <Margin height={isPhone ? 100 : 180} />
      <View style={{ width: '100%', minHeight: isPhone ? 476 : 803 }}>
        <View style={[styles.container, { backgroundColor: '#1d2141', borderRadius: 16 }]}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
              padding: 16,
              gap: 8,
              overflow: 'hidden',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              >
                <View
                  style={
                    {
                      borderBottomColor: colorPalette.neutral['200'],
                      borderBottomWidth: 0.5,
                      width: 'fit-content',
                    } as any
                  }
                >
                  <DText
                    color={colorPalette.neutral['200']}
                    fontSize={isPhone ? Fonts.font12 : Fonts.font28}
                  >
                    {`Question ${currentQuestionIndex + 1}/${maxQuestions}`}
                  </DText>
                </View>
                <Score scores={scores} questions={maxQuestions} />
              </View>
              <GradientCountdownCircle
                text={':' + padNumber(questionTime)}
                backgroundColor={'transparent'}
                fontSize={isPhone ? Fonts.font18 : Fonts.font32}
                width={isPhone ? 54 : 108}
                steps={questionTimeMax}
                index={questionTime > 0 ? questionTime : 0}
              />
            </View>
            {showCountdownCard && <CountdownCard time={goSeconds} steps={getReadyTime} />}
            <SlideInView
              offset={-650}
              type={'tap'}
              direction="forward"
              question={currentQuestion.questionId}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 32,
                width: '100%',
              }}
            >
              <DText
                center={true}
                color={'white'}
                fontWeight={900}
                fontSize={isPhone ? Fonts.font18 : Fonts.font38}
                style={{ minHeight: 62 }}
              >
                {currentQuestion?.questionText}
              </DText>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: 16,
                }}
              >
                {answerOptions?.map((answerOption, index) => {
                  return (
                    <BiggerTouchableOpacity
                      key={index}
                      disabled={gameLock || submitting}
                      onPress={() =>
                        addEntry({
                          questionId: currentQuestion?.questionId || '',
                          answerId: index.toString(),
                        })
                      }
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: isPhone ? 60 : 120,
                        borderRadius: 4,
                        paddingHorizontal: 16,
                        backgroundColor: Colors.cardColor,
                        position: 'relative',
                      }}
                    >
                      <Animated.View
                        style={{
                          position: 'absolute',
                          top: 0,
                          flexDirection: 'row',
                          width: '100%',
                          height: isPhone ? 60 : 120,
                          borderRadius: 4,
                          paddingRight: 16,
                          paddingLeft: 16,
                          backgroundColor: colors[index]?.highlight,
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          zIndex: 10,
                        }}
                      >
                        <View
                          style={{
                            height: isPhone ? 22 : 44,
                            width: isPhone ? 22 : 44,
                            borderWidth: 1,
                            borderRadius: 999,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderColor: colors[index]?.border,
                          }}
                        >
                          {colors[index]?.icon}
                        </View>
                      </Animated.View>
                      <DText
                        center={true}
                        style={{ zIndex: 10000, marginLeft: 16, marginRight: 16 }}
                        color={'white'}
                        fontSize={isPhone ? Fonts.font16 : Fonts.font48}
                        fontWeight={900}
                      >
                        {answerOption}
                      </DText>
                    </BiggerTouchableOpacity>
                  );
                })}
              </View>
            </SlideInView>
          </View>
        </View>
      </View>
    </View>
  );
}

function Score({ scores, questions }: { scores: (boolean | undefined)[]; questions: number }) {
  const { isPhone } = useContext(WindowContext);
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
      }}
    >
      {Array.from({ length: questions }).map((_num, index) => {
        return (
          <View
            key={index}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 999,
              width: isPhone ? 20 : 40,
              height: isPhone ? 20 : 40,
              borderColor:
                scores[index] === undefined
                  ? colorPalette.neutral['400']
                  : scores[index] === true
                  ? Colors.questionRightHighlight
                  : Colors.questionWrongHighlight,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
          >
            {scores[index] === true ? (
              <Check
                width={isPhone ? 16 : 48}
                height={isPhone ? 16 : 40}
                tintColor={Colors.questionRightHighlight}
              />
            ) : scores[index] === false ? (
              <Close
                width={isPhone ? 16 : 40}
                height={isPhone ? 16 : 40}
                tintColor={Colors.questionWrongHighlight}
              />
            ) : (
              <></>
            )}
          </View>
        );
      })}
    </View>
  );
}

function CountdownCard({ time, steps }: { time: number; steps: number }) {
  const { isPhone } = useContext(WindowContext);
  const BLUR_SIZE = 5;

  const timerStyles: any = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    borderRadius: 16,
    WebkitBackdropFilter: 'blur(' + BLUR_SIZE + 'px)',
    backdropFilter: 'blur(' + BLUR_SIZE + 'px)',
  };

  return (
    <View style={timerStyles}>
      <DText
        fontSize={isPhone ? Fonts.font14 : Fonts.font32}
        fontWeight={Fonts.Bold}
        color={'white'}
      >
        Get Ready!
      </DText>
      <GradientCountdownCircle
        text={':' + padNumber(time)}
        backgroundColor={'#4a4e67'}
        width={isPhone ? 78 : 140}
        fontSize={isPhone ? Fonts.font24 : Fonts.font32}
        steps={steps}
        index={time > 0 ? time : 0}
      />
    </View>
  );
}

export function GameResultContent({ gameResults }: { gameResults: Play }) {
  const totalQuestions = gameResults.entries.length;
  const totalCorrect = gameResults.entries.filter((entry) => entry.correct).length;
  const { isPhone } = useContext(WindowContext);
  return (
    <View
      style={{
        borderRadius: 4,
        width: '100%',
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 8,
      }}
    >
      <View
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <DText fontSize={isPhone ? 16 : 24} color={'#D3D3DC'}>
          {`You got ${totalCorrect} of ${totalQuestions} questions correct.`}
        </DText>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: isPhone ? 12 : 24,
          gap: isPhone ? 14 : 28,
        }}
      >
        {gameResults.entries.map((result, index) => {
          const number = index + 1;
          return <TriviaEntryResult key={result.questionId} result={result} number={number} />;
        })}
      </View>
    </View>
  );
}

export function TriviaGameResultModal({
  gameResults,
  submit,
  setHasResults,
}: {
  gameResults: Play;
  submit: (phoneNumber: string) => Promise<{ points: number } | 'error'>;
  setHasResults: (results: boolean) => void;
}) {
  const { isPhone } = useContext(WindowContext);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const phoneValid = useMemo(() => phoneNumber.length === 10, [phoneNumber]);

  const [submitPressed, setSubmitPressed] = useState(false);
  const [phoneSubmitted, setPhoneSubmitted] = useState(false);
  const [termsBoxOneChecked, setTermsBoxOneChecked] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setError(undefined);
  }, [phoneNumber]);

  const submitPhoneNumber = useCallback(async () => {
    setSubmitPressed(true);
    const textSent = await submit(phoneNumber);
    if (textSent === 'error') {
      setError('This number was already used to play this game. Please try again.');
      return;
    }
    setHasResults(false);
    setPhoneSubmitted(!!textSent?.points);
  }, [submit, phoneNumber, setHasResults]);

  const handlePhoneInput = (input: string) => {
    const phoneInput = parseInt(input);
    setPhoneNumber(phoneInput ? phoneInput.toString() : '');
  };

  const fadeAnim = useRef(new Animated.Value(0)).current;
  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  };

  const { resetInactivityTimer } = useInactivityDetector(
    () => {
      if (!isPhone) {
        // User is inactive and the splash is not showing, so we should show it.
        // We should also reset the game so that the next user  can see the game.
        window.location.reload();
      }
    },
    phoneSubmitted ? 10000 : 30000,
  );

  const totalQuestions = gameResults.entries.length;
  const totalCorrect = gameResults.entries.filter((entry) => entry.correct).length;
  const disableSubmitButton = !phoneValid || !termsBoxOneChecked;

  return (
    <Animated.View
      onLayout={fadeIn}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'transparent',
        opacity: fadeAnim,
      }}
      onTouchStart={resetInactivityTimer}
    >
      <View style={{ width: '100%', paddingHorizontal: isPhone ? 8 : 36 }}>
        <View
          style={{
            backgroundColor: '#1d2141',
            borderRadius: 16,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: isPhone ? 8 : 16,
            minHeight: isPhone ? 476 : 803,
            paddingVertical: isPhone ? 24 : 48,
            paddingHorizontal: isPhone ? 16 : 32,
            width: '100%',
          }}
        >
          <View style={{ marginBottom: 16 }}>
            <DText
              color={'#9B83F7'}
              letterSpacing={1}
              fontSize={isPhone ? 12 : 22}
              fontWeight={900}
            >
              {phoneSubmitted
                ? 'THANKS FOR PLAYING!'
                : `YOU GOT ${totalCorrect} OF ${totalQuestions} RIGHT`}
            </DText>
          </View>
          <View
            style={{
              width: '100%',
              justifyContent: 'center',
              flexDirection: 'row',
              marginBottom: 8,
            }}
          >
            <DText center={true} color={'white'} fontSize={isPhone ? 18 : 36} fontWeight={800}>
              {`Submit your phone number to\nbe entered to win FREE tickets!`}
            </DText>
          </View>
          <View style={{ width: '100%' }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingHorizontal: 20,
                paddingTop: isPhone ? 4 : 8,
                paddingBottom: isPhone ? 2 : 4,
                gap: 6,
              }}
            >
              <TextInput
                keyboardType="phone-pad"
                onChangeText={handlePhoneInput}
                value={phoneNumber}
                autoComplete="off"
                autoCorrect={false}
                maxLength={10}
                style={{
                  width: '100%',
                  height: isPhone ? 45 : 90,
                  backgroundColor: phoneSubmitted ? '#474B5E' : 'transparent',
                  color: '#D3D3DC',
                  borderRadius: 8,
                  borderWidth: isPhone ? 1.5 : 3,
                  borderColor: '#D3D3DC',
                  borderStyle: 'solid',
                  paddingHorizontal: isPhone ? 10 : 20,
                  paddingVertical: isPhone ? 8 : 16,
                  marginTop: isPhone ? 4 : 8,
                  marginBottom: isPhone ? 6 : 12,
                  fontSize: isPhone ? 16 : 24,
                }}
                editable={!phoneSubmitted}
                caretHidden={!!phoneSubmitted}
                placeholder="Mobile Phone Number"
                // This is a workarund so that the timer doesnt dismiss when user is entering their number
                //@ts-expect-error - We know that resetInactivityTimer is a function and doesnt take the event as a prop.
                onKeyPress={resetInactivityTimer}
              />
            </View>
          </View>
          {!phoneSubmitted && (
            <View style={{ justifyContent: 'flex-start', width: '100%', paddingHorizontal: 20 }}>
              <View
                onPointerDown={() => setTermsBoxOneChecked(!termsBoxOneChecked)}
                style={{ flexDirection: 'row', alignItems: 'center' }}
              >
                <DText
                  fontSize={isPhone ? 10 : 18}
                  color="#717488"
                  letterSpacing={0.5}
                  lineHeight="1.4"
                >
                  <View
                    style={{
                      borderColor: 'white',
                      borderWidth: isPhone ? 1 : 2,
                      marginRight: isPhone ? 4 : 8,
                      top: isPhone ? 1 : 2,
                      left: isPhone ? 2 : 4,
                    }}
                  >
                    <Check
                      tintColor="white"
                      width={isPhone ? 10 : 18}
                      height={isPhone ? 10 : 18}
                      style={{ opacity: termsBoxOneChecked ? 1 : 0 }}
                    />
                  </View>
                  {` By providing my mobile number and clicking “Submit”, I consent to receive one MMS text message at the number above from Vivid Seats (1-833-898-4843) with directions to claim 3,000 Game Center Tokens (“Tokens”) by downloading the Vivid Seats app + creating an account. Tokens may be redeemed for discounts off Vivid Seats purchases. 3000 Tokens = $15 off. Consent not req’d to purchase. Msgs may be sent w/ autodialer-prerecorded. Call 1-833-228-5143 for help. Msg and data rates may apply. If your mobile device doesn’t support MMS, you may not receive the text or the text may come to you in a different format. View Privacy Policy at http://vividseats.com/privacy.`}
                </DText>
              </View>
              <Margin height={isPhone ? 12 : 16} />
            </View>
          )}
          <View
            style={{
              width: '100%',
              alignItems: 'center',
              paddingHorizontal: 18,
              paddingTop: 2,
            }}
          >
            <BiggerTouchableOpacity
              onPress={async () => {
                await submitPhoneNumber();
              }}
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 8,
                paddingBottom: phoneSubmitted ? 4 : 0,
                backgroundColor: phoneSubmitted
                  ? '#4AC76D'
                  : disableSubmitButton
                  ? '#474B5E'
                  : '#277CF4',
                height: isPhone ? 40 : 66,
              }}
              disabled={!phoneValid || !termsBoxOneChecked || submitPressed}
            >
              {submitPressed && !phoneSubmitted ? (
                <View>
                  <ActivityIndicator color="white" size={isPhone ? 24 : 40} />
                </View>
              ) : (
                <DText
                  color={phoneSubmitted ? 'black' : disableSubmitButton ? '#A0A2B3' : '#FFFFFF'}
                  fontSize={isPhone ? 16 : 28}
                  fontWeight={700}
                  letterSpacing={0.5}
                >
                  {phoneSubmitted ? `You're Entered` : 'Submit'}
                  {phoneSubmitted && (
                    <Check
                      width={isPhone ? 16 : 32}
                      height={isPhone ? 16 : 32}
                      tintColor={'black'}
                      style={{ top: isPhone ? 3 : 6, right: -4 }}
                    />
                  )}
                </DText>
              )}
            </BiggerTouchableOpacity>
            {phoneSubmitted && (
              <>
                <Margin height={isPhone ? 22 : 44} />
                <Margin height={1.5} width={'100%'} backgroundColor="#A69FE2" />
                <Margin height={isPhone ? 18 : 36} />
                <GameResultContent gameResults={gameResults} />
              </>
            )}
            {error && (
              <DText
                center={true}
                color={Colors.red}
                fontSize={isPhone ? 16 : 24}
                fontWeight={500}
                style={{ marginTop: 8 }}
              >
                {error}
              </DText>
            )}
          </View>
        </View>
      </View>
    </Animated.View>
  );
}

export function TriviaEntryResult({
  number,
  result,
}: {
  number: number;
  result: Play['entries'][number];
}) {
  const { isPhone } = useContext(WindowContext);
  const color =
    result.correct === undefined
      ? Colors.borderBlue
      : result.correct
      ? Colors.gameCenterQuestionRight
      : Colors.gameCenterQuestionWrong;
  const size = isPhone ? 20 : 40;
  const resultIcon = (
    <View
      style={
        {
          width: isPhone ? 24 : 48,
          height: isPhone ? 24 : 48,
          borderWidth: 4,
          borderRadius: 999,
          borderColor: color,
          backgroundColor: Colors.betchaBlack,
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: result.correct ? '0px 2px 4px 0px ' + color : '',
        } as any
      }
    >
      {result.correct ? (
        <Check width={size} height={size} tintColor={color} />
      ) : (
        <Close width={size} height={size} tintColor={color} />
      )}
    </View>
  );
  return (
    <View
      style={{
        marginTop: 16,
        marginLeft: 4,
        justifyContent: 'center',
      }}
    >
      <View
        style={{
          backgroundColor: Colors.betchaBlack,
          width: isPhone ? 72 : 144,
          height: isPhone ? 72 : 144,
          borderRadius: 144,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: 54,
            height: 54,
            backgroundColor: 'transparent',
            borderRadius: 18,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <View
            style={{
              position: 'absolute',
              left: isPhone ? -16 : -64,
              top: isPhone ? -11 : -44,
              zIndex: 1,
            }}
          >
            {resultIcon}
          </View>
          <>
            <DText
              style={{ position: 'absolute', top: isPhone ? 5 : -12, left: isPhone ? 12 : -10 }}
              fontSize={isPhone ? 19 : 38}
              fontWeight={900}
              color="white"
            >
              #
            </DText>
            <DText fontSize={isPhone ? 36 : 72} fontWeight={900} color="white" style={{ left: 6 }}>
              {number}
            </DText>
          </>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
});
