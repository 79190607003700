import { Animated, Easing } from 'react-native';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { WindowContext } from '../../App';

export function CoinsAndTrianglesOverlay({
  animate,
  zIndex,
}: {
  animate: boolean;
  zIndex: number;
}) {
  const { isPhone } = useContext(WindowContext);
  const customEasing1 = Easing.bezier(0.42, 0, 0.58, 1);
  const customEasing2 = Easing.bezier(0.5, 1.9, 1, 0.86);
  const customEasing3 = Easing.bezier(0.5, 1.89, 0.55, 0.94);
  const customEasing4 = Easing.bezier(0.5, 1.89, 0.29, 0.9);

  const floatAnim1 = useRef(new Animated.Value(0)).current;
  const floatAnim2 = useRef(new Animated.Value(0)).current;

  const rotateAnim1 = useRef(new Animated.Value(0)).current;
  const rotateInterpolated1 = rotateAnim1.interpolate({
    inputRange: [-5, 0, 5],
    outputRange: ['-5deg', '0deg', '5deg'],
  });
  const rotateAnim2 = useRef(new Animated.Value(0)).current;
  const rotateInterpolated2 = rotateAnim2.interpolate({
    inputRange: [-5, 0, 5],
    outputRange: ['-5deg', '0deg', '5deg'],
  });

  const startFloating1 = useCallback(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(floatAnim1, {
          toValue: -10,
          duration: 1000,
          easing: customEasing1,
          useNativeDriver: true,
        }),
        Animated.timing(floatAnim1, {
          toValue: 0,
          duration: 1000,
          easing: customEasing1,
          useNativeDriver: true,
        }),
        Animated.timing(floatAnim1, {
          toValue: 0,
          duration: 1000,
          easing: customEasing1,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  }, [floatAnim1, customEasing1]);
  const startFloating2 = useCallback(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(floatAnim2, {
          toValue: -10,
          duration: 1500,
          easing: customEasing2,
          useNativeDriver: true,
        }),
        Animated.timing(floatAnim2, {
          toValue: 0,
          duration: 1500,
          easing: customEasing2,
          useNativeDriver: true,
        }),
        Animated.timing(floatAnim2, {
          toValue: 0,
          duration: 1500,
          easing: customEasing2,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  }, [floatAnim2, customEasing2]);

  const startRotate1 = useCallback(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(rotateAnim1, {
          toValue: 5,
          duration: 1250,
          easing: customEasing3,
          useNativeDriver: true,
        }),
        Animated.timing(rotateAnim1, {
          toValue: -5,
          duration: 1250,
          easing: customEasing3,
          useNativeDriver: true,
        }),
        Animated.timing(rotateAnim1, {
          toValue: 0,
          duration: 1250,
          easing: customEasing3,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  }, [rotateAnim1, customEasing3]);
  const startRotate2 = useCallback(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(rotateAnim2, {
          toValue: 5,
          duration: 1500,
          easing: customEasing4,
          useNativeDriver: true,
        }),
        Animated.timing(rotateAnim2, {
          toValue: -5,
          duration: 1500,
          easing: customEasing4,
          useNativeDriver: true,
        }),
        Animated.timing(rotateAnim2, {
          toValue: 0,
          duration: 1500,
          easing: customEasing4,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  }, [rotateAnim2, customEasing4]);

  useEffect(() => {
    if (animate) {
      startFloating1();
      startFloating2();
      startRotate1();
      startRotate2();
    }
  }, [animate, startFloating1, startFloating2, startRotate1, startRotate2]);

  const size = isPhone ? 1080 / 13 : 1080 / 8;

  return (
    <>
      <Animated.Image
        style={{
          position: 'absolute',
          width: size,
          height: size,
          top: '-7%',
          left: '-7%',
          transform: [{ translateY: floatAnim1 }, { scaleX: -1 }],
          zIndex,
        }}
        source={require('./assets/Token2.png')}
      />
      <Animated.Image
        style={{
          position: 'absolute',
          width: size,
          height: size,
          top: '3%',
          left: '3%',
          transform: [{ translateY: floatAnim2 }, { rotate: rotateInterpolated1 }],
          zIndex,
        }}
        source={require('./assets/Triangle-4_Blue-3.png')}
      />
      <Animated.Image
        style={
          {
            position: 'absolute',
            width: size,
            height: size,
            top: isPhone ? '25%' : '30%',
            transform: [
              { scale: '30%' },
              { scaleX: -1 },
              { translateY: floatAnim2 },
              { rotate: rotateInterpolated2 },
            ],
            zIndex,
          } as any
        }
        source={require('./assets/Token5.png')}
      />
      <Animated.Image
        style={{
          position: 'absolute',
          width: size,
          height: size,
          top: '65%',
          left: '-2%',
          transform: [{ translateY: floatAnim1 }, { rotate: rotateInterpolated2 }],
          zIndex,
        }}
        source={require('./assets/Triangle-2_Purple-1.png')}
      />
      <Animated.Image
        style={
          {
            position: 'absolute',
            width: size,
            height: size,
            bottom: isPhone ? '0%' : '3%',
            left: '7%',
            transform: [
              { translateY: floatAnim2 },
              { rotate: rotateInterpolated2 },
              { scale: '60%' },
              { scaleX: -1 },
            ],
            zIndex,
          } as any
        }
        source={require('./assets/Token6.png')}
      />
      <Animated.Image
        style={{
          position: 'absolute',
          width: size,
          height: size,
          bottom: '-2%',
          left: '70%',
          transform: [{ translateX: floatAnim1 }, { rotate: rotateInterpolated1 }, { scaleX: -1 }],
          zIndex,
        }}
        source={require('./assets/Triangle-2_Pink-1.png')}
      />
      <Animated.Image
        style={
          {
            position: 'absolute',
            width: size,
            height: size,
            top: isPhone ? '35%' : '31%',
            right: '-1%',
            transform: [
              { scale: '40%' },
              { translateY: floatAnim2 },
              { rotate: rotateInterpolated1 },
            ],
            zIndex,
          } as any
        }
        source={require('./assets/Token5.png')}
      />
      <Animated.Image
        style={
          {
            position: 'absolute',
            width: size,
            height: size,
            top: isPhone ? '70%' : '30%',
            right: '-5%',
            transform: [
              { translateY: floatAnim1 },
              { rotate: rotateInterpolated2 },
              { scale: '70%' },
            ],
            zIndex,
          } as any
        }
        source={require('./assets/Triangle-4_Blue-1.png')}
      />
      <Animated.Image
        style={
          {
            position: 'absolute',
            width: size,
            height: size,
            top: 0,
            right: 0,
            transform: [
              { translateY: floatAnim2 },
              { rotate: rotateInterpolated2 },
              { scale: isPhone ? '120%' : '160%' },
            ],
            zIndex,
          } as any
        }
        source={require('./assets/Triangle-3_Purple-2.png')}
      />
    </>
  );
}
