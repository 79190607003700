import { TextInput, View } from 'react-native';
import DText from './DText';
import { colorPalette } from '../themes/variables';
import Fonts from '../constants/Fonts';

const outline = { outline: 'none' };
export const TextBox = ({
  label,
  value,
  width,
  onChangeText,
}: {
  label: string;
  value?: string;
  onChangeText: (text: string) => void;
  width: number;
}) => (
  <View
    style={{
      width,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    }}
  >
    <DText color={colorPalette.neutral['200']} fontSize={Fonts.font14} lineHeight={'150%'}>
      {label}
    </DText>
    <TextInput
      spellCheck={false}
      placeholder={''}
      autoCorrect={false}
      autoComplete={'off'}
      maxLength={40}
      onChangeText={onChangeText}
      style={{
        ...outline,
        color: 'white',
        lineHeight: 20,
        fontFamily: 'GT-Walsheim-Medium',
        fontSize: 16,
        width,
      }}
      value={value}
    />
    <View
      style={{
        width,
        height: 1,
        backgroundColor: colorPalette.neutral['200'],
      }}
    />
  </View>
);
