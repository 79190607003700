import { View } from 'react-native';
import { ChallengeCard } from './ChallengeCard';
import DText from './DText';
import Fonts from '../constants/Fonts';
import { Challenge } from '../interfaces';

export function ChallengeList({ data, title }: { data: (Challenge | undefined)[]; title: string }) {
  // Empty state if no challenges played
  if (!data || data.length === 0 || data.every((challenge) => challenge === undefined)) {
    return null;
  }

  const sortedChallenges = data.sort((a, b) => {
    if (!a || !b) {
      return 0;
    }

    const aHasPriority = a.definition && a.definition.sortPriority !== undefined;
    const bHasPriority = b.definition && b.definition.sortPriority !== undefined;

    // If only one of the items has a sortPriority, it should come first
    if (aHasPriority && !bHasPriority) {
      return -1;
    }
    if (!aHasPriority && bHasPriority) {
      return 1;
    }

    // If both have a sortPriority, compare them in descending order
    if (aHasPriority && bHasPriority) {
      return (b.definition.sortPriority || 0) - (a.definition.sortPriority || 0);
    }

    // If neither has a sortPriority, sort by startDate in descending order
    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
  });

  return (
    <View style={{ marginBottom: 16, height: 'auto' }}>
      <DText color="white" fontSize={Fonts.font14} fontWeight={900} marginBottom={8}>
        {title}
      </DText>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 12,
        }}
      >
        {sortedChallenges.map((challenge: any) => {
          if (challenge) {
            return <ChallengeCard key={challenge.id} challenge={challenge} />;
          }
        })}
      </View>
    </View>
  );
}
