import { View } from 'react-native';
import { Prize, RedemptionsResponse } from '../interfaces';
import { colorPalette } from '../themes/variables';
import DText from './DText';
import Fonts from '../constants/Fonts';
import Margin from './Margin';
import Utils from '../Utils';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import { useQuery } from '@tanstack/react-query';
import { getPrizesV2 } from '../api';
import { Copy, Ticket } from './images';
import { useContext } from 'react';
import { WindowContext } from '../App';

export function TicketCreditCard({ redemption }: { redemption: RedemptionsResponse }) {
  const isExpired = redemption.expiresAt ? new Date(redemption.expiresAt) < new Date() : false;

  const windowValues = useContext(WindowContext);
  const {
    isLoading: isPrizesLoading,
    isError: isPrizesError,
    error: _prizesError,
    data: prizes,
  } = useQuery<Prize[], Error, Prize[], string[]>({
    queryKey: ['Prizes'],
    queryFn: getPrizesV2(windowValues),
  });

  const prize = prizes?.find((p) => p.id === redemption.prizeId);

  if (isPrizesLoading || isPrizesError) {
    return null;
  }

  return (
    <View
      style={{
        width: '100%',
        padding: 16,
        borderRadius: 8,
        backgroundColor: 'rgba(255,255,255,.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View
          style={{
            backgroundColor: isExpired ? colorPalette.red['300'] : colorPalette.indigo['350'],
            borderRadius: 96,
            width: 141,
            height: 21,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DText fontWeight={600} fontSize={Fonts.font14} color={colorPalette.neutral['700']}>
            {isExpired ? 'Ticket Credit Expired' : 'Ticket Credit Issued'}
          </DText>
        </View>
        <DText fontWeight={900} fontSize={Fonts.font14} color="white">
          {'-' + prize?.cost}
        </DText>
      </View>

      <DText fontWeight={900} fontSize={Fonts.font14} color={'white'}>
        {`Tokens Redeemed for $${prize?.value} Credit`}
      </DText>

      <DText fontSize={Fonts.font14} color={colorPalette.neutral['200']}>
        {Utils.shortDateFormat(new Date(redemption.createdAt))}
      </DText>

      <DText fontWeight={900} fontSize={Fonts.font14} color={'white'}>
        DETAILS
      </DText>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <DText fontSize={Fonts.font14} color={colorPalette.neutral['200']}>
          {'Promo Code: ' + redemption.code}
        </DText>
      </View>

      <DText fontSize={Fonts.font14} color={colorPalette.neutral['200']}>
        {redemption.expiresAt
          ? `Code expires ${Utils.shortDateFormat(
              new Date(redemption.expiresAt),
            )}, minimum order $${prize?.minPurchase} (before taxes and fees)`
          : 'Code does not expire'}
      </DText>
      <Margin height={10} />
      <View>
        {!isExpired && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <BiggerTouchableOpacity
              onPress={() => {
                Utils.copyTextToClipboard(redemption.code, null, (e: Error) => {
                  alert(e);
                });
              }}
              activeOpacity={0.5}
              style={{
                flexDirection: 'row',
                width: '33%',
                height: 36,
                padding: '6px 16px',
                borderRadius: 4,
                borderWidth: 1,
                borderColor: 'white',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Copy width={24} height={24} tintColor={'white'} />
              <Margin width={4} />
              <DText fontWeight={600} fontSize={Fonts.font13} color={'white'}>
                Copy Code
              </DText>
            </BiggerTouchableOpacity>
            <BiggerTouchableOpacity
              onPress={() => {
                Utils.copyTextToClipboard(redemption.code, () => {
                  const deeplink =
                    'vividseats://home?utm_source=game_center&utm_campaign=game_center&utm_promo=##PROMOCODE'.replace(
                      '##PROMOCODE',
                      redemption.code,
                    );
                  location.href = deeplink;
                });
              }}
              activeOpacity={0.5}
              style={{
                flexDirection: 'row',
                width: '33%',
                height: 36,
                padding: '6px 16px',
                borderRadius: 4,
                borderWidth: 1,
                borderColor: 'white',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Ticket width={24} height={24} tintColor={'white'} />
              <Margin width={4} />
              <DText fontWeight={600} fontSize={Fonts.font13} color={'white'}>
                Buy In App
              </DText>
            </BiggerTouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
}
