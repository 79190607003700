import React from 'react';
import DText from './DText';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import { colorPalette } from '../themes/variables';
import Fonts from '../constants/Fonts';
import { LeftArrow } from '../components/images';

interface CtaButtonProps {
  active?: boolean;
  outline?: boolean;
  backgroundColorActive?: string;
  onPress: () => void;
  width?: number | '100%';
  backArrow?: boolean;
  text: string;
}

const CtaButton = (props: CtaButtonProps) => {
  let backgroundColor = props.active ? colorPalette.cobalt['400'] : 'rgba(1,1,1,.2)';
  let color = props.active ? 'white' : colorPalette.neutral['200'];
  let borderColor = props.active ? colorPalette.cobalt['400'] : 'rgba(1,1,1,.2)';

  if (props.outline) {
    backgroundColor = 'transparent';
    color = props.active ? 'white' : colorPalette.neutral['600'];
    borderColor = props.active ? 'white' : colorPalette.neutral['600'];
  } else {
    backgroundColor = props.active ? colorPalette.cobalt['400'] : colorPalette.neutral['600'];
    color = props.active ? 'white' : colorPalette.neutral['200'];
    borderColor = props.active ? colorPalette.cobalt['400'] : colorPalette.neutral['600'];
  }

  if (props.active && props.backgroundColorActive) {
    backgroundColor = props.backgroundColorActive;
    borderColor = props.backgroundColorActive;
  }

  return (
    <BiggerTouchableOpacity
      disabled={!props.active}
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}
      style={{
        paddingTop: 6,
        paddingRight: 16,
        paddingBottom: 6,
        paddingLeft: 16,
        backgroundColor,
        height: 36,
        minWidth: props.width || 124,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor,
      }}
    >
      {props.backArrow && (
        <LeftArrow width={24} height={24} tintColor={color} style={{ marginRight: 8 }} />
      )}
      <DText lineHeight={'150%'} fontWeight={600} fontSize={Fonts.font16} color={color}>
        {props.text}
      </DText>
    </BiggerTouchableOpacity>
  );
};

export default CtaButton;
