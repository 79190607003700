import { StyleSheet, View } from 'react-native';
import { colorPalette } from '../themes/variables';
import DText from './DText';
import Fonts from '../constants/Fonts';

export function ErrorPage({ error = '', extra }: { error?: string; extra?: JSX.Element }) {
  return (
    <View style={styles.mainContainer}>
      <View style={[styles.container]}>
        <DText style={{ padding: 36 }} center={true} fontSize={Fonts.font24} color="white">
          There was an error loading the GameCenter. Please close the app and reopen it to try
          again.
        </DText>
        <DText center={true} fontSize={Fonts.font24} color="white">
          {error}
        </DText>
        <br />
        {extra || null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    flexDirection: 'column',
    maxWidth: 475,
    justifySelf: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 475,
  },
});
