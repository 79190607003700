import { StyleSheet, FlatList, View, Modal, Image, ActivityIndicator } from 'react-native';
import adler32 from 'adler-32';
import React, { useState, useEffect, useContext } from 'react';
import Colors from '../constants/Colors';
import DText from './DText';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import Margin from './Margin';
import { colorPalette } from '../themes/variables';
import Utils, { getChallengeIcon } from '../Utils/index';
import Fonts from '../constants/Fonts';
import CtaButton from './CtaButton';
import {
  Block,
  EyeHidden,
  EyeShown,
  User,
  Trophy,
  Trash,
  Settings,
  Edit,
  Close,
  LeaveLeague,
} from './images';
import { LeagueEditUserName } from './LeagueEditUserName';
import { LeagueLeaveLeague } from './LeagueLeaveLeague';
import { LeagueEditLeagueName } from './LeagueEditLeagueName';
import { renderCopyTextModal } from '../components/CopyText';
import { LeagueCloseLeague } from './LeagueCloseLeague';
import { LeagueBlockUser } from './LeagueBlockUser';
import { Challenge, League } from '../interfaces';
import { getLeagueV2, updateLeagueV2, getChallengesV2 } from '../api';
import { ErrorPage } from './ErrorPage';
import { useQuery } from '@tanstack/react-query';
import { LoadingPage } from './LoadingPage';
import { LeaguePlayerProfile } from './LeagueSection';
import { WindowContext } from '../App';

let inIntervalTimer = false;

export interface leagueBoardProps {
  setSelectedLeague: (league?: League) => void;
  leagueType?: League['type'];
  leagueId?: League['id'];
  width: number;
  refetchLeagues: () => Promise<any>;
}

const headerData = {
  headerWidth: 375,
  headerHeight: 72,
  HeaderImage:
    'https://media.vsstatic.com/image/upload/w_375,dpr_2,q_auto/v1691898158/Product/app/features/game-center/headers/Logo-gc_uoacdm.png',
};

export const LeagueLeaderboard = (props: leagueBoardProps) => {
  const windowValues = useContext(WindowContext);

  const {
    isLoading: challengesLoading,
    isError: isChallengesError,
    data: challenges,
  } = useQuery<Challenge[], Error, Challenge[], (string | undefined)[]>({
    queryKey: ['GameCenterData-Challenges', '50', 'false', props.leagueType],
    queryFn: getChallengesV2(windowValues),
  });

  const {
    isInitialLoading: leagueLoading,
    error: leagueError,
    data: activeLeague,
    refetch: refetchLeague,
  } = useQuery<League, Error, League, (string | undefined)[]>({
    queryKey: ['leagueData', props.leagueId?.toString()],
    queryFn: getLeagueV2(windowValues),
    enabled: Boolean(props.leagueId),
  });

  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showAllTime, setShowAllTime] = useState(true);
  const [editName, setEditName] = useState(false);
  const [editLeague, setEditLeague] = useState(false);
  const [leaveLeague, setLeaveLeague] = useState(false);
  const [showGlobalCopy, setShowGlobalCopy] = useState(false);
  const [globalCopyText, setGlobalCopyText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [adminUserEditMode, setAdminUserEditMode] = useState<League['members'][number] | null>();
  const [closeLeague, setCloseLeague] = useState(false);
  const [blockUser, setBlockUser] = useState(false);
  const [selectedMember, setSelectedMember] = useState<League['members'][number]>();
  const [referralLoading, setReferralLoading] = useState(false);

  const { accountId } = useContext(WindowContext);
  const newMember = {
    blocked: false,
    leagueId: activeLeague?.id || null,
    maskName: false,
    name: 'Check back soon!',
    ranking: {
      rank: 0,
      points: 0,
    },
    rankingThisWeek: {
      rank: 0,
      points: 0,
    },
    userId: 'new-user',
  };
  const activeMember =
    activeLeague?.members.find((member) => member.userId === accountId) || newMember;
  const isAdmin = activeLeague?.adminId === activeMember?.userId;
  const leagueMembers = activeLeague?.members.sort((a, b) =>
    showAllTime
      ? b.ranking.points - a.ranking.points || a.ranking.rank - b.ranking.rank
      : b.rankingThisWeek.points - a.rankingThisWeek.points ||
        a.rankingThisWeek.rank - b.rankingThisWeek.rank,
  );
  const sortedLeagueMembers = leagueMembers?.slice(0, 50);
  const leagueChallenges = challenges?.filter(({ type }) => activeLeague?.type === type);
  const [activeChallenge] = leagueChallenges?.length
    ? leagueChallenges
    : challenges?.length
    ? challenges
    : [];
  const [mostRecentPlayableGame] =
    activeChallenge?.games
      ?.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
      .filter(
        (game) =>
          new Date(game.startDate).getTime() <= new Date().getTime() &&
          (game.endDate === null || new Date(game.endDate).getTime() >= new Date().getTime()),
      ) || [];
  const challengeToPlay =
    activeChallenge?.active &&
    mostRecentPlayableGame &&
    !mostRecentPlayableGame?.plays.some(({ userId }) => activeMember?.userId === userId);

  // for android copy we need to see if there is global text text
  if (!Utils.haveChallengeCallback()) {
    useEffect(() => {
      Utils.leaderBoardIsControllingCopy(true);
      const interval = setInterval(() => {
        if (inIntervalTimer) {
          return;
        }
        inIntervalTimer = true;
        if (Utils.getGlobalCopyText()) {
          setShowGlobalCopy(true);
          setGlobalCopyText(Utils.getGlobalCopyText());
          Utils.clearGlobalCopyText();
        }
        inIntervalTimer = false;
      }, 50);
      return () => {
        Utils.leaderBoardIsControllingCopy(false);
        clearInterval(interval);
      };
    }, []);
  }

  if (leagueLoading || challengesLoading) {
    return <LoadingPage />;
  }

  if (!activeLeague || leagueError || isChallengesError) {
    return <ErrorPage error={'Could not load league.'} />;
  }

  const maskUser = async (maskName: boolean, userId: string) => {
    try {
      setSubmitting(true);
      await updateLeagueV2(windowValues)({
        queryKey: ['leagueId', activeLeague?.id.toString()],
        league: { members: [{ userId, maskName } as League['members'][number]] },
      });
      await refetchLeague();
    } catch (err) {
      setShowSettings(false);
      setErrorMsg(err as string);
    }
    setShowSettings(false);
    setAdminUserEditMode(null);
    setSubmitting(false);
  };
  const createReferralLink = async () => {
    const referralCode = adler32.str(accountId);
    try {
      const referrers = { [referralCode]: { accountId, challengeId: activeChallenge?.id } };
      setReferralLoading(true);
      await updateLeagueV2(windowValues)({
        queryKey: ['leagueId', activeLeague.id.toString()],
        league: { definition: { referrers } },
      });
      setReferralLoading(false);
      return referralCode;
    } catch (err) {
      setErrorMsg(err as string);
      setReferralLoading(false);
    }
  };

  const renderError = () => {
    if (!errorMsg) {
      return;
    }
    return (
      <View
        style={{
          width: props.width,
          height: 300,
          minHeight: 64,
          padding: 8,
          borderRadius: 8,
          backgroundColor: Colors.white10, // colorPalette.neutral['700'], //rgba(255,255,255,.1)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Margin height={12} />
        <DText style={{ padding: 36 }} center={true} fontSize={Fonts.font18} color="white">
          {'There was an error loading the league.'}
        </DText>
        <DText center={true} fontSize={Fonts.font16} color="white">
          {'Error: ' + errorMsg}
        </DText>
        <Margin height={36} />
        <CtaButton
          outline={true}
          width={props.width / 3}
          active={true}
          text="Cancel"
          onPress={() => {
            setSubmitting(false);
            setErrorMsg('');
          }}
        />
        <Margin height={16} />
      </View>
    );
  };

  const renderSelectorBar = () => {
    const barWidth = (props.width - 32) / 2;
    const activeColor = Colors.white;

    const selectorButton = (text: string, active: boolean) => {
      return (
        <BiggerTouchableOpacity
          style={{
            width: barWidth,
            height: 32,
            borderRadius: 16,
            backgroundColor: active ? activeColor : 'transparent',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            setShowAllTime(!showAllTime);
          }}
        >
          <DText fontSize={Fonts.font12} fontWeight={600} color={active ? 'trransparent' : 'white'}>
            {text}
          </DText>
        </BiggerTouchableOpacity>
      );
    };
    return (
      <View
        style={{
          width: props.width,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 12,
        }}
      >
        {selectorButton('All Time', showAllTime)}
        {selectorButton('This Week', !showAllTime)}
      </View>
    );
  };

  const renderLeaderBoard = () => {
    const renderInviteButton = () => (
      <View
        style={{
          minHeight: 72,
          flexDirection: 'row',
          justifyContent: 'space-between',
          // alignItems: 'center',
          backgroundColor: 'rgba(255,255,255,.1)',
          borderRadius: 8,
          marginBottom: 12,
          width: props.width,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: props.width,
          }}
        >
          <View style={{ margin: 16, flexShrink: 1 }}>
            <DText fontWeight={900} color={'white'} fontSize={Fonts.font16} letterSpacing={1.25}>
              {`PLAY AS A SQUAD`}
            </DText>
            <Margin height={12} />
            <DText fontWeight={400} color={'white'} fontSize={Fonts.font18} lineHeight={'1.5'}>
              {`Earn 50 tokens for new players that join Vivid Seats`}
            </DText>
          </View>
          <BiggerTouchableOpacity
            key={'rbRow'}
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: 40,
              backgroundColor: colorPalette.cobalt['400'],
              borderRadius: 4,
              paddingHorizontal: 16,
              paddingVertical: 24,
              marginLeft: 36,
              marginRight: 16,
              width: 144,
            }}
            onPress={async () => {
              const title = `Join my league ${activeLeague.name}`;
              const { prizeDescription = '', prizeDescriptionSubText = '' } =
                activeChallenge?.definition || {};
              const winText =
                title +
                ` to play games and for a chance at winning ${prizeDescription.toLowerCase()} ${prizeDescriptionSubText.toLowerCase()}:\n`;
              const referral = await createReferralLink();
              const deeplinkHost =
                process.env.NODE_ENV === 'development'
                  ? 'vividseats://'
                  : 'https://vividseats.go.link/';
              if (!referral) return;
              const url = `\n${deeplinkHost}game_center?leagueId=${activeLeague.id}&referralId=${referral}&adj_t=1a7eze8m_1are7nwl`;

              const text = winText + url;
              setShowGlobalCopy(true);
              setGlobalCopyText(text);
            }}
          >
            {referralLoading ? (
              <ActivityIndicator color="white" size={'small'} />
            ) : (
              <DText
                lineHeight={'150%'}
                center={false}
                fontWeight={700}
                color={'white'}
                fontSize={Fonts.font20}
              >
                Invite Friends
              </DText>
            )}
          </BiggerTouchableOpacity>
        </View>
      </View>
    );
    return (
      <View
        style={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {renderBackgroundImage()}
        <Image
          source={{ uri: headerData.HeaderImage }}
          style={{
            width: '100%',
            height: undefined,
            aspectRatio: headerData.headerWidth / headerData.headerHeight,
          }}
        />
        <Margin height={24} />
        <View
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Margin height={16} />
          <View
            style={{
              marginRight: 12,
              marginLeft: 12,
              paddingLeft: 16,
              paddingRight: 16,
              width: props.width,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'rgba(255,255,255,.1)',
              borderRadius: 8,
              height: 90,
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  width: 58,
                  height: 58,
                  borderRadius: 29,
                  backgroundColor: 'rgba(255,255,255,.1)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 16,
                }}
              >
                <DText
                  style={{ backgroundColor: 'transparent' }}
                  fontSize={36}
                  color="white"
                  fontWeight={900}
                >
                  {getChallengeIcon(activeLeague?.type)}
                </DText>
              </View>
              <View style={{ maxWidth: props.width * 0.6 }}>
                <DText lineHeight={'125%'} color={'white'} fontWeight={900} fontSize={Fonts.font20}>
                  {activeLeague.name}
                </DText>
              </View>
            </View>
            <BiggerTouchableOpacity
              onPress={() => setShowSettings(true)}
              style={{
                alignSelf: 'flex-start',
                marginLeft: 16,
                width: 28,
                height: 28,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(4, 9, 44, 0.5)',
                borderRadius: 14,
              }}
            >
              <Settings width={24} height={24} tintColor={'white'} />
            </BiggerTouchableOpacity>
          </View>
          <Margin height={36} />
          {renderInviteButton()}

          <View
            style={{
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >
            <DText lineHeight={'150%'} fontSize={Fonts.font20} color="white" fontWeight={900}>
              Your Ranking
            </DText>
          </View>
          <Margin height={16} />
          <View>
            {renderLeaguePlayerWithEdit(activeMember, activeMember.userId !== 'new-user')}
          </View>
          <Margin height={4} />
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >
            <DText lineHeight={'150%'} fontSize={Fonts.font20} color="white" fontWeight={900}>
              Leaderboard
            </DText>
            <View style={{ flex: 1, height: 20 }} />
          </View>
          <Margin height={16} />
          {renderSelectorBar()}
        </View>
      </View>
    );
  };

  const renderLeaguePlayer = ({ item }: { item: League['members'][number] }) => {
    return renderLeaguePlayerWithEdit(item, false);
  };

  const renderLeaguePlayerWithEdit = (item: League['members'][number], edit: boolean) => {
    const points = showAllTime ? item.ranking.points : item.rankingThisWeek.points;
    return (
      <BiggerTouchableOpacity
        disabled={edit}
        onPress={() => {
          setSelectedMember(item);
        }}
        style={{
          minHeight: 62,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'rgba(255,255,255,.1)',
          borderRadius: 8,
          marginBottom: 12,
          width: props.width,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            maxWidth: '70%',
            paddingRight: 16,
          }}
        >
          <DText
            lineHeight={'150%'}
            style={{ minWidth: 48, paddingRight: 16, paddingLeft: 16 }}
            center={true}
            fontWeight={900}
            color={'white'}
            fontSize={Fonts.font24}
          >
            {(showAllTime ? item.ranking.rank : item.rankingThisWeek.rank) || '-'}
          </DText>
          <View style={{ maxWidth: props.width * 0.7 }}>
            <DText
              lineHeight={'150%'}
              center={false}
              fontWeight={900}
              color={'white'}
              fontSize={Fonts.font18}
            >
              {(activeMember.userId !== 'new-user' ? '@' : '') + item.name}
            </DText>
          </View>
          {edit && (
            <BiggerTouchableOpacity
              disabled={isAdmin}
              onPress={() => setEditName(true)}
              style={{
                opacity: isAdmin ? 0 : 1,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <Margin width={8} />
              <Edit height={12} width={12} tintColor={'white'} />
            </BiggerTouchableOpacity>
          )}
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <DText
            lineHeight={'150%'}
            center={true}
            fontWeight={900}
            color={'white'}
            fontSize={Fonts.font18}
          >
            {points ? '+' + points : '-'}
          </DText>
          {isAdmin && (
            <BiggerTouchableOpacity
              onPress={() => {
                if (item.userId === activeMember?.userId) {
                  setEditName(true);
                  return;
                }
                setAdminUserEditMode(item);
                setShowSettings(true);
              }}
              style={{
                alignSelf: 'flex-start',
                marginLeft: 12,
                width: 24,
                height: 24,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Settings width={24} height={24} tintColor={'white'} />
            </BiggerTouchableOpacity>
          )}
          <Margin width={16} />
        </View>
      </BiggerTouchableOpacity>
    );
  };

  const renderSettings = () => {
    const width = props.width + 32;
    const cmds: any[] = [];
    let title;
    let keyIndex = 0;
    const buildCmd = (Widget: any, text: string, callback: () => void, bottom = false) => {
      keyIndex++;
      cmds.push(
        <View key={'cmd' + keyIndex}>
          <BiggerTouchableOpacity
            onPress={callback}
            style={{
              width,
              height: 60,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderTopColor: colorPalette.neutral['600'],
              borderTopWidth: 1,
              borderBottomColor: bottom ? colorPalette.neutral['600'] : colorPalette.neutral['700'],
              borderBottomWidth: 1,
            }}
          >
            <Margin width={16} />
            <Widget height={26} width={26} tintColor="white"></Widget>
            <Margin width={8} />
            <DText fontWeight={600} fontSize={Fonts.font14} color={'white'}>
              {text}
            </DText>
          </BiggerTouchableOpacity>
        </View>,
      );
    };
    if (adminUserEditMode) {
      title = 'User Settings: @' + adminUserEditMode.name;
      if (adminUserEditMode.maskName) {
        buildCmd(EyeHidden, 'Show Display Name', () => {
          maskUser(false, adminUserEditMode.userId);
        });
      } else {
        buildCmd(EyeShown, 'Hide Display Name', () => {
          maskUser(true, adminUserEditMode.userId);
        });
      }
      buildCmd(Block, 'Permanently Block User', () => {
        setShowSettings(false);
        setBlockUser(true);
      });
    } else if (isAdmin) {
      title = 'League Settings';
      buildCmd(Trophy, 'Edit League Name', () => {
        setShowSettings(false);
        setEditLeague(true);
      });
      buildCmd(
        Trash,
        'Permanently Close League',
        () => {
          setShowSettings(false);
          setCloseLeague(true);
        },
        true,
      );
    } else if (activeMember.name !== 'new-user') {
      title = 'League Settings';
      buildCmd(User, 'Edit Display Name', () => {
        setShowSettings(false);
        setEditName(true);
      });
      buildCmd(
        LeaveLeague,
        'Leave League',
        () => {
          setShowSettings(false);
          setLeaveLeague(true);
        },
        true,
      );
    }
    return (
      <View
        pointerEvents="none"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: showSettings ? 1 : 0,
          width: '100%',
          height: '100%',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,.8)',
        }}
      >
        <Modal
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          animationType={'slide'}
          transparent={true}
          onRequestClose={() => {
            setShowSettings(false);
            setAdminUserEditMode(null);
            setErrorMsg('');
          }}
          visible={showSettings}
        >
          <View style={[styles.mainContainer, { backgroundColor: 'transparent' }]}>
            <View
              style={{
                flex: 1,
                maxWidth: 475,
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            />
            <View
              style={{
                height: 12,
                width,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                borderTopColor: colorPalette.neutral['600'],
                borderTopWidth: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: 16,
                paddingRight: 16,
                alignItems: 'center',
                overflow: 'hidden',
                backgroundColor: colorPalette.neutral['700'],
              }}
            ></View>
            <View
              style={{
                width,
                justifyContent: 'flex-end',
                alignItems: 'center',
                backgroundColor: colorPalette.neutral['700'],
              }}
            >
              <View
                style={{
                  minHeight: 50,
                  width,
                  marginBottom: 8,
                  paddingTop: 28,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingLeft: 16,
                  paddingRight: 16,
                  alignItems: 'center',
                  overflow: 'hidden',
                  paddingBottom: 8,
                }}
              >
                <View style={{ maxWidth: width - 90 }}>
                  <DText lineHeight={'150%'} fontSize={Fonts.font18} color="white" fontWeight={900}>
                    {title}
                  </DText>
                </View>
                <BiggerTouchableOpacity
                  onPress={() => {
                    setShowSettings(false);
                    setAdminUserEditMode(null);
                  }}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Close width={24} height={24} tintColor="white" />
                </BiggerTouchableOpacity>
              </View>
              {submitting ? (
                <View style={{ height: cmds.length * 60, justifyContent: 'center' }}>
                  <ActivityIndicator size={'large'} color={'white'} />
                </View>
              ) : (
                cmds
              )}
              <View style={{ height: 60, width }} />
            </View>
          </View>
        </Modal>
      </View>
    );
  };

  const renderBackgroundImage = () => {
    const backgroundImage =
      'https://media.vsstatic.com/image/upload/w_375,dpr_2,q_auto/v1685043110/Product/app/features/free-to-play/backgrounds/SpotlightBackgroundImage_tall2_y9edka_gxx6dc.png';
    const styleObj: any = {
      backgroundRepeat: 'no-repeat',
      resizeMode: 'cover',
      position: 'absolute',
      left: 0,
      right: 0,
      margin: '0 auto',
      maxWidth: 475,
      height: 800 + (sortedLeagueMembers?.length || 1) * 72,
      width: '100%',
      aspectRatio: 1 / 4,
    };
    return <Image source={{ uri: backgroundImage }} style={styleObj} />;
  };

  return (
    <View key="leaguelistLBMain" style={styles.mainContainer}>
      <View
        style={[
          styles.container,
          { backgroundColor: Colors.betchaBlack40Percent },
          {
            flex: 1,
            justifyContent: 'flex-start',
          },
        ]}
      >
        {selectedMember ? (
          <View style={{ flex: 1 }}>
            {renderBackgroundImage()}
            <Image
              source={{ uri: headerData.HeaderImage }}
              style={{
                width: '100%',
                height: undefined,
                aspectRatio: headerData.headerWidth / headerData.headerHeight,
              }}
            />
            <Margin height={24} />
            <LeaguePlayerProfile
              selectedMember={selectedMember}
              closeProfile={() => setSelectedMember(undefined)}
              activeMember={activeMember}
              league={activeLeague}
              challenges={challenges}
              width={props.width}
            />
          </View>
        ) : (
          <FlatList
            contentContainerStyle={{ alignItems: 'center' }}
            ListHeaderComponentStyle={{
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            onRefresh={() => {
              // do nothing
            }}
            scrollEnabled={!selectedMember}
            refreshing={false}
            keyExtractor={(item) => item.userId}
            ListHeaderComponent={renderLeaderBoard}
            ListFooterComponent={() => {
              return <View style={{ height: 100 }} />;
            }}
            renderItem={renderLeaguePlayer}
            data={sortedLeagueMembers}
            style={{ flex: 1, width: '100%' }}
          />
        )}
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            height: 96,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colorPalette.neutral[700],
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <BiggerTouchableOpacity
            onPress={() => {
              props.setSelectedLeague();
              windowValues.setChallenge(activeChallenge);
            }}
            style={{
              width: '100%',
              borderRadius: 4,
              justifyContent: 'center',
              alignItems: 'center',
              height: 48,
              backgroundColor: challengeToPlay
                ? colorPalette.cobalt['400']
                : colorPalette.white['600'],
            }}
          >
            <DText
              fontSize={Fonts.font16}
              fontWeight={600}
              color={challengeToPlay ? 'white' : colorPalette.neutral['300']}
            >
              {challengeToPlay ? 'Play Now' : 'See Game History'}
            </DText>
          </BiggerTouchableOpacity>
        </View>
        <BiggerTouchableOpacity
          onPress={() => {
            setShowAllTime(true);
            selectedMember ? setSelectedMember(undefined) : props.setSelectedLeague();
          }}
          style={{
            position: 'absolute',
            top: 30,
            right: 16,
            height: 40,
            width: 40,
            borderRadius: 20,
            backgroundColor: 'rgba(4, 9, 44, 0.5)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Close width={24} height={24} tintColor="white" />
        </BiggerTouchableOpacity>
      </View>
      {renderSettings()}
      <Modal
        style={{
          width: '100%',
          minHeight: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: 'rgba(255,255,255,.2)',
        }}
        animationType={'fade'}
        transparent={true}
        onRequestClose={() => false}
        visible={editName || leaveLeague || editLeague || closeLeague || blockUser || !!errorMsg}
      >
        {errorMsg && (
          <View key="editUserName" style={[styles.mainContainer]}>
            <View
              style={[
                styles.container,
                { backgroundColor: Colors.betchaBlack40Percent },
                {
                  flex: 1,
                  paddingLeft: 16,
                  paddingRight: 16,
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <Margin height={12} />
              {renderError()}
            </View>
          </View>
        )}

        {editLeague && (
          <View key="editUserName" style={[styles.mainContainer]}>
            <View
              style={[
                styles.container,
                { backgroundColor: Colors.betchaBlack40Percent },
                {
                  flex: 1,
                  paddingLeft: 16,
                  paddingRight: 16,
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <Margin height={12} />
              <LeagueEditLeagueName
                leaderboardIcon={getChallengeIcon(activeChallenge?.type)}
                leagueName={activeLeague?.name}
                onCancel={() => setEditLeague(false)}
                width={props.width}
                leagueId={activeLeague?.id.toString()}
                refetchLeague={refetchLeague}
              />
            </View>
          </View>
        )}
        {editName && (
          <View key="editUserName" style={[styles.mainContainer]}>
            <View
              style={[
                styles.container,
                { backgroundColor: Colors.betchaBlack40Percent },
                {
                  flex: 1,
                  paddingLeft: 16,
                  paddingRight: 16,
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <Margin height={12} />
              <LeagueEditUserName
                leaderboardIcon={getChallengeIcon(activeChallenge?.type)}
                userName={activeMember?.name}
                leagueId={activeLeague?.id.toString()}
                leagueName={activeLeague?.name}
                onCancel={() => setEditName(false)}
                width={props.width}
                refetchLeague={refetchLeague}
              />
            </View>
          </View>
        )}
        {leaveLeague && (
          <View key="leaveTheLeave" style={styles.mainContainer}>
            <View
              style={[
                styles.container,
                { backgroundColor: Colors.betchaBlack40Percent },
                {
                  flex: 1,
                  paddingLeft: 16,
                  paddingRight: 16,
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <Margin height={64} />
              <LeagueLeaveLeague
                leagueId={activeLeague?.id}
                leaderboardIcon={getChallengeIcon(activeChallenge?.type)}
                leagueName={activeLeague?.name}
                setSelectedLeague={props.setSelectedLeague}
                width={props.width}
                userId={activeMember?.userId}
                refetchLeagues={props.refetchLeagues}
              />
            </View>
          </View>
        )}
        {closeLeague && (
          <View key="closeTheLeague" style={styles.mainContainer}>
            <View
              style={[
                styles.container,
                { backgroundColor: Colors.betchaBlack40Percent },
                {
                  flex: 1,
                  paddingLeft: 16,
                  paddingRight: 16,
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <Margin height={64} />
              <LeagueCloseLeague
                leaderboardIcon={getChallengeIcon(activeChallenge?.type)}
                leagueName={activeLeague?.name}
                leagueId={activeLeague?.id.toString()}
                setSelectedLeague={props.setSelectedLeague}
                refetchLeagues={props.refetchLeagues}
                onCancel={() => setCloseLeague(false)}
                width={props.width}
              />
            </View>
          </View>
        )}
        {blockUser && (
          <View key="blockUser" style={styles.mainContainer}>
            <View
              style={[
                styles.container,
                { backgroundColor: Colors.betchaBlack40Percent },
                {
                  flex: 1,
                  paddingLeft: 16,
                  paddingRight: 16,
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <Margin height={64} />
              <LeagueBlockUser
                leaderboardIcon={getChallengeIcon(activeChallenge?.type)}
                leagueName={activeLeague?.name}
                member={adminUserEditMode}
                leagueId={activeLeague?.id.toString()}
                refetchLeague={refetchLeague}
                onCancel={() => setBlockUser(false)}
                width={props.width}
              />
            </View>
          </View>
        )}
      </Modal>
      {renderCopyTextModal(
        showGlobalCopy,
        () => {
          setShowGlobalCopy(false);
        },
        globalCopyText,
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,.8)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flex: 1,
    maxWidth: 475,
  },
});
