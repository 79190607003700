import { StyleSheet, View } from 'react-native';
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import DText from '../components/DText';
import Margin from '../components/Margin';
import Utils, { getChallengeIcon } from '../Utils/index';
import Fonts from '../constants/Fonts';
import CtaButton from '../components/CtaButton';
import { IOSStore, GoogleStore } from '../components/images';
import { colorPalette } from '../themes/variables';
import BiggerTouchableOpacity from '../components/BiggerTouchableOpacity';
import { renderCopyTextModal } from '../components/CopyText';
import { joinLeagueV2 } from '../api';
import { WindowContext } from '../App';

let inIntervalTimer = false;

export default function JoinTheFun() {
  const [showCopy, setShowCopy] = useState(false);
  const [showGlobalCopy, setShowGlobalCopy] = useState(false);
  const [globalCopyText, setGlobalCopyText] = useState('');
  const [leagueName, setLeagueName] = useState('');
  const [emoji, setEmoji] = useState('');
  const [winText, setWinText] = useState('');

  const windowValues = useContext(WindowContext);

  const { inviteCode = '' } = useParams();
  useEffect(() => {
    const retrieveLeagueInfo = async () => {
      const league = await joinLeagueV2(windowValues)({ inviteCode });
      setLeagueName(league.name);
      const [challenge] = league.challenges;
      const prizeDescription = `${challenge.definition.prizeDescription} ${challenge.definition.prizeDescriptionSubText}`;
      setEmoji(getChallengeIcon(challenge.type));
      setWinText(prizeDescription);
    };
    retrieveLeagueInfo();
  }, [inviteCode, windowValues]);

  // for android copy we need to see if there is global text text
  useEffect(() => {
    const interval = setInterval(() => {
      if (inIntervalTimer) {
        return;
      }
      inIntervalTimer = true;
      if (Utils.getGlobalCopyText()) {
        setShowGlobalCopy(true);
        setGlobalCopyText(Utils.getGlobalCopyText());
        Utils.clearGlobalCopyText();
      }
      inIntervalTimer = false;
    }, 50);
    return () => clearInterval(interval);
  }, []);

  const doShowCopy = (show?: boolean) => {
    if (!show) {
      setShowCopy(false);
    } else {
      setShowCopy(true);
      setTimeout(() => {
        setShowCopy(() => {
          return false;
        });
      }, 1000);
    }
  };

  const renderCopy = (x?: number, y?: number) => {
    if (!showCopy) {
      return <View />;
    }
    const th = 4;
    return (
      <View
        pointerEvents={'none'}
        style={{
          position: 'absolute',
          top: y,
          left: x,
        }}
      >
        <View
          pointerEvents={'none'}
          style={{
            backgroundColor: colorPalette.neutral['800'],
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
            height: 18,
            width: 50,
          }}
        >
          <DText color="white" fontSize={12}>
            Copied
          </DText>
        </View>
        <View
          style={{
            position: 'absolute',
            top: 18,
            left: 22,
            width: 0,
            height: 0,
            backgroundColor: 'transparent',
            borderStyle: 'solid',
            borderTopWidth: th,
            borderRightWidth: th,
            borderBottomWidth: 0,
            borderLeftWidth: th,
            borderTopColor: colorPalette.neutral['800'],
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
          }}
        />
      </View>
    );
  };

  return (
    <View style={[styles.mainContainer]}>
      <View style={[styles.container]}>
        <Margin height={64} />
        <View
          style={{
            width: 96,
            height: 96,
            borderRadius: 48,
            backgroundColor: 'rgba(255,255,255,.1)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DText fontSize={64} color="white" fontWeight={900}>
            {emoji}
          </DText>
        </View>
        <Margin height={16} />
        <DText color="white" fontSize={Fonts.font16} lineHeight={'125%'} fontWeight={900}>
          Join
        </DText>
        <Margin height={16} />
        <DText
          style={{ paddingLeft: 16, paddingRight: 16 }}
          color="white"
          fontSize={Fonts.font20}
          lineHeight={'125%'}
          fontWeight={900}
        >
          {leagueName}
        </DText>
        <Margin height={16} />
        <DText
          style={{ paddingLeft: 16, paddingRight: 16 }}
          center={true}
          color="white"
          fontSize={Fonts.font16}
          lineHeight={'125%'}
          fontWeight={900}
        >
          {winText}
        </DText>
        <Margin height={16} />
        <DText color="white" fontSize={Fonts.font14} lineHeight={'150%'}>
          To join this league follow these steps
        </DText>
        <Margin height={16} />
        <View
          style={{
            width: '100%',
            paddingLeft: 104,
            paddingRight: 32,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <DText color="white" fontSize={Fonts.font14} lineHeight={'150%'}>
            1. Download the Vivid Seats App & log in
          </DText>
          <DText color="white" fontSize={Fonts.font14} lineHeight={'150%'}>
            2. Tap the Game Center Card in the app
          </DText>
          <DText color="white" fontSize={Fonts.font14} lineHeight={'150%'}>
            3. Tap "Leagues"
          </DText>
          <DText color="white" fontSize={Fonts.font14} lineHeight={'150%'}>
            4. Enter the invite code
          </DText>
        </View>
        <Margin height={16} />
        <DText color="white" fontSize={Fonts.font32} lineHeight={'125%'} fontWeight={900}>
          {inviteCode}
        </DText>
        <Margin height={24} />
        <View>
          <CtaButton
            onPress={() => {
              Utils.copyTextToClipboard(inviteCode, () => {
                doShowCopy(true);
              });
            }}
            width={127}
            active={true}
            text="Copy Invite Code"
          />
          {renderCopy(40, -20)}
        </View>
        <Margin height={24} />
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <BiggerTouchableOpacity
            onPress={() => {
              const url = 'https://apps.apple.com/us/app/vivid-seats-buy-tickets/id963355757';
              window.open(url, '_blank');
            }}
          >
            <IOSStore width={135} height={40} />
          </BiggerTouchableOpacity>
          <Margin width={12} />
          <BiggerTouchableOpacity
            onPress={() => {
              const url =
                'https://play.google.com/store/apps/details?id=com.vividseats.android&hl=en_US&gl=US';
              window.open(url, '_blank');
            }}
          >
            <GoogleStore width={135} height={40} />
          </BiggerTouchableOpacity>
        </View>
      </View>
      {renderCopyTextModal(
        showGlobalCopy,
        () => {
          setShowGlobalCopy(false);
        },
        globalCopyText,
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colorPalette.neutral[700],
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    flex: 1,
    backgroundColor: colorPalette.neutral[700],
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 475,
  },
});
