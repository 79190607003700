import { Image, View, useWindowDimensions } from 'react-native';
import DText from '../DText';
import Colors from '../../constants/Colors';
import BiggerTouchableOpacity from '../BiggerTouchableOpacity';
import { useCallback, useContext } from 'react';
import { WindowContext } from '../../App';
import { CoinsAndTrianglesOverlay } from './CoinsAndTrianglesOverlay';
import { LegalDisclaimer } from './LegalDisclaimer';

export function SplashScreen({
  showSplash,
  toggleGame,
}: {
  showSplash: boolean;
  toggleGame: () => void;
}) {
  // Get phone query param from url to determine if to show QR code. We do not show QR Codes on phones.
  const { isPhone } = useContext(WindowContext);
  const { width } = useWindowDimensions();
  const isDesktop = width >= 1440;

  const handlePlayGame = useCallback(() => {
    toggleGame();
  }, [toggleGame]);

  const PlayNow = () => (
    <BiggerTouchableOpacity
      onPress={handlePlayGame}
      style={{
        height: isPhone ? 60 : 128,
        backgroundColor: '#277CF4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        marginHorizontal: isPhone ? 16 : 64,
      }}
    >
      <DText fontSize={isPhone ? 24 : 48} fontWeight={700} color={Colors.textWhite}>
        Play Now
      </DText>
    </BiggerTouchableOpacity>
  );

  if (!showSplash) {
    return null;
  }

  return (
    <View
      style={{
        width: '100%',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: '#04092c',
        zIndex: 1,
      }}
    >
      <Image
        source={require('./assets/gamecenterlogo.png')}
        style={
          {
            position: 'absolute',
            top: isPhone ? 50 : 100,
            left: '50%',
            transform: [{ translateX: '-50%' }],
            width: isDesktop ? '43%' : '80%',
            height: undefined,
            aspectRatio: 2.04,
            zIndex: 0,
          } as any
        }
      />
      <Image
        source={require('./assets/vividseatslogo.png')}
        style={
          {
            width: isPhone ? '30%' : 370,
            height: isPhone ? 'auto' : 68,
            position: 'absolute',
            top: isPhone ? 20 : 80,
            left: '50%',
            transform: [{ translateX: '-50%' }],
            aspectRatio: 6,
            zIndex: 0,
          } as any
        }
      />
      <View
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 10,
          pointerEvents: 'none',
          overflow: 'hidden',
        }}
      >
        <CoinsAndTrianglesOverlay animate zIndex={1} />
      </View>
      <View
        style={
          {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            top: '65%',
            transform: [{ translateY: '-50%' }],
            zIndex: 10,
          } as any
        }
      >
        <DText
          center={true}
          color={'#04092c'}
          fontSize={isPhone ? 32 : 72}
          fontWeight={900}
          style={
            {
              textShadow:
                'white 0px 1px 1px, white 1px -1px 1px, white -1px -1px 1px, white 1px 0px 1px, -1px 1px 1px white, -1px 1px 5px white, white -2px 2px 7px',
              letterSpacing: '.5rem',
            } as any
          }
        >
          PLAY TRIVIA TO
        </DText>
        <DText
          center={true}
          color={Colors.white}
          fontSize={isPhone ? 54 : 108}
          fontWeight={900}
          style={{
            textShadow: 'rgb(255 255 255 / 60%) 4px 0px 10px',
            letterSpacing: '.75rem',
            zIndex: 20,
          }}
        >
          WIN FREE TICKETS
        </DText>

        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            justifyContent: 'center',
            alignItems: 'stretch',
          }}
        >
          <PlayNow />
          <LegalDisclaimer />
        </View>
      </View>
    </View>
  );
}
