import { View } from 'react-native';
import PropTypes from 'prop-types';
import DText from './DText';
import Fonts from '../constants/Fonts';
import { Logo } from './images';
import { colorPalette } from '../themes/variables';

interface initialProps {
  initials?: string;
}

const Initials: any = function (props: initialProps) {
  const drawInitials = !!(props.initials && props.initials.length > 0);
  return (
    <View
      style={{
        width: 54,
        height: 54,
        borderRadius: 30,
        backgroundColor: colorPalette.white['800'],
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {drawInitials && (
        <DText
          fontFamily={'GT-Walsheim-Black'}
          color="white"
          fontWeight={900}
          fontSize={Fonts.font24}
        >
          {props.initials ? props.initials.toUpperCase() : ''}
        </DText>
      )}
      {!drawInitials && <Logo height={54} width={47} />}
    </View>
  );
};

Initials.propTypes = {
  initials: PropTypes.any,
};

export default Initials;
