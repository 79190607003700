import { View } from 'react-native';
import { useContext } from 'react';
import DText from '../DText';
import Fonts from '../../constants/Fonts';
import { colorPalette } from '../../themes/variables';
import { WindowContext } from '../../App';

export function LegalDisclaimer({ onResultsScreen }: { onResultsScreen?: boolean }) {
  const windowValues = useContext(WindowContext);
  const { isPhone } = windowValues;

  return (
    <>
      <View
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          zIndex: 10,
          //backgroundColor: 'rgba(0,0,0,0.5)', //If we want it to be more visible
        }}
      >
        <DText
          center={true}
          color={colorPalette.neutral['300']}
          fontSize={isPhone ? Fonts.font8 : Fonts.font15}
          fontWeight={400}
          style={{
            marginTop: isPhone ? 4 : 8,
            marginHorizontal: onResultsScreen ? (isPhone ? 8 : 36) : isPhone ? 16 : 64,
          }}
        >
          No purchase necessary. Purchase will not increase the chances of winning. US residents
          only (incl. DC, excl. PR). 18+ (19+ ALA/NEB; 21+ MISS). Sweepstakes for Contest 1 begins
          10/03/24 at 12:00 p.m. CT and ends 7:00 p.m. CT on 10/06/24. Sweepstakes for Contest 2
          begins 10/10/24 at 12:00 p.m. CT and ends 7:00 p.m. CT on 10/13/24. One (1) winner will be
          chosen for each of the two (2) Contests from all eligible entries received during the
          applicable Contest Entry Period. Each winner will receive a five hundred dollar ($500)
          Vivid Seats gift card. Winner notified via text. Vivid Seats account required for prize
          redemption. Limit one entry per person. Void where prohibited and outside US. Visit
          http://gamecenter.vividseats.com/tandc for Official Rules and add’l restrictions. Sponsor:
          Vivid Seats LLC.
        </DText>
      </View>
    </>
  );
}
