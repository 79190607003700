import { View } from 'react-native';
import { TransactionsResponse } from '../interfaces';
import DText from './DText';
import Fonts from '../constants/Fonts';
import { colorPalette } from '../themes/variables';

export function TransactionHistoryCard({ transaction }: { transaction: TransactionsResponse }) {
  const startDate = new Date(transaction.createdAt).toLocaleDateString();
  const amount = transaction.amount;

  return (
    <View
      style={{
        borderRadius: 4,
        backgroundColor: 'rgba(255,255,255,.1)',
        width: '100%',
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <DText color={'white'} fontWeight={900} fontSize={Fonts.font14}>
          User Referral Bonus!
        </DText>
        <DText color={'white'} fontWeight={900} fontSize={Fonts.font14}>
          {'+' + amount}
        </DText>
      </View>
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <DText color={colorPalette.neutral[200]} fontSize={Fonts.font14}>
          {startDate}
        </DText>
      </View>
    </View>
  );
}
