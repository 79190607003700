import React, { useEffect, useRef } from 'react';
import { TouchableOpacity } from 'react-native';

let _allowTapAction = true;

interface BiggerTouchableOpacityProps {
  onPress: () => void;
  eventName?: string;
  onBlur?: () => void;
  onPressIn?: () => void;
  onPressOut?: () => void;
  style?: any;
  onLayout?: (arg0: any) => void;
  disabled?: boolean;
  hitSlop?: any;
  activeOpacity?: number;
  children?: any;
}

const BiggerTouchableOpacity = (props: BiggerTouchableOpacityProps) => {
  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, [mounted]);

  return (
    <TouchableOpacity
      onPress={() => {
        if (_allowTapAction) {
          let error;
          _allowTapAction = false;
          try {
            if (props.onPress) {
              props.onPress();
            }
          } catch (e) {
            error = e;
          }
          if (props.eventName) {
            // EventsTracker.event(props.eventName, props.eventData || {})
          }
          setTimeout(() => {
            _allowTapAction = true;
          }, 75);
          if (error) {
            throw error;
          }
        }
      }}
      onBlur={props.onBlur}
      onPressIn={props.onPressIn}
      onPressOut={props.onPressOut}
      style={[props.style, { outline: 'none' }]}
      onLayout={props.onLayout}
      disabled={/* handlingTouch || */ props.disabled}
      hitSlop={props.hitSlop || { top: 7, left: 7, right: 7, bottom: 7 }}
      activeOpacity={props.activeOpacity || 0.9}
    >
      {props.children}
    </TouchableOpacity>
  );
};
// 		//
export default BiggerTouchableOpacity;
