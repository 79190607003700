import React, { useState } from 'react';
import { View } from 'react-native';
import DText from './DText';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface gradientCountdownProps {
  width?: number;
  steps?: number;
  index?: number;
  backgroundColor?: string;
  fontSize?: number;
  text: string;
}

export const GradientCountdownCircle = (props: gradientCountdownProps) => {
  const [gradientId] = useState('gradCirc' + new Date().getTime() + Math.random());
  const width = props.width || 50;
  const steps = props.steps || 15;
  const index = props.index || 0;
  const bkgColor = props.backgroundColor || 'black';

  let val = index;
  if (val < 0) {
    val = 0;
  }

  const styleObj: any = {
    strokeLinecap: 'butt',
    trail: {
      stroke: '#e07bbd',
    },
    pathColor: `url(#${gradientId})`, // `rgba(62, 152, 199, ${steps/index})`,
    trailColor: Colors.cardColor,
  };

  return (
    <View style={{ width: width, height: width, justifyContent: 'center', alignItems: 'center' }}>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id={gradientId} gradientTransform={`rotate(90)`}>
            <stop offset="0%" stopColor={'#5d50cb'} />
            <stop offset="86%" stopColor={'#e07bbd'} />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgressbar
        strokeWidth={5}
        styles={buildStyles(styleObj)}
        minValue={0}
        value={val}
        maxValue={steps}
      />
      <View
        style={{
          position: 'absolute',
          top: 5,
          left: 5,
          width: width - 10,
          height: width - 10,
          borderRadius: width - 10,
          backgroundColor: bkgColor,
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          padding: 5,
        }}
      >
        <DText fontWeight={900} color="white" fontSize={props.fontSize || Fonts.font18}>
          {props.text}
        </DText>
      </View>

      {/* <LinearGradient
				colors={['#e07bbd', '#5d50cb']}
				start={{ x: 0.0, y: 1.0 }}
				end={{ x: 1.0, y: 1.0 }}
				style={{
					width: width,
					height: width,
					borderRadius: width,
					padding: 3,
					overflow: 'hidden'
				}}>
				<LinearGradient
					colors={colors}
					start={{ x: 1.0, y: 1.0 }}
					end={{ x: 1.0, y: 0.0 }}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: width,
						height: width,
						borderRadius: width,
						padding: 3,
						overflow: 'hidden',
						transform: `rotate(${120 + (index * 180) / steps}deg)`
					}}
				/>

			</LinearGradient> */}
    </View>
  );
};
