import Layout from './Layout'

export default {
	Regular: '400',
	Medium: '600',
	Bold: '800',
	font48: Layout.isSmallDevice ? 36 : 48,
	font38: Layout.isSmallDevice ? 32 : 38,
	font36: Layout.isSmallDevice ? 28 : 36,
	font32: Layout.isSmallDevice ? 26 : 32,
	font28: Layout.isSmallDevice ? 24 : 28,
	font26: Layout.isSmallDevice ? 22 : 26,
	font24: Layout.isSmallDevice ? 20 : 24,
	font22: Layout.isSmallDevice ? 18 : 22,
	font20: Layout.isSmallDevice ? 18 : 20,
	font18: Layout.isSmallDevice ? 16 : 18,
	font16: Layout.isSmallDevice ? 14 : 16,
	font15: Layout.isSmallDevice ? 12 : 15,
	font14: Layout.isSmallDevice ? 12 : 14,
	font13: Layout.isSmallDevice ? 11 : 13,
	font12: Layout.isSmallDevice ? 10 : 12,
	font11: Layout.isSmallDevice ? 10 : 11,
	font10: Layout.isSmallDevice ? 10 : 10,
	font9: Layout.isSmallDevice ? 9 : 9,
	font8: Layout.isSmallDevice ? 8 : 8,
	labelMost: 'More'
}
