import { Game, Play } from '../interfaces';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import { useCallback, useState } from 'react';
import { GameResultContent, TriviaEntryResult } from './NewTriviaGame';
import { Modal, ScrollView, View } from 'react-native';
import DText from './DText';
import Fonts from '../constants/Fonts';
import { colorPalette } from '../themes/variables';
import Colors from '../constants/Colors';
import { Close } from './images';
import { capitalize } from '../Utils';
import { PendingPill } from './NewPickEmGame';

export function YourSelectionCard({ game, userId, name, plays }: { game: Game; userId?: string, name?: string, plays?: Play[] }) {
  const [showDetails, setShowDetails] = useState<Game | null>(null);
  const toggleShowDetails = useCallback(() => {
    if (!showDetails) {
      setShowDetails(game);
    } else {
      setShowDetails(null);
    }
  }, [game, showDetails]);
  const username = name && '@' + name;
  const matchGame = (plays?: Play[]) =>
    plays?.find((play) => play.userId === userId && game.id === play.gameId);
  const userPlayedGame = matchGame(game.plays) || matchGame(plays);
  return (
    <>
      {showDetails && (
        <YourSelectionDetailView
          plays={plays}
          dismissModal={toggleShowDetails}
          game={game}
          userId={userId}
          name={username}
        />
      )}
      {userPlayedGame && (
        <GameResultContent
          toggleShowDetails={toggleShowDetails}
          showDetails={true}
          game={game}
          plays={plays}
          name={username}
          userId={userId}
        />
      )}
    </>
  );
}

function YourSelectionDetailView({
  game,
  plays,
  userId,
  name,
  dismissModal,
}: {
  game: Game;
  plays?: Play[]
  userId?: string;
  name?: string;
  dismissModal: () => void;
}) {
  const questions = game.definition.questions;
  const matchGame = (plays?: Play[]) =>
    plays?.find((play) => play.userId === userId && game.id === play.gameId);
  const userPlayedGame = matchGame(game.plays) || matchGame(plays);
  const play = userPlayedGame || (game.plays || plays)[0];
  const totalQuestions = play.entries.length;
  const totalCorrect = play.entries.filter((entry) => entry.correct).length;
  const anyPending = questions.some((question) => !question.answerId);
  const isPickEmOrCuratedPoll = anyPending;
  const useName = userId && name;
  const nameOrYou = useName ? name : 'You';
  const namesOrYour = useName ? name + "'s" : 'Your';

  const isAwaitingAnswer = play.entries.some(entry => entry.correct === undefined);

  const totalPossibleBonusPoints = questions.reduce((accumulator, question) => {
    return accumulator + question.points;
  }, 0);

  return (
    <Modal
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,.2)',
        alignSelf: 'center',
      }}
      animationType={'fade'}
      transparent={true}
      onRequestClose={dismissModal}
    >
      <ScrollView style={{height: '100%'}} contentContainerStyle={{height: '100%'}}>
        <View
          style={{
            alignItems: 'center',
            width: '100%',
            minHeight: '100%',
            maxWidth: 475,
            paddingTop: 90,
            paddingBottom: 32,
            backgroundColor: 'rgba(0, 21, 38, 0.8)',
            alignSelf: 'center',
            paddingHorizontal: 16,
          }}
        >
          <View
            style={{
              backgroundColor: '#1d2141',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
              padding: 12,
              width: '100%',
            }}
          >
            <BiggerTouchableOpacity
              onPress={dismissModal}
              style={{
                position: 'absolute',
                top: 12,
                right: 12,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
              }}
            >
              <Close height={18} width={18} tintColor={'white'}></Close>
            </BiggerTouchableOpacity>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 12,
              }}
            >
              <DText color={'white'} center={true} fontWeight={900} fontSize={Fonts.font18}>
                {`${nameOrYou} earned +${play.points} entries by\n playing this game!`}
              </DText>
              <DText color={colorPalette.neutral[200]} center={true} fontSize={Fonts.font14}>
                {anyPending
                  ? `Check back later to see ${namesOrYour.toLowerCase()} results!`
                  : `${nameOrYou} got ${totalCorrect}/${totalQuestions} questions correct.`}
              </DText>
            </View>
            {questions.map((question, index) => {
              const playForQuestion = play.entries.find((entry) => entry.questionId === question.questionId); 
              if (!playForQuestion) {
                return null;
              }
            const chosenAnswer =
              question.answerOptions[playForQuestion.answerId as any] || capitalize(playForQuestion.answerId as string);
              const correctAnwer = question.answerOptions[question.answerId as any];

              const colorToUse =
                playForQuestion.correct === undefined
                  ? Colors.grey400
                  : playForQuestion.correct
                  ? Colors.gameCenterQuestionRight
                  : Colors.gameCenterQuestionWrong;
              return (
                <View
                  key={index}
                  style={{
                    display: 'flex',
                    borderRadius: 4,
                    flexDirection: 'column',
                    width: '100%',
                    backgroundColor: 'rgba(255, 255, 255, .2)',
                    padding: 12,
                    gap: 16,
                  }}
                >
                  <DText center={true} color={'white'} fontWeight={900} fontSize={Fonts.font16}>
                    {question.questionText}
                  </DText>
                  <View style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 16 }}>
                    <TriviaEntryResult number={index + 1} result={playForQuestion} game={game} />
                    <View
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: 1,
                        justifyContent: 'center',
                        gap: 8,
                      }}
                    >
                      <DText color={Colors.white} fontWeight={900} fontSize={Fonts.font14}>
                        {`${isPickEmOrCuratedPoll ? '' : namesOrYour +  ' Answer: '}${chosenAnswer}`}
                      </DText>
                      {
                        anyPending ? null : (
                          <DText color={colorPalette.neutral[200]} fontSize={Fonts.font12}>
                            Correct Answer: {correctAnwer}
                          </DText>
                        )
                      }
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 9999,
                        borderWidth: 1,
                        paddingHorizontal: 15,
                        paddingVertical: 5,
                        height: 'auto',
                        alignSelf: 'center',
                        borderColor: playForQuestion.correct === undefined ? 'transparent' : colorToUse,
                        shadowColor: playForQuestion.correct === undefined ? 'transparent' : colorToUse,
                        backgroundColor: playForQuestion.correct === undefined ? colorToUse : 'transparent',
                        shadowRadius: 12,
                        shadowOpacity: 0.5,
                      }}
                    >
                      <DText color={playForQuestion.correct === undefined ? Colors.grey : colorToUse} fontWeight={900} fontSize={Fonts.font18}>
                        +{playForQuestion.correct !== undefined ? playForQuestion.correct === true ? playForQuestion.points : 0 : question.points}
                      </DText>
                    </View>
                  </View>
                </View>
              );
            })}
            <View style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <View
                style={{
                  width: '100%',
                  borderColor: '#474b5e',
                  borderWidth: 1,
                  height: 1.5,
                  marginVertical: 8,
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              />
              <View style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 8 }}>
                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <DText color={colorPalette.neutral[200]} fontSize={Fonts.font14}>
                    Drawing Entries Earned
                  </DText>
                  <DText color={Colors.white} fontSize={Fonts.font14} fontWeight={900}>
                    +{game.definition.defaultPointsForPlaying}
                  </DText>
                </View>
                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <DText color={colorPalette.neutral[200]} fontSize={Fonts.font14}>
                    Bonus Entries for Correct Picks
                  </DText>
                  <DText color={isAwaitingAnswer ? Colors.grey200 : Colors.white} fontSize={Fonts.font14} fontWeight={900}>
                    {
                      isAwaitingAnswer ? `Up to +${totalPossibleBonusPoints}` : '+' +(play.points - game.definition.defaultPointsForPlaying)
                    }
                  </DText>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  borderColor: '#474b5e',
                  borderWidth: 1,
                  height: 1.5,
                  marginVertical: 8,
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              />
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <DText color={colorPalette.neutral[200]} fontSize={Fonts.font14}>
                  Total Entries Earned
                </DText>
                <DText color={Colors.white} fontSize={Fonts.font14} fontWeight={900}>
                  {isAwaitingAnswer ? <PendingPill /> : '+' + play.points}
                </DText>
              </View>
            </View>
            <BiggerTouchableOpacity
              onPress={dismissModal}
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
                backgroundColor: colorPalette.cobalt['400'],
                paddingTop: 6,
                paddingBottom: 6,
                height: 36,
              }}
            >
              <DText color={colorPalette.white['100']} fontSize={Fonts.font16}>
                {'Close Details'}
              </DText>
            </BiggerTouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </Modal>
  );
}
