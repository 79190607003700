export interface Game {
  id: number;
  name: string;
  active: boolean;
  endDate: Date | string;
  startDate: Date | string;
  definition: {
    defaultPointsForPlaying: number;
    questions: {
      points: number;
      answerId: string;
      questionId: string;
      questionText: string;
      answerOptions: string[];
      answerOptionsImages: {
        id: string;
        answerId: string;
        url: string;
      }[];
      questionEndDate?: string;
    }[];
  };
  plays: Play[];
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt?: Date | string | null;
  challenge_games: {
    createdAt: Date | string;
    updatedAt: Date | string;
    challengeId: number;
    gameId: number;
  };
}

export interface Play {
  id: number;
  gameId: number;
  userId: string;
  entries: {
    questionId: string;
    answerId: string | null | undefined;
    correct: boolean;
    points: number;
  }[];
  points: number;
  completedAt: Date | string;
  updatedAt: Date | string;
  createdAt: Date | string;
  deletedAt?: Date | string | null;
}

export interface PlayRequest {
  gameId: number;
  entries: Entry[];
}

export type Entry = Omit<Play['entries'][number], 'correct' | 'points' | 'answerOptions'>;

export interface League {
  id: number;
  name: string;
  inviteCode?: string;
  type: string;
  definition: {
    [key: string]: any;
  };
  adminId: string;
  members: {
    name: string;
    userId: string;
    blocked: boolean;
    maskName: boolean;
    leagueId: number | null;
    ranking: {
      rank: number;
      points: number;
    };
    rankingThisWeek: {
      rank: number;
      points: number;
    };
  }[];
  plays: Play[];
  challenges: Challenge[];
  standings: {
    userId: string;
    points: number;
  }[];
  standingsThisWeek: {
    userId: string;
    points: number;
  }[];
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt?: Date | string | null;
}

export interface Challenge {
  id: number;
  name: string;
  active: boolean;
  startDate: Date | string;
  endDate: Date | string;
  definition: {
    sortPriority?: number;
    [key: string]: any;
  };
  games?: Game[];
  leagues?: League[];
  createdAt: Date | string;
  updatedAt: Date | string;
  type: string;
  deletedAt?: Date | string | null;
}

export interface User {
  id: number;
  pointsRedeemed?: number;
  pointsEarned?: number;
  pointsBalance: number;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt?: Date | string | null;
}

export interface Prize {
  id: number;
  name: string;
  description: string;
  cost: number;
  minPurchase: number;
  value: number;
  active: boolean;
  expiresAt: Date | string;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt?: Date | string | null;
}

export interface RedeemPrizeResponse {
  id: number;
  code: string;
  cost: number;
  prizeId: number;
  expiresAt: Date | string;
}

export interface RedemptionsResponse {
  id: number;
  cost: number;
  code: string;
  expiresAt: Date | string | null;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt: Date | string | null;
  userId: string | string;
  prizeId: string | number;
}

export type GameWithoutPlays = Omit<Game, 'plays'>;

export type GameTypes = 'trivia' | 'pickem' | 'curated_poll';
export const GameTypesToGameString: Record<GameTypes, string> = {
  trivia: 'Trivia',
  pickem: "Pick Em's",
  curated_poll: 'Poll',
};

export interface PickColors {
  [key: string]: {
    highlight: string;
    border: string;
    icon: JSX.Element;
  };
}

export interface PickedEm {
  borderColor: string;
  brightness: number;
  selected: boolean;
}

export interface TransactionsResponse {
  id: number;
  amount: number;
  type: string;
  description: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt?: Date | string | null;
  userId: string | number;
  playId: string | number;
  redemptionId: string | number;
}
