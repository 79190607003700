import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import DText from './DText';

enum CustomFontType {
  titleXXL = 'titleXXL',
  titleXL = 'titleXL',
  titleLG = 'titleLG',
  titleMD = 'titleMD',
  titleSM = 'titleSM',
  subtitleXL = 'subtitleXL',
  subtitleLG = 'subtitleLG',
  subtitleMD = 'subtitleMD',
  subtitleSM = 'subtitleSM',
  overlineBold = 'overlineBold',
  bodyBold = 'bodyBold',
  bodyMedium = 'bodyMedium',
  bodyRegular = 'bodyRegular',
  smallBold = 'smallBold',
  smallMedium = 'smallMedium',
  smallRegular = 'smallRegular',
  captionBold = 'captionBold',
  captionMedium = 'captionMedium',
  captionRegular = 'captionRegular',
  default = 'default',
}

interface ProgressTier {
  value: string;
  color?: string;
  size?: keyof typeof CustomFontType;
}

interface ProgressProps {
  progressTiers: {
    marker?: ProgressTier;
    label?: ProgressTier;
  }[];
  progress: number;
  barColor?: string;
  progressColor?: string;
  offset?: boolean;
  height?: number;
}

export const VSProgressBar: React.FC<ProgressProps> = ({
  progressTiers,
  progress,
  barColor,
  progressColor,
  offset = false,
  height,
}) => {
  const [barWidth, setBarWidth] = useState(0);

  const positiveTotal = Math.max(progressTiers?.length, 1);
  const barHeight = height || 16;

  const labelPadding = progressTiers.some((tier) => tier.label) ? barHeight : 0;
  const barBackgroundStyles = { backgroundColor: barColor || 'rgb(211, 211, 220)' };
  const barProgressStyles = {
    height: barHeight,
    width: progress <= 0 ? 0 : (Math.min(progress, positiveTotal) / positiveTotal) * barWidth,
    backgroundColor: progressColor || 'rgb(232, 169, 58)',
    borderTopRightRadius: progress >= positiveTotal - 0.1 ? 20 : 0,
    borderBottomRightRadius: progress >= positiveTotal - 0.1 ? 20 : 0,
  };

  const unitWidth = barWidth / progressTiers?.length;
  const offsetStyle = offset && { left: unitWidth / 2 };

  return (
    <View style={{ paddingBottom: labelPadding }}>
      <View
        style={[styles.barBackground, barBackgroundStyles]}
        onLayout={(event) => {
          return setBarWidth(event.nativeEvent.layout.width);
        }}
      >
        <View style={[styles.barProgress, barProgressStyles]} />
        <View style={[styles.progressTierContainer, offsetStyle]}>
          {progressTiers?.map((tier, index) => (
            <View style={styles.progressTier} key={index}>
              <ProgressTier progressTier={tier.marker} barHeight={barHeight} />
              <ProgressTier progressTier={tier.label} barHeight={barHeight} />
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

/**
 * Helper functions/Components
 */
const ProgressTier = ({
  progressTier,
  barHeight: lineHeight,
}: {
  progressTier?: ProgressTier;
  barHeight: number;
}) => {
  return (
    <DText
      style={{
        lineHeight,
      }}
      fontSize={Fonts.font12}
      color={progressTier?.color || Colors.white}
      fontWeight={900}
    >
      {progressTier?.value || ' '}
    </DText>
  );
};

const styles = StyleSheet.create({
  barBackground: {
    borderRadius: 20,
  },
  barProgress: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  progressTierContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    position: 'absolute',
  },
  progressTier: {
    alignItems: 'center',
  },
});
