import React from 'react';
import { View } from 'react-native';
import Colors from '../constants/Colors';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import DText from './DText';
import { GameCenterTabs } from '../routes/GameCenter';

export function GameCenterTab({
  activeTab,
  setActiveTab,
  children,
}: {
  activeTab: string;
  setActiveTab: (text: GameCenterTabs) => void;
  children: React.ReactNode;
}) {
  const active = activeTab === children;
  return (
    <BiggerTouchableOpacity
      onPress={() => {
        setActiveTab(children as GameCenterTabs);
      }}
      style={{ justifyContent: 'center', alignItems: 'center', width: '100%', flexShrink: 1 }}
    >
      <DText color={active ? Colors.borderBlue : 'white'}>{children}</DText>
      <View
        style={{
          marginTop: 6,
          width: '90%',
          height: 2,
          backgroundColor: active ? Colors.borderBlue : 'transparent',
        }}
      ></View>
    </BiggerTouchableOpacity>
  );
}
