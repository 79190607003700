import { View } from 'react-native';
import React, { useContext } from 'react';
import DText from './DText';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import Margin from './Margin';
import Fonts from '../constants/Fonts';
import { League } from '../interfaces';
import { WindowContext } from '../App';
import { getChallengeIcon } from '../Utils';

export const LeagueList = (props: {
  leagues: League[];
  width: number;
  challengeType?: string;
  setSelectedLeague: (league: League) => void;
}) => {
  const { accountId } = useContext(WindowContext);
  return (
    <View>
      {props.leagues
        ?.filter(({ type }) => !props.challengeType || props.challengeType === type)
        .map((league) => {
          const { id, name, adminId, members, type } = league;
          const isAdmin = adminId === accountId;
          const activeMember = members.find((member) => member.userId === accountId);
          return (
            <BiggerTouchableOpacity
              onPress={() => props.setSelectedLeague(league)}
              key={id}
              style={{
                width: '100%',
                minHeight: 77,
                paddingLeft: 16,
                paddingRight: 24,
                paddingTop: 16,
                paddingBottom: 16,
                marginBottom: 16,
                borderRadius: 8,
                backgroundColor: 'rgba(255,255,255,.1)',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    width: 46,
                    height: 46,
                    borderRadius: 23,
                    backgroundColor: 'rgba(255,255,255,.1)',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <DText fontSize={24} color="white" fontWeight={900}>
                    {getChallengeIcon(type)}
                  </DText>
                </View>
                <Margin width={12} />
                <View
                  style={{
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    maxWidth: props.width - 150,
                  }}
                >
                  <DText color="white" fontSize={Fonts.font14} lineHeight={'150%'} fontWeight={900}>
                    {name}
                  </DText>
                  {isAdmin && (
                    <DText color="white" fontSize={Fonts.font14} lineHeight={'150%'}>
                      {'Owner'}
                    </DText>
                  )}
                </View>
                <Margin width={12} />
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <DText color="white" fontSize={Fonts.font14} lineHeight={'150%'} fontWeight={900}>
                  {'Points'}
                </DText>
                <DText color="white" fontSize={Fonts.font14} lineHeight={'150%'} fontWeight={900}>
                  {activeMember?.ranking.points}
                </DText>
              </View>
            </BiggerTouchableOpacity>
          );
        })}
    </View>
  );
};
