import { Image, View } from 'react-native';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import DText from './DText';
import { colorPalette } from '../themes/variables';

export function PromotionCard() {
  return (
    <View
      style={{
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      <View
        style={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <DText color={colorPalette.neutral[200]} fontSize={18} fontWeight={800}>
            Play real money games on Vivid Picks
          </DText>
        </View>
        <BiggerTouchableOpacity
          style={{ width: '100%', borderRadius: 8, overflow: 'hidden' }}
          onPress={() => {
            // TODO: Migrate this page?
            location.href =
              'https://i.play.vividpicks.com/vsnp?utm_term=vp_banner_100x_nba&utm_campaign=Game_Center';
          }}
        >
          <Image
            source={{
              uri: 'https://media.vsstatic.com/image/upload/v1704233726/CRM/VP/running-campaigns/100x/100x-nba-Mobile-2x.jpg',
            }}
            style={{
              width: '100%',
              height: undefined,
              aspectRatio: 1.27,
            }}
          />
        </BiggerTouchableOpacity>
      </View>
    </View>
  );
}
