import { useCallback, useEffect, useRef } from 'react';
import { AppState, AppStateStatus, GestureResponderEvent } from 'react-native';

const useInactivityDetector = (onInactive: () => void, timeout = 5000) => {
    const inactivityTimer = useRef<NodeJS.Timeout>();
    const appState = useRef<AppStateStatus | string>(AppState.currentState);

    const resetInactivityTimer = useCallback((_event?: GestureResponderEvent) => {
        clearTimeout(inactivityTimer.current);
        inactivityTimer.current = setTimeout(onInactive, timeout);
    }, [onInactive, timeout]);

    useEffect(() => {
        const handleAppStateChange = (nextAppState: string) => {
            if (nextAppState === 'active') {
                resetInactivityTimer();
            }
            appState.current = nextAppState;
        };

        const subscription = AppState.addEventListener('change', handleAppStateChange);

        resetInactivityTimer();

        return () => {
            clearTimeout(inactivityTimer.current);
            subscription.remove();
        };
    }, [onInactive, resetInactivityTimer, timeout]);

    return { resetInactivityTimer };
};

export default useInactivityDetector;
