import { View } from 'react-native';
import { Gifts } from './images';
import DText from './DText';
import Fonts from '../constants/Fonts';

export function PrizeContainer({
  prizeText,
  prizeSubText,
}: {
  prizeText: string;
  prizeSubText: string;
}) {
  return (
    <View
      style={{
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 4,
        gap: 6,
      }}
    >
      <Gifts height={24} width={24} tintColor={'#28aef0'} />
      <View>
        <DText color={'#28aef0'} fontSize={Fonts.font16} fontWeight={900}>
          {prizeText}
        </DText>
        {prizeSubText && (
          <DText color={'#28aef0'} fontSize={Fonts.font12} fontWeight={900}>
            {prizeSubText}
          </DText>
        )}
      </View>
    </View>
  );
}
