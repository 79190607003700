// import React from 'reactn'
import React from 'react';
import { Text, Platform } from 'react-native';
import Colors from '../constants/Colors';

const fontFamily = Platform.select({
  android: 'GT-Walsheim-Regular',
  web: 'GT-Walsheim-Regular',
  ios: 'GT-Walsheim-Regular', //Avenir Next'
});

interface DTextProps {
  fontWeight?: number | string;
  bold?: boolean;
  fontSize?: number;
  color: string;
  marginTop?: number;
  marginBottom?: number;
  letterSpacing?: number;
  italic?: boolean;
  children?: any;
  lineHeight?: string;
  style?: any;
  fontFamily?: string;
  center?: boolean;
  adjustsFontSizeToFit?: boolean;
  ellipsizeMode?: any;
  numberOfLines?: number;
}

const DText = function (props: DTextProps) {
  const defaultObj: any = { fontFamily: fontFamily, color: Colors.textBlack };

  if (props.fontWeight) {
    defaultObj.fontWeight = props.fontWeight.toString();
  } else if (props.bold) {
    defaultObj.fontWeight = '800';
  }
  if (props.fontSize) {
    defaultObj.fontSize = props.fontSize;
  }
  if (props.color) {
    defaultObj.color = props.color;
  }
  if (props.marginTop) {
    defaultObj.marginTop = props.marginTop;
  }
  if (props.marginBottom) {
    defaultObj.marginBottom = props.marginBottom;
  }
  if (props.letterSpacing) {
    defaultObj.letterSpacing = props.letterSpacing;
  }
  if (props.italic) {
    defaultObj.fontStyle = 'italic';

    switch (defaultObj.fontWeight) {
      case '800':
      case '900':
      case '700':
      case '600':
        defaultObj.fontFamily = 'Aeonik-BoldItalic';
        defaultObj.fontWeight = '700';
        break;

      default:
        defaultObj.fontFamily = 'Aeonik-RegularItalic';
        break;
    }
  } else {
    switch (defaultObj.fontWeight) {
      case '800':
      case '900':
      case 800:
      case 900:
        defaultObj.fontFamily = 'GT-Walsheim-Bold';
        defaultObj.fontWeight = '500';
        break;
      case '700':
      case '600':
      case 700:
      case 600:
        defaultObj.fontFamily = 'GT-Walsheim-Medium';
        defaultObj.fontWeight = '500';
        break;
      default:
        defaultObj.fontFamily = 'GT-Walsheim-Regular';
        break;
    }
  }

  if (props.fontFamily) {
    defaultObj.fontFamily = props.fontFamily;
  }

  if (props.center) {
    defaultObj.textAlign = 'center';
  }
  if (props.lineHeight) {
    defaultObj.lineHeight = props.lineHeight;
  }
  let obj;
  if (props.style) {
    if (Array.isArray(props.style)) {
      obj = [defaultObj];
      let s;
      for (s of props.style) {
        obj.push(s);
      }
    } else {
      obj = [defaultObj, { ...props.style }];
    }
  } else {
    obj = defaultObj;
  }
  //obj.fontStyle = 'rounded'
  return (
    <Text
      allowFontScaling={false}
      style={obj}
      adjustsFontSizeToFit={props.adjustsFontSizeToFit ? true : false}
      ellipsizeMode={props.ellipsizeMode}
      numberOfLines={props.numberOfLines}
      // onPress={props.onPress ? () => props.onPress() : null}>
    >
      {props.children}
    </Text>
  );
};

// DText.propTypes = {
// 	fontWeight: PropTypes.any,
// 	bold: PropTypes.bool,
// 	fontSize: PropTypes.number,
// 	color: PropTypes.string,
// 	marginTop: PropTypes.number,
// 	marginBottom: PropTypes.number,
// 	letterSpacing: PropTypes.number,
// 	italic: PropTypes.bool,
// 	center: PropTypes.bool,
// 	lineHeight: PropTypes.any,
// 	style: PropTypes.any,
// 	adjustsFontSizeToFit: PropTypes.bool,
// 	ellipsizeMode: PropTypes.any,
// 	numberOfLines: PropTypes.number,
// 	children: PropTypes.any,
// 	onPress: PropTypes.any,
// }

export default DText;
