import { StyleSheet, Text, View } from 'react-native';

console.log(window.location.href);
export default function Root() {
  return (
    <View style={styles.container}>
      <Text>Root betcha widgets start!</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#04092C',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
