import React from 'react';
import { Image, ImageResizeMode } from 'react-native';

interface imageProps {
  top?: number;
  tintColor?: string;
  resizeMode?: ImageResizeMode;
  width: number | string;
  height: number | string;
  style?: any;
}

const ImageDisplay = function (image: any, props: imageProps, additionalStyle: any = {}) {
  const top = props.top || 0;
  let tintColor;
  if (props.tintColor) {
    tintColor = { tintColor: props.tintColor };
    return (
      <Image
        // tintColor={props.tintColor}
        source={image}
        resizeMode={props.resizeMode || 'contain'}
        style={[
          { marginTop: top, width: props.width, height: props.height },
          props.style ? { ...props.style } : {},
          additionalStyle,
          tintColor,
        ]}
      />
    );
  } else {
    return (
      <Image
        source={image}
        resizeMode={props.resizeMode || 'contain'}
        style={[
          { marginTop: top, width: props.width, height: props.height },
          props.style ? { ...props.style } : {},
          additionalStyle,
        ]}
      />
    );
  }
};

export const EditRoundSolid = function (props: imageProps) {
  return ImageDisplay(
    require('../../assets/images/edit-round-solid/edit-round-solid@3x.png'),
    props,
  );
};

export const PropEmptyPlayerAvatar = function (props: imageProps) {
  return ImageDisplay(
    require('../../assets/images/propEmptyPlayerAvatar/propEmptyPlayerAvatar@3x.png'),
    props,
  );
};

export const UnderArrow = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/underArrow/under-arrow@3x.png'), props);
};

export const OverArrow = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/overArrow/over-arrow@3x.png'), props);
};

export const Close = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Close/Close@3x.png'), props);
};

export const Info = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Info/Info@3x.png'), props);
};

export const Clock = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Clock/Clock@3x.png'), props);
};

export const AchievementLockIconSmall = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/achievements/Lock Icon.png'), props);
};

export const Blur = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Blur/Blur@3x.png'), props);
};

export const AchievementLockIconLarge = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/achievements/LockedAchievement.png'), props);
};

export const ChevronUp = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Chevrons/Up.png'), props);
};

export const ChevronDown = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Chevrons/Down.png'), props);
};

export const Bell = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Bell/Bell@3x.png'), props);
};

export const Tie = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Tie/Tie@3x.png'), props);
};

export const Pending = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Time/hourglass-alternate@3x.png'), props);
};

export const Ticket = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Ticket/Ticket@3x.png'), props);
};

export const Check = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Check/Check@3x.png'), props);
};

export const Minus = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Minus/Minus@3x.png'), props);
};

export const Forward = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Forward/Forward@3x.png'), props);
};

export const BlurGCSummary = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/BlueGCSummary/Ellipse18@3x.png'), props);
};

export const Gifts = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Gifts/gift-box@3x.png'), props);
};

export const Money = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Money/accounting-coins-stack@3x.png'), props);
};

export const Logo = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Logo/Brandmark@3x.png'), props);
};

export const LeftArrow = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/LeftArrow/Back@3x.png'), props);
};

export const Accuracy = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Accuracy/accuracy.png@3x.png'), props);
};

export const DaysPlayed = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/DaysPlayed/daysplayed@3x.png'), props);
};

export const Fail = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Fail/Fail@3x.png'), props);
};

export const Copy = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Copy/Copy@3x.png'), props);
};

export const IOSStore = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/downloadApp/ios@3x.png'), props);
};

export const GoogleStore = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/downloadApp/google@3x.png'), props);
};

export const AddUser = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/AddUser/CombinedShape@3x.png'), props);
};

export const LeaveLeague = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/LeaveLeague/logout-1-alternate2@3x.png'), props);
};

export const Edit = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Edit/Edit@3x.png'), props);
};

export const Invite = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Invite/EmailActionSend@3x.png'), props);
};

export const Settings = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Settings/Cog@3x.png'), props);
};

export const Trophy = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Trophy/Trophy-Outline@3x.png'), props);
};

export const Trash = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/Trash/Delete@3x.png'), props);
};

export const User = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/User/User@3x.png'), props);
};

export const Block = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/BlockUser/BlockUser@3x.png'), props);
};

export const EyeHidden = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/EyeHidden/EyeHidden@3x.png'), props);
};

export const EyeShown = function (props: imageProps) {
  return ImageDisplay(require('../../assets/images/EyeShown/EyeShown@3x.png'), props);
};
