export const TeamImages = {
  // NFL
  'Arizona Cardinals':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148720/Product/app/features/game-center/pick-ems/nfl/arizona-cardinals-thumbnail_r0bc5h.png',
  'Atlanta Falcons':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146871/Product/app/features/game-center/pick-ems/nfl/atlanta-falcons-thumbnail_kpsv9n.png',
  'Baltimore Ravens':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148703/Product/app/features/game-center/pick-ems/nfl/baltimore-ravens-thumbnail_ep4i8i.png',
  'Buffalo Bills':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148705/Product/app/features/game-center/pick-ems/nfl/buffulo-bills-thumbnail_zrjyui.png',
  'Carolina Panthers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146858/Product/app/features/game-center/pick-ems/nfl/carolina-panthers-thumbnail_lzkrix.png',
  'Chicago Bears':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148704/Product/app/features/game-center/pick-ems/nfl/chicago-bears-thumbnail_zhtsxd.png',
  'Cincinnati Bengals':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148700/Product/app/features/game-center/pick-ems/nfl/cincinnati-bengals-thumbnail_wh9idu.png',
  'Cleveland Browns':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146870/Product/app/features/game-center/pick-ems/nfl/cleveland-browns-thumbnail_tff9iq.png',
  'Dallas Cowboys':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146868/Product/app/features/game-center/pick-ems/nfl/dallas-cowboys-thumbnail_zhjsi1.png',
  'Denver Broncos':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148716/Product/app/features/game-center/pick-ems/nfl/denver-broncos-thumbnail_ra6ccf.png',
  'Detroit Lions':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148699/Product/app/features/game-center/pick-ems/nfl/detroit-lions-thumbnail_qcftll.png',
  'Green Bay Packers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148694/Product/app/features/game-center/pick-ems/nfl/green-bay-packers-thumbnail_ssoe43.png',
  'Houston Texans':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146863/Product/app/features/game-center/pick-ems/nfl/houston-texans-thumbnail_vktjsx.png',
  'Indianapolis Colts':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148691/Product/app/features/game-center/pick-ems/nfl/indianapolis-colts-thumbnail_rca5og.png',
  'Jacksonville Jaguars':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148717/Product/app/features/game-center/pick-ems/nfl/jacksonville-jaguars-thumbnail_d6yxrr.png',
  'Kansas City Chiefs':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148721/Product/app/features/game-center/pick-ems/nfl/kansas-city-chiefs-thumbnail_zxnbrw.png',
  'Las Vegas Raiders':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148691/Product/app/features/game-center/pick-ems/nfl/las-vegas-raiders-thumbnail_vs9fhk.png',
  'Los Angeles Chargers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148724/Product/app/features/game-center/pick-ems/nfl/los-angeles-chargers-thumbnail_qmckrn.png',
  'Los Angeles Rams':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146873/Product/app/features/game-center/pick-ems/nfl/los-angeles-rams-thumbnail_nmfdcc.png',
  'Miami Dolphins':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146874/Product/app/features/game-center/pick-ems/nfl/miami-dolphins-thumbnail_pgbpwd.png',
  'Minnesota Vikings':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146872/Product/app/features/game-center/pick-ems/nfl/minnesota-vikings-thumbnail_wbrn8i.png',
  'New England Patriots':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146859/Product/app/features/game-center/pick-ems/nfl/new-england-patriots-thumbnail_ql8kvf.png',
  'New Orleans Saints':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146876/Product/app/features/game-center/pick-ems/nfl/new-orleans-saints-thumbnail_zkjgkr.png',
  'New York Giants':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148688/Product/app/features/game-center/pick-ems/nfl/new-york-giants-thumbnail_zaxict.png',
  'New York Jets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148689/Product/app/features/game-center/pick-ems/nfl/new-york-jets-thumbnail_vnnyvt.png',
  'Philadelphia Eagles':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148713/Product/app/features/game-center/pick-ems/nfl/philadelphia-eagles-thumbnail_ym7mqj.png',
  'Pittsburgh Steelers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148687/Product/app/features/game-center/pick-ems/nfl/pittsburgh-thumbnail_whhzgg.png',
  'San Francisco 49ers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723147172/Product/app/features/game-center/pick-ems/nfl/san-francisco-49ers-thumbnail_tdmakt.png',
  'Seattle Seahawks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148704/Product/app/features/game-center/pick-ems/nfl/seattle-seahawks-thumbnail_tnoxms.png',
  'Tampa Bay Buccaneers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148722/Product/app/features/game-center/pick-ems/nfl/tampa-bay-buccaneers-thumbnail_yhrdn2.png',
  'Tennessee Titans':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723147065/Product/app/features/game-center/pick-ems/nfl/tennessee-titans-thumbnail_uukkkm.png',
  'Washington Commanders':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723147137/Product/app/features/game-center/pick-ems/nfl/washington-commanders-thumbnail_untztv.png',

  // NBA
  'Atlanta Hawks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/52-nba-atlanta-hawks/atlanta-hawks-tickets-2.jpg',
  'Boston Celtics':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/105-nba-boston-celtics/boston-celtics-tickets-2.jpg',
  'Brooklyn Nets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/29773-nba-brooklyn-nets/brooklyn-nets-tickets-2.jpg',
  'Charlotte Hornets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/4547-nba-charlotte-hornets/charlotte-hornets-tickets-2.jpg',
  'Chicago Bulls':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/161-nba-chicago-bulls/chicago-bulls-tickets-2.jpg',
  'Cleveland Cavaliers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/182-nba-cleveland-cavaliers/cleveland-cavaliers-tickets-2.jpg',
  'Dallas Mavericks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/215-nba-dallas-mavericks/dallas-mavericks-tickets-2.jpg',
  'Denver Nuggets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/235-nba-denver-nuggets/denver-nuggets-tickets-2.jpg',
  'Detroit Pistons':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/239-nba-detroit-pistons/detroit-pistons-tickets-2.jpg',
  'Golden State Warriors':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/328-nba-golden-state-warriors/golden-state-warriors-tickets-3.jpg',
  'Houston Rockets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/360-nba-houston-rockets/houston-rockets-tickets-2.jpg',
  'Indiana Pacers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/369-nba-indiana-pacers/indiana-pacers-tickets-2.jpg',
  'Los Angeles Clippers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/480-nba-los-angeles-clippers/los-angeles-clippers-tickets-2.jpg',
  'Los Angeles Lakers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/483-nba-los-angeles-lakers/los-angeles-lakers-tickets-2.jpg',
  'Memphis Grizzlies':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/910-nba-memphis-grizzlies/memphis-grizzlies-tickets-2.jpg',
  'Miami Heat':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/533-nba-miami-heat/miami-heat-tickets-2.jpg',
  'Milwaukee Bucks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/550-nba-milwaukee-bucks/milwaukee-bucks-tickets-2.jpg',
  'Minnesota Timberwolves':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/553-nba-minnesota-timberwolves/minnesota-timberwolves-tickets-2.jpg',
  'New Orleans Pelicans':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/153-nba-new-orleans-pelicans/new-orleans-pelicans-tickets-2.jpg',
  'New York Knicks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/602-nba-new-york-knicks/new-york-knicks-tickets-2.jpg',
  'Oklahoma City Thunder':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/16164-nba-oklahoma-city-thunder/oklahoma-city-thunder-tickets-2.jpg',
  'Orlando Magic':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/636-nba-orlando-magic/orlando-magic-tickets-2.jpg',
  'Philadelphia 76ers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/668-nba-philadelphia-76ers/philadelphia-76ers-tickets-2.jpg',
  'Phoenix Suns':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/679-nba-phoenix-suns/phoenix-suns-tickets-2.jpg',
  'Portland Trail Blazers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/690-nba-portland-trail-blazers/portland-trail-blazers-tickets-2.jpg',
  'Sacramento Kings':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/748-nba-sacramento-kings/sacramento-kings-tickets-2.jpg',
  'San Antonio Spurs':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/754-nba-san-antonio-spurs/san-antonio-spurs-tickets-2.jpg',
  'Toronto Raptors':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/879-nba-toronto-raptors/toronto-raptors-tickets-2.jpg',
  'Utah Jazz':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/902-nba-utah-jazz/utah-jazz-tickets-2.jpg',
  'Washington Wizards':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/hero/event/sports/926-nba-washington-wizards/washington-wizards-tickets-2.jpg',

  // MLB Coming Soon
} as const;
