import { View } from 'react-native';
import { Challenge } from '../interfaces';
import DText from './DText';
import Fonts from '../constants/Fonts';
import { colorPalette } from '../themes/variables';
import { useCallback, useContext } from 'react';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import { WindowContext } from '../App';

export function HistoryCard({ challenge }: { challenge: Challenge }) {
    const windowValues = useContext(WindowContext);
    const score = challenge.games
    ?.map((game) => {
      const points = game.plays.reduce((accumulator, play) => {
        return accumulator + play.points;
      }, 0);

      return points;
    })
    .reduce((accumulator, points) => {
      return accumulator + points;
    }, 0);

    const startDate = new Date(challenge.startDate).toLocaleDateString();
    const endDate = new Date(challenge.endDate).toLocaleDateString();

    const onPress = useCallback(() => {
        windowValues.setChallenge(challenge);
    }, [challenge, windowValues]);

    return (
        <BiggerTouchableOpacity
        onPress={onPress}
        style={{
            borderRadius: 4,
            backgroundColor: 'rgba(255,255,255,.1)',
            width: '100%',
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        }}>
            <View style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <DText color={'white'} fontWeight={900} fontSize={Fonts.font14}>{challenge.name}</DText>
                <DText color={'white'} fontWeight={900} fontSize={Fonts.font14}>{score ? '+' + score : '-'}</DText>
            </View> 
            <View style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <DText color={colorPalette.neutral[200]} fontSize={Fonts.font14}>{startDate} - {endDate}</DText>
            </View>
        </BiggerTouchableOpacity>
    );
}