import { Image, StyleSheet, View, useWindowDimensions } from 'react-native';
import { colorPalette } from '../../themes/variables';
import { useContext } from 'react';
import { WindowContext } from '../../App';
import { CoinsAndTrianglesOverlay } from './CoinsAndTrianglesOverlay';

export function GameCenterContainer({ children }: { children: React.ReactNode }) {
  const { isPhone } = useContext(WindowContext);
  const { width } = useWindowDimensions();
  const isDesktop = width >= 1440;

  return (
    <View style={styles.mainContainer}>
      <Image
        source={require('./assets/vividseatslogo.png')}
        style={
          {
            width: isDesktop ? '15%' : '30%',
            height: 'auto',
            position: 'absolute',
            top: 20,
            left: '50%',
            transform: [{ translateX: '-50%' }],
            aspectRatio: 6,
            zIndex: 10,
          } as any
        }
      />
      <Image
        source={require('./assets/gamecentershortheader.png')}
        style={
          {
            position: 'absolute',
            top: 30,
            width: isDesktop ? '40%' : '60%',
            left: '50%',
            transform: [{ translateX: '-50%' }],
            aspectRatio: 6,
            zIndex: 10,
          } as any
        }
      />
      <View
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 0,
          pointerEvents: 'none',
        }}
      >
        <CoinsAndTrianglesOverlay animate={false} zIndex={0} />
      </View>
      <View style={{ width: '100%', paddingHorizontal: isPhone ? 16 : 54, paddingBottom: 32 }}>
        {children}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    flexDirection: 'column',
    justifySelf: 'center',
    alignItems: 'center',
    zIndex: 0,
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
});
