import { ActivityIndicator, View } from 'react-native';
import React, { useContext, useState } from 'react';
import Colors from '../constants/Colors';
import DText from './DText';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import Margin from './Margin';
import Fonts from '../constants/Fonts';
import CtaButton from './CtaButton';
import { Close } from './images';
import { updateLeagueV2 } from '../api';
import { TextBox } from './TextBox';
import { WindowContext } from '../App';

export interface LeagueEditLeagueNameProps {
  leagueId?: string;
  leagueName?: string;
  width: number;
  leaderboardIcon: string;
  onCancel: (deletedLeague?: boolean) => void;
  refetchLeague: () => Promise<any>;
}

export const LeagueEditLeagueName = (props: LeagueEditLeagueNameProps) => {
  const windowValues = useContext(WindowContext);

  const [leagueName, setLeagueName] = useState(props.leagueName);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const changeName = async () => {
    setSubmitting(true);
    try {
      await updateLeagueV2(windowValues)({
        queryKey: ['leagueId', props.leagueId],
        league: { name: leagueName },
      });
      await props.refetchLeague();
      setSubmitting(false);
      props.onCancel();
    } catch (err) {
      setSubmitting(false);
      setErrorMessage((err as { message: string }).message);
    }
  };

  const buttonWidth = (props.width - 40) / 2;

  const renderErrorMessage = () => {
    if (!errorMessage) {
      return;
    }
    return (
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: props.width,
          minHeight: 64,
          padding: 8,
          borderRadius: 8,
          backgroundColor: Colors.white10, // colorPalette.neutral['700'], //rgba(255,255,255,.1)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Margin height={12} />
        <DText style={{ padding: 36 }} center={true} fontSize={Fonts.font18} color="white">
          There was an error changing the league name:
        </DText>
        <DText center={true} fontSize={Fonts.font16} color="white">
          {'Error: ' + errorMessage}
        </DText>
        <Margin height={36} />
        <CtaButton
          outline={true}
          width={buttonWidth}
          active={true}
          text="Cancel"
          onPress={() => {
            props.onCancel();
          }}
        />
        <Margin height={16} />
      </View>
    );
  };

  return (
    <View
      key={'enterleagueBoard'}
      style={{
        width: props.width,
        minHeight: 64,
        padding: 8,
        borderRadius: 8,
        backgroundColor: Colors.white10, // colorPalette.neutral['700'], //rgba(255,255,255,.1)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Margin height={16} />
      <View
        style={{
          width: 48,
          height: 48,
          borderRadius: 24,
          backgroundColor: 'rgba(255,255,255,.1)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DText
          style={{ backgroundColor: 'transparent' }}
          fontSize={32}
          color="white"
          fontWeight={900}
        >
          {props.leaderboardIcon}
        </DText>
      </View>
      <Margin height={8} />
      <DText color="white" fontSize={Fonts.font16} lineHeight={'150%'} fontWeight={900}>
        Change League Name
      </DText>
      <Margin height={8} />
      <TextBox
        label={'League Name'}
        value={leagueName || ''}
        onChangeText={setLeagueName}
        width={props.width - 32}
      />
      <Margin height={32} />
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: props.width - 32,
        }}
      >
        <CtaButton
          outline={true}
          width={buttonWidth}
          active={true}
          text="Cancel"
          onPress={() => {
            props.onCancel();
          }}
        />
        <CtaButton
          onPress={() => {
            changeName();
          }}
          width={buttonWidth}
          active={!!leagueName?.trim().length}
          text="Continue"
        />
      </View>
      <Margin height={16} />
      <BiggerTouchableOpacity
        disabled={submitting}
        onPress={() => {
          props.onCancel();
        }}
        style={{
          position: 'absolute',
          top: 12,
          right: 8,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Close height={18} width={18} tintColor={'white'}></Close>
      </BiggerTouchableOpacity>
      {submitting && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: Colors.betchaBlack80Percent,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator animating={true} size={'large'} color={Colors.white} />
        </View>
      )}
      {renderErrorMessage()}
    </View>
  );
};
