import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Margin from './Margin';
import Colors from '../constants/Colors';
import { colorPalette } from '../themes/variables';

export function LoadingPage() {
  return (
    <View style={styles.mainContainer}>
      <Margin height={64} />
      <ActivityIndicator animating={true} size={'large'} color={Colors.white} />
      <Margin height={64} />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    flexDirection: 'column',
    maxWidth: 475,
    width: '100%'
  },
  container: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 475,
  },
});
