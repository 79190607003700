import React from 'react';
import { View } from 'react-native';

interface MarginProps {
  height?: number;
  width?: number;
  backgroundColor: string;
}

const Margin: any = function (props: MarginProps) {
  const bk = props.backgroundColor || 'transparent';
  return (
    <View style={{ backgroundColor: bk, height: props.height || 1, width: props.width || 1 }} />
  );
};

export default Margin;
