import React from 'react';
import { StyleSheet, TextInput, View, Modal } from 'react-native';
import { colorPalette } from '../themes/variables';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import DText from './DText';
import { Close } from './images';
import Margin from './Margin';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

export const renderCopyTextModal = (visible: boolean, onClose: () => void, copyText: string) => {
  const textInputStyle: any = {
    color: 'white',
    marginRight: 16,
    marginLeft: 16,
    width: '100%',
    minHeight: 200,
    display: 'inlineBlock',
    padding: 12,
    backgroundColor: 'transparent',
    fontSize: 12,
    fontFamily: 'GT-Walsheim-Regular',
  };
  return (
    <Modal
      style={{
        zIndex: 5000,
        width: '100%',
        minHeight: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,.2)',
      }}
      animationType={'fade'}
      transparent={true}
      onRequestClose={() => false}
      visible={visible}
    >
      <View key="androidCopy" style={[styles.mainContainer, { backgroundColor: 'transparent' }]}>
        <View
          style={[
            styles.container,
            { backgroundColor: Colors.betchaBlack40Percent },
            {
              flex: 1,
              paddingLeft: 16,
              paddingRight: 16,
              justifyContent: 'flex-start',
            },
          ]}
        >
          <Margin height={12} />
          <View
            style={{
              width: '100%',
              minHeight: 64,
              padding: 8,
              borderRadius: 8,
              backgroundColor: Colors.white10, // colorPalette.neutral['700'], //rgba(255,255,255,.1)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Margin height={24} />
            <DText color={'white'} fontWeight={900} fontSize={Fonts.font16} lineHeight={'150%'}>
              Copy the Text Below
            </DText>
            <Margin height={8} />
            <TextInput
              autoCapitalize={'none'}
              autoComplete={'off'}
              autoCorrect={false}
              value={copyText}
              multiline={true}
              autoFocus={true}
              selectTextOnFocus={true}
              style={textInputStyle}
            ></TextInput>
            <BiggerTouchableOpacity
              disabled={false}
              onPress={() => {
                onClose();
              }}
              style={{
                position: 'absolute',
                top: 12,
                right: 8,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Close height={18} width={18} tintColor={'white'}></Close>
            </BiggerTouchableOpacity>
            <Margin height={16} />
          </View>
          <Margin height={16} />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flex: 1,
    maxWidth: 475,
  },
});
