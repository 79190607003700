import { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import {
  Modal,
  StyleSheet,
  View,
  PanResponder,
  PanResponderInstance,
  ScrollView,
} from 'react-native';
import { WindowContext, queryClient } from '../App';
import {
  Entry,
  Play,
  PlayRequest,
  Game,
  GameWithoutPlays,
  PickedEm,
  Challenge,
} from '../interfaces';
import { postPlay } from '../api';
import { Check, Close, Pending } from './images';
import DText from './DText';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { colorPalette } from '../themes/variables';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import { SlideInView } from './SlideInView';
import { getChallengeIcon } from '../Utils';
import Margin from './Margin';

export function CuratedPollModal({
  game,
  onCloseModal,
  challenge,
}: {
  challenge: Challenge;
  game: Omit<Game, 'plays'>;
  onCloseModal: () => void;
}) {
  // This is the play objecct that will eventually get filled and sent to the server
  const [play, setPlay] = useState<PlayRequest>({
    gameId: game.id,
    entries: [],
  });

  // If we are in a submitting state
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  // The current question index to render, always start at index 0.
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  // The list of questions from the game definition
  const questions = useMemo(() => game.definition.questions, [game]);

  // How many total questions there are in this game
  const maxQuestions = questions.length;
  // The maximum index we can hit before the game is done
  const maxQuestionIndex = maxQuestions - 1;

  // This determines whether or not to lock the state of the game, so that people can't answer after the question is done
  const [gameLock, setGameLock] = useState(false);
  // This determines how long (in seconds) to lock the game state to show the result
  const GAME_LOCK_TIME = 0.5;

  const [gameResults, setGameResults] = useState<Play | null>(null);

  const addEntry = useCallback(
    (entry: Entry, paginate = true) => {
      setGameLock(true);

      // Whenever a play entry is added, append it to the play state that will get sent out in the postPlay request
      setPlay((prevPlay) => {
        const previousPlayEntries = prevPlay.entries;

        const foundPreviousEntry = previousPlayEntries.find((previousPlayEntry) => {
          return previousPlayEntry.questionId === entry.questionId;
        });
        // If we find a previous play and they're overwriting their past selection, remove the old one before adding the new one
        if (foundPreviousEntry) {
          previousPlayEntries.splice(previousPlayEntries.indexOf(foundPreviousEntry), 1);
        }
        const updatedEntries = [...previousPlayEntries, entry];
        const playRecord = {
          ...prevPlay,
          entries: updatedEntries,
        };
        return playRecord;
      });

      if (currentQuestionIndex === maxQuestionIndex || !paginate) {
        setGameLock(false);
        return;
      }

      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSwipeDirection('forward');
        setGameLock(false);
      }, GAME_LOCK_TIME * 1000);
    },
    [currentQuestionIndex, maxQuestionIndex],
  );

  // The current question based on the index
  const currentQuestion = useMemo(() => {
    const question = questions[currentQuestionIndex];
    return question;
  }, [currentQuestionIndex, questions]);

  // Pad all the entries that have expired with N/A as the answer option
  useEffect(() => {
    questions.forEach((question, index) => {
      if (question.questionEndDate) {
        const questionEndDate = new Date(question.questionEndDate);
        const now = new Date();
        if (now > questionEndDate) {
          if (question.answerId !== 'none') {
            addEntry(
              {
                questionId: String(index) || '',
                answerId: 'none',
              },
              false,
            );
          }
        }
      }
    });
  }, [addEntry, questions]);

  useEffect(() => {
    const firstAvailableQuestionIndex = questions.findIndex((question) => {
      if (!question.questionEndDate) {
        return true;
      }
      if (question.questionEndDate) {
        const questionEndDate = new Date(question.questionEndDate);
        const now = new Date();
        return now < questionEndDate;
      }
    });
    setCurrentQuestionIndex(firstAvailableQuestionIndex);
  }, [setCurrentQuestionIndex, questions]);

  const blurCurrentQuestion = () => {
    const now = new Date();
    const question = questions[currentQuestionIndex];
    if (question.questionEndDate && now > new Date(question.questionEndDate)) {
      return true;
    }
    return false;
  };

  // The answer options for that current question
  const answerOptions = useMemo(() => currentQuestion?.answerOptions, [currentQuestion]) || [];

  const closeAndClear = useCallback(() => {
    setGameResults(null);
    onCloseModal();
  }, [onCloseModal]);

  const windowValues = useContext(WindowContext);

  const optimisticGradePlay = useCallback(
    ({ play, game }: { play: PlayRequest; game: Omit<Game, 'plays'> }) => {
      // Step 1: Create the object without the total points, and entries for this play dont have points since they're ungraded
      const optimisticPlay = {
        ...play,
        entries: play.entries.map((entry) => {
          return {
            ...entry,
          };
        }),
        // Stubbing out the rest of the play object
        id: 0,
        userId: windowValues.accountId || '',
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        gameId: game.id,
        deletedAt: null,
        completedAt: new Date().toISOString(),
        points: 0, // Will be updated in step 2
      };

      // Step 2: Sum up the points and add/update the key
      optimisticPlay.points = game.definition.defaultPointsForPlaying || 0;

      return optimisticPlay as Play;
    },
    [windowValues.accountId],
  );

  const submitPlay = useCallback(
    async (playRecord: PlayRequest) => {
      setSubmitting(true);
      try {
        // Because the server takes time to respond, we want to show the results of the game while the server is processing the play record.
        const optimisticPlay = optimisticGradePlay({ play, game });
        setTimeout(() => {
          setGameResults(optimisticPlay);
        }, GAME_LOCK_TIME * 1000);

        const postPlayResponse = await postPlay(windowValues)({ play: playRecord });
        queryClient.invalidateQueries({
          queryKey: ['GameCenterData-Leagues'],
          refetchType: 'all',
        });
        if (postPlayResponse) {
          setSubmitting(false);
          // Now that the submission is done, refresh the data to update the challenge cards.
          await queryClient.refetchQueries(['ChallengeData']);
          await queryClient.refetchQueries(['GameCenterData-Challenges']);
        } else {
          // If there is no resposne, something probably went wrong, show error modal.
          setSubmitError(
            'Something went wrong when submitting your play record. Please refresh and try again',
          );
          setSubmitting(false);
          // TODO: Add error modal.
        }
      } catch (error) {
        // Something went horribly wrong, show error modal.
        setSubmitError(
          'Something went wrong when submitting your play record. Please refresh and try again',
        );
        setSubmitting(false);
      }
    },
    [game, optimisticGradePlay, play, windowValues],
  );

  const isSelected = useCallback(
    (questionId: string | undefined, answerId: string): PickedEm => {
      const entry = play.entries.find((record) => record.questionId === questionId);
      if (!questionId || !entry?.answerId) {
        return {
          borderColor: Colors.white50,
          brightness: 1,
          selected: false,
        };
      }
      if (entry?.answerId === answerId) {
        return {
          borderColor: Colors.white,
          brightness: 1.25,
          selected: true,
        };
      } else {
        return {
          borderColor: Colors.white50,
          brightness: 0.9,
          selected: false,
        };
      }
    },
    [play],
  );

  const allQuestionsAnswered = useMemo(() => {
    // If build play record has the same number of entries as the total number of questions, and every entry has an answerId, then all questions are answered.
    return play.entries.length === maxQuestions && play.entries.every((entry) => entry.answerId);
  }, [maxQuestions, play.entries]);

  const disableSubmitButton = useMemo(() => {
    if (!allQuestionsAnswered || gameLock === true) {
      return true;
    }
    return false;
  }, [allQuestionsAnswered, gameLock]);

  const [swipeOffset, setSwipeOffset] = useState(-650);
  const [swipeType, setSwipeType] = useState<'pull' | 'tap'>('tap');
  const [swipeDirection, setSwipeDirection] = useState<'forward' | 'backward'>('forward');

  const onClickPaginationDot = useCallback(
    (index: number) => {
      setCurrentQuestionIndex((previousQuestionIndex) => {
        if (index > previousQuestionIndex) {
          setSwipeType('tap');
          setSwipeDirection('forward');
        } else {
          setSwipeType('tap');
          setSwipeDirection('backward');
        }
        return index;
      });
    },
    [setCurrentQuestionIndex],
  );

  const resetSwipeUI = useCallback(() => {
    setSwipeOffset(-650);
    setSwipeType('tap');
  }, []);

  const onSwipeRight = useCallback(() => {
    if (currentQuestionIndex === 0) {
      setSwipeOffset(0);
      return;
    }
    setSwipeDirection('backward');
    resetSwipeUI();
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  }, [currentQuestionIndex, resetSwipeUI]);

  const onSwipeLeft = useCallback(() => {
    if (currentQuestionIndex === maxQuestionIndex) {
      setSwipeOffset(0);
      return;
    }
    setSwipeDirection('forward');
    resetSwipeUI();
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  }, [currentQuestionIndex, maxQuestionIndex, resetSwipeUI]);

  const [panResponder, setPanResponder] = useState<PanResponderInstance>(
    PanResponder.create({
      onStartShouldSetPanResponder: (_evt, _gestureState) => {
        return true;
      },
      onPanResponderMove: (_event, _gestureState) => {},
    }),
  );

  useEffect(() => {
    setPanResponder(
      PanResponder.create({
        onStartShouldSetPanResponder: (_evt, _gestureState) => {
          // TODO: Use this to bypass button eating input
          return true;
        },
        onPanResponderMove: (_event, gestureState) => {
          const { dx: direction } = gestureState;
          setSwipeType('pull');
          setSwipeOffset(direction); // Continue tracking the swipe offset
        },
        onPanResponderRelease: (_event, gestureState) => {
          const { dx: direction, vx: velocity } = gestureState;
          const velocityToSwipe = 0.7;
          const threshold = 80;

          if (Math.abs(direction) > threshold || Math.abs(velocity) > velocityToSwipe) {
            if (direction > 0) {
              onSwipeRight();
            } else {
              onSwipeLeft();
            }
          }
        },
      }),
    );
  }, [currentQuestionIndex, onSwipeLeft, onSwipeRight, resetSwipeUI]);

  if (gameResults) {
    return (
      <PickEmGameResultModal
        game={game}
        gameResults={gameResults}
        onCloseModal={closeAndClear}
        challenge={challenge}
      />
    );
  }

  return (
    <View
      {...panResponder.panHandlers}
      style={{
        alignItems: 'center',
        width: '100%',
        maxWidth: 475,
        backgroundColor: 'transparent',
        alignSelf: 'center',
      }}
    >
      <View style={{ width: '100%' }}>
        <View style={[styles.container, { backgroundColor: '#1d2141', borderRadius: 4 }]}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
              padding: 16,
              gap: 8,
              overflow: 'hidden',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              >
                <View
                  style={{
                    borderBottomColor: colorPalette.neutral['200'],
                    borderBottomWidth: 0.5,
                  }}
                >
                  <DText color={colorPalette.neutral['200']} fontSize={Fonts.font12}>
                    {`Question ${currentQuestionIndex + 1}/${maxQuestions}`}
                  </DText>
                </View>
              </View>
            </View>
            <SlideInView
              offset={swipeOffset}
              type={swipeType}
              direction={swipeDirection}
              question={currentQuestion.questionId}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 32,
                width: '100%',
              }}
            >
              <View
                style={{
                  display: blurCurrentQuestion() ? 'flex' : 'none',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'rgba(0,0,0,0.7)',
                  zIndex: 10000,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  borderRadius: 4,
                  justifyContent: 'center',
                }}
              >
                <DText fontSize={Fonts.font26} fontWeight={900} center color={'white'}>
                  THE ENTRY PERIOD FOR THIS MATCH UP HAS EXPIRED
                </DText>
              </View>
              <DText
                center={true}
                color={'white'}
                fontWeight={900}
                fontSize={Fonts.font18}
                style={{ minHeight: 62 }}
              >
                {currentQuestion?.questionText}
              </DText>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: 16,
                }}
              >
                {answerOptions?.map((answerOption, index) => {
                  return (
                    <BiggerTouchableOpacity
                      key={index}
                      disabled={gameLock || submitting}
                      onPress={() =>
                        addEntry({
                          questionId: currentQuestion?.questionId || '',
                          answerId: index.toString(),
                        })
                      }
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 60,
                        borderRadius: 4,
                        paddingHorizontal: 16,
                        backgroundColor: Colors.cardColor,
                        position: 'relative',
                        flexShrink: 1,
                        display: 'flex',
                        filter: `brightness(${
                          isSelected(currentQuestion?.questionId, index.toString()).brightness
                        })`,
                      }}
                    >
                      <View
                        style={{
                          width: 20,
                          height: 20,
                          borderWidth: 2,
                          borderRadius: 9999,
                          borderColor: isSelected(currentQuestion?.questionId, index.toString())
                            .borderColor,
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          padding: 3,
                        }}
                      >
                        {isSelected(currentQuestion?.questionId, index.toString()).selected ? (
                          <View
                            style={{
                              borderRadius: 9999,
                              width: '100%',
                              height: '100%',
                              backgroundColor: isSelected(
                                currentQuestion?.questionId,
                                index.toString(),
                              ).borderColor,
                            }}
                          />
                        ) : null}
                      </View>
                      <DText
                        center={true}
                        style={{ zIndex: 10000, marginLeft: 16, marginRight: 16 }}
                        color={'white'}
                        fontSize={Fonts.font16}
                        fontWeight={900}
                      >
                        {answerOption}
                      </DText>
                    </BiggerTouchableOpacity>
                  );
                })}
              </View>
            </SlideInView>
            <View style={{ width: '100%' }}>
              <PaginationDots
                setCurrentQuestionIndex={onClickPaginationDot}
                maxQuestions={maxQuestions}
                currentQuestionIndex={currentQuestionIndex}
              />
            </View>
            <View style={{ width: '100%' }}>
              <BiggerTouchableOpacity
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 4,
                  backgroundColor: disableSubmitButton
                    ? Colors.cardColor
                    : colorPalette.cobalt['400'],
                  paddingVertical: 12,
                  paddingHorizontal: 12,
                }}
                disabled={disableSubmitButton}
                onPress={() => {
                  submitPlay(play);
                }}
              >
                <DText color={Colors.white} fontSize={Fonts.font14}>
                  {`Make Your Picks • Closes @ ${new Date(game.endDate).toLocaleDateString([], {
                    month: '2-digit',
                    day: '2-digit',
                  })} ${new Date(game.endDate).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}`}
                </DText>
              </BiggerTouchableOpacity>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginVertical: 4,
              }}
            >
              <DText
                style={{
                  textAlign: 'center',
                }}
                color={Colors.red}
                fontSize={Fonts.font14}
              >
                {submitError}
              </DText>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

function PickEmGameResultModal({
  game,
  gameResults,
  onCloseModal,
  challenge,
}: {
  game: GameWithoutPlays;
  gameResults: Play;
  onCloseModal: () => void;
  challenge?: Challenge;
}) {
  const totalPointsReceived = gameResults.points;
  const totalPossiblePoints = game.definition.questions.reduce((acc, question) => {
    if (question.questionEndDate && new Date(question.questionEndDate) < new Date()) {
      return 0;
    }
    return acc + question.points;
  }, 0);

  const { setChallenge, accountId } = useContext(WindowContext);
  const backToGameCenter = useCallback(() => {
    return setChallenge(undefined);
  }, [setChallenge]);

  const activeGameToBePlayed = challenge?.games?.find(
    ({ id, active, plays }) =>
      game.id !== id && active && !plays.some((play) => play.userId === accountId),
  );
  return (
    <Modal
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,.2)',
        alignSelf: 'center',
      }}
      animationType={'fade'}
      transparent={true}
      onRequestClose={onCloseModal}
    >
      <View
        style={{
          alignItems: 'center',
          width: '100%',
          height: '100%',
          maxWidth: 475,
          paddingTop: 90,
          backgroundColor: 'rgba(0, 21, 38, 0.8)',
          alignSelf: 'center',
        }}
      >
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            maxWidth: 475,
            backgroundColor: 'transparent',
          }}
        >
          <View style={{ width: '100%', paddingHorizontal: 16 }}>
            <View
              style={{
                backgroundColor: '#1d2141',
                borderRadius: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                padding: 20,
                paddingTop: 36,
              }}
            >
              <View
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <DText center={true} color={'white'} fontSize={Fonts.font18} fontWeight={800}>
                  {`Check back after the events\nto see how you did.`}
                </DText>
                <DText center={true} color={Colors.lightWhite} fontSize={Fonts.font14}>
                  {`You earned +${totalPointsReceived} entries just for playing.`}
                </DText>
              </View>
              <View
                style={{
                  borderRadius: 4,
                  width: '100%',
                  backgroundColor: 'rgba(255,255,255,.1)',
                  marginBottom: 8,
                  padding: 14,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              >
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    marginBottom: 8,
                  }}
                >
                  <DText center={true} color={'white'} fontSize={Fonts.font14} fontWeight={800}>
                    {game.name}
                  </DText>
                </View>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  style={{ display: 'flex' }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 8,
                      padding: 8,
                    }}
                  >
                    {gameResults.entries
                      .sort((entry1, entry2) => {
                        return parseInt(entry1.questionId) - parseInt(entry2.questionId);
                      })
                      .map((play, index) => {
                        const number = index + 1;
                        return <PickEmEntryResult key={index} number={number} result={play} />;
                      })}
                  </View>
                </ScrollView>
                <View
                  style={{
                    width: '100%',
                    borderColor: '#474b5e',
                    borderWidth: 1,
                    height: 1.5,
                    marginVertical: 8,
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <DText fontSize={Fonts.font16} color={'white'}>
                      Drawing Entries Earned
                    </DText>
                    <DText fontSize={Fonts.font16} fontWeight={900} color={Colors.white}>
                      {'+' + totalPointsReceived}
                    </DText>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <DText fontSize={Fonts.font16} color={'white'}>
                      Bonus Entries for Correct Picks
                    </DText>
                    <DText fontSize={Fonts.font16} color={Colors.white50}>
                      {'Up to +' + totalPossiblePoints}
                    </DText>
                  </View>
                </View>
                <View
                  style={{
                    width: '100%',
                    borderColor: '#474b5e',
                    borderWidth: 1,
                    height: 1.5,
                    marginVertical: 8,
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <DText fontSize={Fonts.font14} color={'white'}>
                    Total Entries Earned
                  </DText>
                  <PendingPill />
                </View>
              </View>
              <View style={{ width: '100%' }}>
                <BiggerTouchableOpacity
                  onPress={() => {
                    onCloseModal();
                    if (!activeGameToBePlayed) {
                      backToGameCenter();
                    }
                  }}
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 4,
                    backgroundColor: colorPalette.cobalt['400'],
                    paddingTop: 6,
                    paddingBottom: 6,
                    height: 36,
                  }}
                >
                  <DText color={colorPalette.white['100']} fontSize={Fonts.font16}>
                    {activeGameToBePlayed ? 'Play Next Game' : 'Back to Game Center'}
                  </DText>
                </BiggerTouchableOpacity>
                {challenge?.definition.featuredLeagueId && (
                  <View style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                    <DText
                      color={colorPalette.neutral[200]}
                      fontSize={18}
                      fontWeight={800}
                      style={{
                        marginTop: 12,
                      }}
                    >
                      Leagues
                    </DText>
                    <BiggerTouchableOpacity
                      onPress={() => {
                        window.location.replace(
                          window.location.href.split('?')[0] +
                            `?leagueId=${challenge?.definition.featuredLeagueId}`,
                        );
                      }}
                      style={{
                        width: '100%',
                        minHeight: 77,
                        paddingLeft: 16,
                        paddingRight: 24,
                        paddingTop: 16,
                        paddingBottom: 16,
                        marginBottom: 16,
                        borderRadius: 8,
                        backgroundColor: 'rgba(255,255,255,.1)',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <View
                          style={{
                            width: 46,
                            height: 46,
                            borderRadius: 23,
                            backgroundColor: 'rgba(255,255,255,.1)',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <DText fontSize={24} color="white" fontWeight={900}>
                            {getChallengeIcon(challenge?.type)}
                          </DText>
                        </View>
                        <Margin width={12} />
                        <View
                          style={{
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                          }}
                        >
                          <DText
                            color="white"
                            fontSize={Fonts.font15}
                            lineHeight={'150%'}
                            fontWeight={900}
                          >
                            {challenge?.name}
                          </DText>
                          <DText color="white" fontSize={Fonts.font13} lineHeight={'150%'}>
                            {'See your spot in our public league!'}
                          </DText>
                        </View>
                        <Margin width={12} />
                      </View>
                    </BiggerTouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          </View>
          <BiggerTouchableOpacity
            onPress={onCloseModal}
            style={{
              position: 'absolute',
              top: 12,
              right: 32,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Close height={18} width={18} tintColor={'white'}></Close>
          </BiggerTouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

function PickEmEntryResult({
  number,
  result,
}: {
  number: number;
  result: Play['entries'][number];
}) {
  const color =
    result.correct === undefined
      ? Colors.borderBlue
      : result.correct
      ? Colors.gameCenterQuestionRight
      : Colors.gameCenterQuestionWrong;
  const size = 16;
  const resultIcon = (
    <View
      style={
        {
          justifyContent: 'center',
          alignItems: 'center',
          width: size,
          height: size,
          borderRadius: 8,
          borderColor: Colors.borderBlue,
          borderWidth: 1,
          backgroundColor: '#1d2241',
          boxShadow: '0px 2px 4px 0px ' + Colors.borderBlue,
        } as any
      }
    >
      {result.correct === undefined ? (
        <Pending width={10} height={8} tintColor={color} />
      ) : result.correct ? (
        <Check width={size} height={size} tintColor={color} />
      ) : (
        <Close width={size} height={size} tintColor={color} />
      )}
    </View>
  );

  return (
    <View
      style={{
        backgroundColor: Colors.betchaBlack,
        width: 36,
        height: 36,
        borderRadius: 18,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: 36,
          height: 36,
          backgroundColor: 'transparent',
          borderRadius: 18,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <View style={{ position: 'absolute', left: -8, top: -4 }}>{resultIcon}</View>
        <DText
          style={{ position: 'absolute', top: 8, left: 8 }}
          fontSize={Fonts.font12}
          fontWeight={900}
          color="white"
        >
          #
        </DText>
        <DText fontSize={Fonts.font20} fontWeight={900} color="white">
          {number}
        </DText>
      </View>
    </View>
  );
}

function PendingPill() {
  const width = 80;
  const height = 30;
  const styleObj: any = {
    position: 'absolute',
    top: 0,
    left: 0,
    width,
    height,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 8px',
    borderRadius: 15,
    borderColor: Colors.borderBlue,
    borderWidth: 1,
    WebkitFilter: 'blur(2px)',
    filter: 'blur(2px)',
  };
  return (
    <View style={{ width, height }}>
      <View style={styleObj}></View>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width,
          height,
          display: 'flex',
          gap: 4,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 8,
          paddingBottom: 12,
          borderRadius: 15,
          borderColor: Colors.borderBlue,
          borderWidth: 1,
        }}
      >
        <Pending height={12} width={9.5} tintColor={Colors.borderBlue}></Pending>
        <DText fontSize={Fonts.font14} fontWeight={600} color={Colors.borderBlue}>
          Pending
        </DText>
      </View>
    </View>
  );
}

function PaginationDots({
  maxQuestions,
  currentQuestionIndex,
  setCurrentQuestionIndex,
}: {
  maxQuestions: number;
  currentQuestionIndex: number;
  setCurrentQuestionIndex: (index: number) => void;
}) {
  return (
    <View
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 12,
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      {Array.from({ length: maxQuestions }).map((_, index) => {
        const isSelected = currentQuestionIndex === index;
        const size = isSelected ? 8 : 4;
        const color = isSelected ? Colors.white : Colors.white50;
        return (
          <BiggerTouchableOpacity
            onPress={() => setCurrentQuestionIndex(index)}
            key={index}
            style={{
              borderRadius: 9999,
              width: size,
              height: size,
              borderColor: color,
              backgroundColor: color,
            }}
          />
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 475,
  },
});
