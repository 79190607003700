import { Pressable } from 'react-native';
import { WindowContext, isNotProd } from '../App';
import DText from '../components/DText';
import { useContext } from 'react';

export function DEVBanner({ shortHeader }: { shortHeader?: boolean }) {
  const { accountId, hideDevBanner, toggleHideDevBanner } = useContext(WindowContext);

  if (!isNotProd) {
    return null;
  }

  if (hideDevBanner) {
    return null;
  }

  return (
    <Pressable
      onPress={() => toggleHideDevBanner()}
      style={{
        position: 'absolute',
        top: shortHeader ? -82 : -202,
        left: 8,
        backgroundColor: 'red',
        borderRadius: 8,
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        zIndex: 99999,
      }}
    >
      <DText fontWeight={600} color="white">
        DEV BUILD
      </DText>
      <DText fontWeight={600} color="white">
        Account ID: {accountId}
      </DText>
    </Pressable>
  );
}
