import { GameCenterContainer } from '../../components/lite/GameCenterContainer';
import { LoadingPage } from '../../components/LoadingPage';
import { ErrorPage } from '../../components/ErrorPage';
import { useQuery } from '@tanstack/react-query';
import { getLiteGames } from '../../api';
import { useContext, useReducer, useState } from 'react';
import { Challenge } from '../../interfaces';
import { WindowContext } from '../../App';
import { SplashScreen } from '../../components/lite/GameCenterLiteSplash';
import { TriviaGameModal } from '../../components/lite/NewTriviaGame';
import { LegalDisclaimer } from '../../components/lite/LegalDisclaimer';
import DText from '../../components/DText';
import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import Margin from '../../components/Margin';

export default function GameCenterChallenge() {
  const windowValues = useContext(WindowContext);

  const {
    isLoading: liteChallengeLoading,
    isError: isLiteChallengeError,
    error,
    data: liteChallenge,
  } = useQuery<Challenge, Error, Challenge, (string | number | undefined)[]>({
    queryKey: ['LiteChallengeData'],
    queryFn: getLiteGames(windowValues),
  });

  const [showSplashScreen, setShowSplashScreen] = useReducer((state) => !state, true);
  const [hasResults, setHasResults] = useState(false);

  scrollTo({ top: 0, behavior: 'instant' });
  if (liteChallengeLoading) {
    return <LoadingPage />;
  }

  if (isLiteChallengeError) {
    return <ErrorPage error={error?.message || 'Unknown error'} />;
  }

  const game = liteChallenge?.games
    ?.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
    .find((game) => game.active);

  const playGame = game && !showSplashScreen;

  return (
    <>
      <SplashScreen showSplash={showSplashScreen} toggleGame={setShowSplashScreen} />
      <GameCenterContainer>
        {playGame && <TriviaGameModal game={game} setHasResults={setHasResults} />}
        {hasResults && <LegalDisclaimer onResultsScreen={hasResults} />}
        {!playGame && !hasResults && !showSplashScreen && (
          <>
            <Margin height={windowValues.isPhone ? 100 : 180} />
            <DText color={Colors.white} fontSize={Fonts.font48} center>
              We’re sorry. <br /> There are no games available at this time. <br /> Please check
              back later
            </DText>
          </>
        )}
      </GameCenterContainer>
    </>
  );
}
