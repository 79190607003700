import { View } from 'react-native';
import Margin from './Margin';
import DText from './DText';
import Fonts from '../constants/Fonts';
import { colorPalette } from '../themes/variables';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';

export function BrowseTicketsCard() {
  return (
    <View>
      <View
        style={{
          borderRadius: 8,
          overflow: 'hidden',
          padding: 16,
          width: '100%',
          backgroundColor: 'rgba(255,255,255,.1)',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          marginVertical: 4,
        }}
      >
        <DText
          style={{ lineHeight: '125%' }}
          color={'white'}
          fontWeight={900}
          fontSize={Fonts.font20}
        >
          {'Done Playing Today?'}
        </DText>
        <DText
          style={{ lineHeight: '150%' }}
          color={colorPalette.neutral['200']}
          fontWeight={Fonts.font16}
          fontSize={Fonts.font16}
        >
          {
            'Come back tomorrow to play more games and in the meantime grab tickets to your next live event.'
          }
        </DText>
        <Margin height={8} />
        <BiggerTouchableOpacity
          onPress={() => {
            location.href =
              'vividseats://home?utm_term=browse_tickets_footer&utm_campaign=game_center';
          }}
          style={{
            borderWidth: 1,
            borderColor: colorPalette.indigo['350'],
            width: '100%',
            height: 36,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 4,
            backgroundColor: 'transparent',
            paddingTop: 6,
            paddingBottom: 6,
            paddingRight: 12,
            paddingLeft: 12,
          }}
        >
          <DText color={colorPalette.indigo['350']} fontWeight={600} fontSize={Fonts.font16}>
            {'Browse Tickets'}
          </DText>
        </BiggerTouchableOpacity>
      </View>
    </View>
  );
}
