import { Image, View } from 'react-native';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import DText from './DText';
import Fonts from '../constants/Fonts';
import { colorPalette } from '../themes/variables';
import { PrizeContainer } from './PrizeContainer';
import Colors from '../constants/Colors';
import { LeftArrow } from './images';
import { Challenge, Game, GameTypes } from '../interfaces';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TriviaGameModal } from './NewTriviaGame';
import { PickEmGameModal } from './NewPickEmGame';
import { CuratedPollModal } from './NewCuratedPollGame';
import { WindowContext } from '../App';
import { getGameType, firstGameActiveForChallenge } from '../Utils';
import { User } from './images';

export const pickEmChallengeTypes = ['NBA', 'NFL', 'MLB', 'NHL', 'MLS', 'NCAA'];

const FeaturedContestUX = ({
  firstGameActive,
  completedPlayableGameForFeaturedChallenge,
}: {
  firstGameActive: boolean;
  completedPlayableGameForFeaturedChallenge?: boolean;
}) => {
  let message = '';
  if (!completedPlayableGameForFeaturedChallenge && firstGameActive) {
    message = 'Play to join our League!';
  } else if (completedPlayableGameForFeaturedChallenge && firstGameActive) {
    message = 'You are entered in this league';
  } else if (!firstGameActive) {
    // challenge is a featured Featured Contest
    message = 'playing will also join you in our League';
  }
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 16,
        gap: 12,
        alignItems: 'center',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: -12,
        }}
      >
        <User
          style={{
            borderRadius: 15,
            border: '1px solid white',
            zIndex: 5000,
            marginRight: -8,
          }}
          width={24}
          height={24}
        />
        <User
          style={{
            borderRadius: 15,
            border: '1px solid white',
            zIndex: 100,
            marginRight: -8,
          }}
          width={24}
          height={24}
        />
        <User
          style={{
            borderRadius: 15,
            border: '1px solid white',
            zIndex: 0,
            marginRight: -8,
          }}
          width={24}
          height={24}
        />
      </View>
      <View
        style={
          {
            background: 'rgba(255,255,255,.1)',
            borderRadius: 16,
            padding: 6,
            paddingRight: 8,
            paddingLeft: 24,
            marginLeft: -24,
            zIndex: -1,
          } as any
        }
      >
        <DText color={colorPalette.neutral['200']} fontWeight={600} fontSize={Fonts.font14}>
          {message}
        </DText>
      </View>
    </View>
  );
};

export function ChallengeCard({
  challenge,
  withBackButton = false,
  onChallengePage = false,
}: {
  challenge: Challenge;
  withBackButton?: boolean;
  onChallengePage?: boolean;
}) {
  const windowValues = useContext(WindowContext);
  const navigate = (url: string) => {
    location.href = url;
  };

  const score = challenge.games
    ?.map((game) => {
      const points = game.plays.reduce((accumulator, play) => {
        return accumulator + play.points;
      }, 0);

      return points;
    })
    .reduce((accumulator, points) => {
      return accumulator + points;
    }, 0);

  const allGames =
    challenge.games &&
    challenge.games.sort((a, b) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

  // The playable games are those who are active
  const playableGames = allGames?.filter((game) => {
    return game.active === true;
  });

  // Return the first game that has no plays
  const playableGame = playableGames?.find((game) => {
    return game.plays?.length === 0;
  });

  // What position that first playable game is in the list of active (playable) games
  const indexOfPlayableGame = playableGames?.indexOf(playableGame || ({} as Game));

  const hasMoreThanOnePlayableGame = (playableGames || [])?.length > 1;
  const nextGameToPlay = hasMoreThanOnePlayableGame
    ? playableGames?.[(indexOfPlayableGame || 0) + 1]
    : undefined;

  // Check if they've played any of the games for the featured challenge
  const completedPlayableGameForFeaturedChallenge = challenge.games
    ? challenge.games.some((game) => game.plays.length >= 1)
    : false;

  const completedPlayableGameForChallenge = playableGame ? playableGame.plays.length >= 1 : false;

  const hasFutureGames = allGames?.some((game) => {
    return new Date(game.startDate).getTime() > new Date().getTime();
  });
  const upcomingFutureGame = allGames?.find((game) => {
    return new Date(game.startDate).getTime() > new Date().getTime();
  });

  const firstGameActive = firstGameActiveForChallenge(allGames);

  /* // For Debugging Game Statuses in the future
  console.log('Current Game To Play', playableGame?.name);
  console.log('Next Game To Play', nextGameToPlay?.name);
  console.log('Has More Playable Games', hasMoreThanOnePlayableGame);
  console.log('Completed Current Game', completedPlayableGameForChallenge);
  console.log('Has Future Games', hasFutureGames);
  console.log('Upcoming Future Game', upcomingFutureGame?.name);
  */

  const [selectedGame, setSelectdGame] = useState<Game | undefined>(undefined);

  const onPressButton = useCallback(
    (
      action:
        | 'open_challenge_view'
        | 'close_challenge_view'
        | 'play_current_game'
        | 'play_next_game',
    ) =>
      () => {
        if (action === 'open_challenge_view') {
          return windowValues.setChallenge(challenge);
        }
        if (action === 'close_challenge_view') {
          // Remove challengeId from URL if its set
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.get('challengeId')) {
            window.location.replace(window.location.href.split('?')[0]);
          }
          return windowValues.setChallenge(undefined);
        }
        if (action === 'play_current_game') {
          if (!onChallengePage) {
            return windowValues.setChallenge(challenge);
          }
          return setSelectdGame(playableGame);
        }
        if (action === 'play_next_game') {
          return setSelectdGame(nextGameToPlay);
        }

        windowValues.setChallenge(challenge);
      },
    [challenge, playableGame, nextGameToPlay, onChallengePage, windowValues],
  );

  const closeModal = useCallback(() => {
    setSelectdGame(undefined);
  }, []);

  const gameType = useMemo<GameTypes | undefined>(
    () => getGameType({ challenge, game: selectedGame }),
    [challenge, selectedGame],
  );

  const GameComponent = useCallback(() => {
    if (selectedGame) {
      if (!gameType) {
        return null;
      }
      if (gameType === 'trivia') {
        return (
          <TriviaGameModal onCloseModal={closeModal} game={selectedGame} challenge={challenge} />
        );
      }
      if (gameType === 'curated_poll') {
        return (
          <CuratedPollModal onCloseModal={closeModal} game={selectedGame} challenge={challenge} />
        );
      }
      if (gameType === 'pickem') {
        return (
          <PickEmGameModal onCloseModal={closeModal} game={selectedGame} challenge={challenge} />
        );
      }
    } else {
      return null;
    }
    // TODO figure out why the challenge is being reset
  }, [selectedGame, gameType, closeModal]);

  const backButton = useMemo(() => {
    if (onChallengePage) {
      if (completedPlayableGameForChallenge || !playableGame || !challenge.active) {
        return (
          <BiggerTouchableOpacity
            onPress={() => {
              navigate('vividseats://home?utm_term=browse_tickets_footer&utm_campaign=game_center');
            }}
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: colorPalette.indigo['350'],
              borderWidth: 1,
              borderRadius: 4,
              backgroundColor: 'transparent',
              paddingVertical: 12,
              paddingHorizontal: 12,
              flexDirection: 'row',
            }}
          >
            <DText color={colorPalette.indigo['350']} fontWeight={600} fontSize={Fonts.font16}>
              {' Browse Tickets'}
            </DText>
          </BiggerTouchableOpacity>
        );
      }
    }
    return (
      <BiggerTouchableOpacity
        onPress={onPressButton('close_challenge_view')}
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          borderColor: Colors.borderBlue,
          borderWidth: 1,
          borderRadius: 4,
          backgroundColor: 'transparent',
          paddingVertical: 7,
          paddingHorizontal: 7,
          flexDirection: 'row',
        }}
      >
        <LeftArrow width={24} height={24} tintColor={Colors.borderBlue} />
        <DText color={Colors.borderBlue} fontWeight={600} fontSize={Fonts.font16}>
          {'Back to Game Center'}
        </DText>
      </BiggerTouchableOpacity>
    );
  }, [
    onChallengePage,
    onPressButton,
    completedPlayableGameForChallenge,
    playableGame,
    challenge.active,
  ]);

  const playButton = useMemo(() => {
    if (onChallengePage) {
      if (completedPlayableGameForChallenge || !playableGame) {
        return (
          <BiggerTouchableOpacity
            onPress={onPressButton('close_challenge_view')}
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
              backgroundColor: colorPalette.cobalt['400'],
              paddingVertical: 12,
              paddingHorizontal: 12,
            }}
          >
            <DText color={'white'} fontWeight={600} fontSize={Fonts.font16}>
              {'Back to Game Center'}
            </DText>
          </BiggerTouchableOpacity>
        );
      }
      if (completedPlayableGameForChallenge && nextGameToPlay) {
        return (
          <BiggerTouchableOpacity
            onPress={onPressButton('play_next_game')}
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
              backgroundColor: colorPalette.cobalt['400'],
              paddingVertical: 12,
              paddingHorizontal: 12,
            }}
          >
            <DText color={'white'} fontWeight={600} fontSize={Fonts.font16}>
              {'Play Next Game'}
            </DText>
          </BiggerTouchableOpacity>
        );
      }
    }
    if (!onChallengePage) {
      if (completedPlayableGameForChallenge || !playableGame) {
        let message = 'See Game History';
        if (challenge.definition.featuredLeagueAdminId && !firstGameActive) {
          message = 'Play Now';
        }
        return (
          <BiggerTouchableOpacity
            onPress={onPressButton('open_challenge_view')}
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
              backgroundColor: colorPalette.neutral['600'],
              paddingVertical: 12,
              paddingHorizontal: 12,
            }}
          >
            <DText color={colorPalette.neutral['200']} fontWeight={600} fontSize={Fonts.font16}>
              {message}
            </DText>
          </BiggerTouchableOpacity>
        );
      }
    }
    return (
      <>
        <BiggerTouchableOpacity
          onPress={onPressButton('play_current_game')}
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 4,
            backgroundColor: colorPalette.cobalt['400'],
            paddingVertical: 12,
            paddingHorizontal: 12,
          }}
        >
          <DText color={'white'} fontWeight={600} fontSize={Fonts.font16}>
            {'Play Now'}
          </DText>
        </BiggerTouchableOpacity>
      </>
    );
  }, [
    onChallengePage,
    onPressButton,
    completedPlayableGameForChallenge,
    playableGame,
    nextGameToPlay,
    challenge.definition.featuredLeagueAdminId,
    firstGameActive,
  ]);

  const maskGame = useMemo(() => {
    const pillTextStyle: any = {
      borderColor: Colors.borderBlue,
      borderRadius: 34,
      borderWidth: 1,
      paddingHorizontal: 11,
      boxShadow: '0 0 9px 2px ' + Colors.borderBlue,
      //paddingVertical: 4,
      backgroundColor: colorPalette.neutral['800'],
      justifyContent: 'center',
      alignItems: 'center',
    };
    if (!playableGame && hasFutureGames) {
      const upcomingFutureGameStartDate = new Date(upcomingFutureGame?.startDate || '');

      return (
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,.5)',
            zIndex: 10,
          }}
        >
          <View
            style={{
              position: 'absolute',
              top: 12,
              left: 12,
            }}
          >
            <View style={pillTextStyle}>
              <Countdown
                endTime={upcomingFutureGameStartDate}
                prefix={
                  challenge.definition.featuredLeagueAdminId && !firstGameActive
                    ? 'LAUNCH'
                    : 'NEXT GAME'
                }
              />
            </View>
          </View>
        </View>
      );
    }
    if ((!playableGame && !hasFutureGames) || !challenge.active) {
      return (
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,.5)',
            zIndex: 10,
          }}
        >
          <View
            style={{
              position: 'absolute',
              top: 12,
              left: 12,
            }}
          >
            <View style={pillTextStyle}>
              <DText color={'white'} fontWeight={600} fontSize={Fonts.font16}>
                {'GAME CLOSED'}
              </DText>
            </View>
          </View>
        </View>
      );
    }
    if (onChallengePage || challenge.definition.featuredLeagueAdminId) {
      return (
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0)',
            zIndex: 10,
          }}
        >
          <View
            style={{
              position: 'absolute',
              top: 12,
              left: 12,
            }}
          >
            <View style={pillTextStyle}>
              <DText color={'white'} fontWeight={600} fontSize={Fonts.font16}>
                {'GAME LIVE'}
              </DText>
            </View>
          </View>
        </View>
      );
    }
    return null;
  }, [
    playableGame,
    hasFutureGames,
    challenge.active,
    challenge.definition.featuredLeagueAdminId,
    onChallengePage,
    upcomingFutureGame?.startDate,
    firstGameActive,
  ]);

  return (
    <>
      {selectedGame ? (
        <GameComponent />
      ) : (
        <BiggerTouchableOpacity
          onPress={() => {
            windowValues.setChallenge(challenge);
          }}
          style={{
            borderRadius: 8,
            overflow: 'hidden',
            width: '100%',
            display: 'flex',
            flexShrink: 'unset',
          }}
        >
          <View style={{ position: 'relative', width: '100%', height: 200 }}>
            {maskGame}
            <Image
              source={{ uri: challenge.definition.image }}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </View>
          <View
            style={{
              padding: 8,
              width: '100%',
              height: 'auto',
              backgroundColor: 'rgba(255,255,255,.1)',
            }}
          >
            <DText
              style={{ lineHeight: '150%' }}
              fontSize={Fonts.font14}
              fontWeight={Fonts.Bold}
              color="white"
            >
              {challenge.name}
            </DText>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: '100%',
                flexShrink: 1,
              }}
            >
              <View>
                <DText
                  style={{ lineHeight: '150%' }}
                  fontSize={Fonts.font12}
                  color={colorPalette.neutral['200']}
                >
                  {"This Week's Prize"}
                </DText>
                <PrizeContainer
                  prizeText={challenge.definition.prizeDescription}
                  prizeSubText={challenge.definition.prizeDescriptionSubText}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <DText
                  style={{ lineHeight: '100%', textAlign: 'end' }}
                  fontSize={Fonts.font12}
                  color={colorPalette.neutral['200']}
                >
                  {"You've Earned"}
                </DText>
                <DText
                  style={{ lineHeight: '100%', textAlign: 'end' }}
                  fontSize={Fonts.font14}
                  fontWeight={900}
                  color={'#28aef0'}
                >
                  {score || '---'}
                </DText>
                <DText
                  fontWeight={900}
                  style={{ lineHeight: '100%', textAlign: 'end' }}
                  fontSize={Fonts.font10}
                  color={'#28aef0'}
                >
                  {'ENTRIES'}
                </DText>
              </View>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 16,
                gap: 12,
              }}
            >
              {playButton}
              {withBackButton && backButton}
            </View>
            {challenge.definition.featuredLeagueAdminId && (
              <FeaturedContestUX
                firstGameActive={firstGameActive}
                completedPlayableGameForFeaturedChallenge={
                  completedPlayableGameForFeaturedChallenge
                }
              />
            )}
          </View>
        </BiggerTouchableOpacity>
      )}
    </>
  );
}

interface CountDownProps {
  timerHitCallback?: () => void;
  endTime: Date;
  prefix: string;
}

const Countdown = (props: CountDownProps) => {
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(0);

  // The state for our timer
  const [timer, setTimer] = useState('00:00:00');

  const getTimeRemaining = (e: Date) => {
    const total = e.getTime() - new Date().getTime();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    const days = Math.floor((total / 1000 / 60 / 60 / 24) % 24);
    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = useCallback(
    (e: Date) => {
      const { total, days, hours, minutes, seconds } = getTimeRemaining(e);
      if (total >= 0) {
        // update the timer
        // check if less than 10 then we need to
        // add '0' at the beginning of the variable
        setTimer(
          (days > 0 ? (days > 9 ? days : '0' + days) + ':' : '') +
            (hours > 9 ? hours : '0' + hours) +
            ':' +
            (minutes > 9 ? minutes : '0' + minutes) +
            ':' +
            (seconds > 9 ? seconds : '0' + seconds),
        );
      } else {
        if (props.timerHitCallback) {
          props.timerHitCallback();
        }
      }
    },
    [props],
  );

  const clearTimer = useCallback(
    (e: Date) => {
      // If you try to remove this line the
      // updating of timer Variable will be
      // after 1000ms or 1sec
      if (Ref.current) {
        clearInterval(Ref.current);
      }
      const id = window.setInterval(() => {
        startTimer(e);
      }, 1000);
      Ref.current = id;
    },
    [startTimer],
  );

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(props.endTime);

    return () => {
      if (Ref.current) {
        clearInterval(Ref.current);
      }
    };
  }, [clearTimer, props.endTime]);

  return (
    <DText color={colorPalette.cobalt['100']} fontSize={Fonts.font16} fontWeight={900}>
      {props.prefix + ' ' + timer}
    </DText>
  );
};
