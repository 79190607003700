import { ActivityIndicator, View } from 'react-native';
import React, { useContext, useState } from 'react';
import Colors from '../constants/Colors';
import DText from './DText';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import Margin from './Margin';
import Fonts from '../constants/Fonts';
import CtaButton from './CtaButton';
import { Close } from './images';
import { closeLeagueV2 } from '../api/index';
import { WindowContext } from '../App';

export interface LeagueCloseLeagueProps {
  leagueName: string;
  leagueId?: string;
  width: number;
  leaderboardIcon: string;
  setSelectedLeague: () => void;
  refetchLeagues: () => Promise<any>;
  onCancel: () => void;
}

export const LeagueCloseLeague = (props: LeagueCloseLeagueProps) => {
  const windowValues = useContext(WindowContext);

  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const closeLeague = async () => {
    setSubmitting(true);
    try {
      await closeLeagueV2(windowValues)({ queryKey: ['leagueId', props.leagueId] });
      await props.refetchLeagues();
      setSubmitting(false);
      props.onCancel();
      props.setSelectedLeague();
    } catch (err) {
      setSubmitting(false);
      setErrorMessage((err as { message: string }).message.substr(0, 96));
    }
  };

  const buttonWidth = (props.width - 40) / 2;

  const renderErrorMessage = () => {
    if (!errorMessage) {
      return;
    }
    return (
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: props.width,
          minHeight: 64,
          padding: 8,
          borderRadius: 8,
          backgroundColor: Colors.white10, // colorPalette.neutral['700'], //rgba(255,255,255,.1)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Margin height={12} />
        <DText style={{ padding: 36 }} center={true} fontSize={Fonts.font18} color="white">
          There was an error trying to close the league:
        </DText>
        <DText center={true} fontSize={Fonts.font16} color="white">
          {'Error: ' + errorMessage}
        </DText>
        <Margin height={36} />
        <CtaButton
          outline={true}
          width={buttonWidth}
          active={true}
          text="Cancel"
          onPress={() => {
            setErrorMessage('');
          }}
        />
        <Margin height={16} />
      </View>
    );
  };

  return (
    <View
      key={'closeTheLeauge'}
      style={{
        width: props.width,
        minHeight: 64,
        padding: 8,
        borderRadius: 8,
        backgroundColor: Colors.white10, // colorPalette.neutral['700'], //rgba(255,255,255,.1)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Margin height={16} />
      <View
        style={{
          width: 48,
          height: 48,
          borderRadius: 24,
          backgroundColor: 'rgba(255,255,255,.1)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DText
          style={{ backgroundColor: 'transparent' }}
          fontSize={32}
          color="white"
          fontWeight={900}
        >
          {props.leaderboardIcon}
        </DText>
      </View>
      <Margin height={8} />
      <DText color="white" fontSize={Fonts.font16} lineHeight={'150%'} fontWeight={900}>
        Permanently Close League
      </DText>
      <Margin height={8} />
      <DText
        center={true}
        color="white"
        fontSize={Fonts.font20}
        lineHeight={'150%'}
        fontWeight={900}
      >
        {props.leagueName}
      </DText>
      <Margin height={8} />
      <DText center={true} color={'white'} fontSize={Fonts.font14} lineHeight={'150%'}>
        {
          'If you choose to permanently close this league,\nall other users will lose access to it.\n\nThis action can’t be undone.'
        }
      </DText>
      <Margin height={32} />
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: props.width - 32,
        }}
      >
        <CtaButton
          outline={true}
          width={buttonWidth}
          active={true}
          text="Cancel"
          onPress={props.onCancel}
        />
        <CtaButton
          onPress={closeLeague}
          backgroundColorActive="red"
          width={buttonWidth}
          active={true}
          text="Close League"
        />
      </View>
      <Margin height={16} />
      <BiggerTouchableOpacity
        disabled={submitting}
        onPress={props.onCancel}
        style={{
          position: 'absolute',
          top: 12,
          right: 8,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Close height={18} width={18} tintColor={'white'}></Close>
      </BiggerTouchableOpacity>
      {submitting && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: Colors.betchaBlack80Percent,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator animating={true} size={'large'} color={Colors.white} />
        </View>
      )}
      {renderErrorMessage()}
    </View>
  );
};
