import { Challenge, Game, GameTypes } from '../interfaces';
import { pickEmChallengeTypes } from '../components/ChallengeCard';
import { WindowValues } from '../api';

let _globalCopyCallback: any;
let _globalCopyText: string;
let _haveChallengeCallback: boolean;
let _leaderboardControllingCopy: boolean;

export default class Utils {
  static shortDateFormat(dateIn: Date) {
    let d;
    if (dateIn) {
      d = new Date(dateIn);
    } else {
      d = new Date();
    }
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  }

  static clearGlobalCopyText() {
    _globalCopyText = '';
  }

  static setHaveChallengeCallback(b: any) {
    _haveChallengeCallback = b;
  }

  static haveChallengeCallback() {
    if (_haveChallengeCallback) {
      return true;
    } else {
      return false;
    }
  }

  static getGlobalCopyText() {
    return _globalCopyText;
  }

  static copyTextToClipboard(text: string, allDone?: any, error?: any) {
    const userAgent = navigator.userAgent.toLowerCase();
    const fakeCopy =
      userAgent.toLowerCase().indexOf('android') > -1 ||
      userAgent.toLowerCase().indexOf('chrome') > -1;

    if ((window as any).__Platform && (window as any).__Platform === 'android') {
      const shareObject = { type: 'COPY_TO_CLIPBOARD', body: text };
      if (window.ReactNativeWebView)
        window.ReactNativeWebView.postMessage(JSON.stringify(shareObject));
      if (allDone) {
        allDone();
      }
      return;
    } else if (fakeCopy) {
      // android needs a prompt
      _globalCopyText = text;
      if (_globalCopyCallback) {
        _globalCopyCallback(text);
      }
      if (allDone) {
        allDone();
      }
      return;
    }
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (allDone) {
          allDone();
        }
      })
      .catch((e) => {
        if (e && error) {
          error(e);
        }
      });
  }

  static leaderBoardIsControllingCopy(showGlobalCopy: boolean) {
    _leaderboardControllingCopy;
    _leaderboardControllingCopy = showGlobalCopy;
  }
}

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const getChallengeIcon = (type = 'DEFAULT' as Challenge['type']) => {
  return (
    {
      MUSIC: '🎸',
      SPOTLIGHT: '🎤',
      'NFL 23-24': '🏈',
      'NBA 23-24': '🏀',
      'NCAA TOURNAMENT MENS': '🏀',
      'NCAA TOURNAMENT WOMENS': '🏀',
    }[type] || '💡'
  );
};

export const getGamesType = ({ challenge }: { challenge: Challenge }): GameTypes => {
  const gameHasNoAnswer = challenge.games?.some(
    (game) => game.definition.questions.filter((question) => !question.answerId).length > 0,
  );

  if (pickEmChallengeTypes.includes(challenge.type.split(' ')[0])) {
    return 'pickem';
  }
  // If the game isnt a sports game but one of the games has no answer, odds are its a curated poll and we'll know the answer when admin/SQS settles it
  if (gameHasNoAnswer) {
    return 'curated_poll';
  }
  return 'trivia';
};

export const getGameType = ({
  challenge,
  game,
}: {
  challenge: Challenge;
  game?: Game;
}): GameTypes | undefined => {
  if (!game) {
    return undefined;
  }
  const gameHasNoAnswer =
    game.definition.questions.filter((question) => !question.answerId).length > 0;

  if (pickEmChallengeTypes.includes(challenge.type.split(' ')[0]) && gameHasNoAnswer) {
    return 'pickem';
  }
  // If the game isnt a sports game but one of the games has no answer, odds are its a curated poll and we'll know the answer when admin/SQS settles it
  if (gameHasNoAnswer) {
    return 'curated_poll';
  }
  return 'trivia';
};

export const firstGameActiveForChallenge = (challengeGames: Game[] | undefined) => {
  let firstGameActive = false;
  challengeGames?.map((game) => {
    if (!firstGameActive) {
      // check if game is active now
      firstGameActive = game.active;
    }
    if (!firstGameActive) {
      // check if game was ever active
      firstGameActive = new Date(game.startDate).getTime() < new Date().getTime();
    }
  });
  // return true if at least one game is or was active
  return firstGameActive;
};

export const isAuthValid = (windowValues: WindowValues) => {
  // Its null when it starts up due to urlParams.get and localStorage.getItem returning null if the key does not exist.
  // So we can ignore the null value because its not technically invalid yet, its just not set.
  // If this returned false, the frontend app would redirect to the unauthorized ErrorPage and stop loading and the user would have to refresh the page.
  if (windowValues.accountId === null) {
    return true;
  }
  // We check if the value is not empty string which is its initial value, and the injectJavaScript from RN would only pass an empty string if there is not
  // proper auth header. So we just need to check if the value is not empty string and not a number.
  // Since accountId's are always numbers, we can just check for if its parseable as a number
  // If it isnt, we can assume its malformed or some invalid value.
  return windowValues.accountId === '' || Boolean(parseInt(windowValues.accountId));
};
