import { View } from 'react-native';
import { TokenCard } from './TokenCard';
import { RedeemCard } from '../components/lite/RedeemComponent';
import { PromotionCard } from './PromotionCard';
import { HistoryList } from './HistoryList';
export function GameCenterHistory() {
  return (
    <View>
      <TokenCard />
      <RedeemCard />
      <HistoryList />
      <PromotionCard />
    </View>
  );
}
