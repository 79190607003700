import { TextInput, View } from 'react-native';
import DText from '../DText';
import Fonts from '../../constants/Fonts';
import BiggerTouchableOpacity from '../BiggerTouchableOpacity';
import { useCallback, useContext, useState } from 'react';
import { WindowContext, queryClient } from '../../App';
import { putLitePlay } from '../../api';
import { Forward } from '../images';
import { RedeemTokensModal } from '../TokenCard';
import Colors from '../../constants/Colors';
import Margin from '../Margin';

export function RedeemCard() {
  const windowValues = useContext(WindowContext);

  const [promoCode, setPromoCode] = useState<string>('');
  const [pointsRedeemed, setPointsRedeemed] = useState<number>();
  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [error, setError] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamPromoCode = urlParams.get('promoCode');

  const setPointsError = () =>
    setError('Could not redeem points. Please re-enter code and try again.');

  const handlePromoCodeInput = (input: string) => setPromoCode(input.slice(0, 7));

  const clearPromoCodeFromUrl = () => {
    if (window.location.href.includes('?promoCode=')) {
      window.location.replace(window.location.href.split('?')[0] + '?promoCode=');
    }
  };

  const redeemPromoCode = useCallback(
    async (promoCode: string) => {
      try {
        const pointsRedeemed = await putLitePlay(windowValues)(promoCode);
        if (pointsRedeemed.points > 0) {
          setShowPrizeModal(true);
          setPointsRedeemed(pointsRedeemed.points);
          await queryClient.refetchQueries(['User']);
          setError('');
        } else {
          setPointsError();
        }
      } catch (error) {
        setPointsError();
      }
    },
    [windowValues],
  );

  if (urlParamPromoCode && !promoCode) {
    if (urlParamPromoCode.length === 0) {
      return;
    }
    if (urlParamPromoCode.length !== 7) {
      setError('Invalid promo code');
    }
    setPromoCode(urlParamPromoCode);
    redeemPromoCode(urlParamPromoCode);
  }

  return (
    <>
      {showPrizeModal && pointsRedeemed && (
        <RedeemTokensModal
          toggleVisible={() => {
            setShowPrizeModal(false);
            setPromoCode('');
            clearPromoCodeFromUrl();
          }}
          alternateTitle={`Success!\n You redeemed ${pointsRedeemed} tokens.`}
        />
      )}
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: error ? 24 : 8,
          width: '100%',
        }}
      >
        <DText color={'white'} fontSize={Fonts.font16} fontWeight={800}>
          HAVE A CODE TO REDEEM?
        </DText>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TextInput
            keyboardType="phone-pad"
            onChangeText={handlePromoCodeInput}
            value={promoCode}
            autoComplete="off"
            autoCorrect={false}
            style={{
              width: '100%',
              height: 48,
              backgroundColor: 'transparent',
              color: 'white',
              borderRadius: 4,
              borderWidth: 1,
              borderColor: error ? 'red' : 'white',
              borderStyle: 'solid',
              paddingHorizontal: 8,
              marginTop: 6,
              marginBottom: 2,
              fontSize: 12.5,
              justifyContent: 'center',
            }}
            placeholder="Enter the code you received to claim your tokens."
          />
          <BiggerTouchableOpacity
            style={{ position: 'absolute', right: 6 }}
            onPress={() => redeemPromoCode(promoCode)}
            disabled={promoCode.length !== 7}
          >
            <Margin height={3} />
            <Forward
              tintColor={promoCode.length === 7 ? Colors.white : Colors.grey400}
              width={25 - (promoCode.length === 7 ? 0 : 1)}
              height={26}
            />
          </BiggerTouchableOpacity>
        </View>
        <>
          {error && (
            <DText color="red" fontSize={11} style={{ position: 'absolute', bottom: -18 }}>
              {error}
            </DText>
          )}
        </>
      </View>
    </>
  );
}
