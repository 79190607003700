import { ActivityIndicator, View, Image } from 'react-native';
import React, { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { createLeagueV2, getChallengesV2, getLeaguesV2 } from '../api';
import Colors from '../constants/Colors';
import DText from './DText';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import Margin from './Margin';
import { colorPalette } from '../themes/variables';
import Fonts from '../constants/Fonts';
import CtaButton from './CtaButton';
import { Close } from './images';
import { Challenge, GameTypesToGameString, League } from '../interfaces';
import { LoadingPage } from './LoadingPage';
import { ErrorPage } from '../components/ErrorPage';
import { TextBox } from '../components/TextBox';
import { pickEmChallengeTypes } from './ChallengeCard';
import { capitalize, getChallengeIcon } from '../Utils';
import { WindowContext } from '../App';

interface LeagueCreateProps {
  width: number;
  onCancel: () => void;
  setSelectedLeague: (league: League) => void;
}

export const LeagueCreate = (props: LeagueCreateProps) => {
  const windowValues = useContext(WindowContext);
  const {
    isLoading: challengesLoading,
    isError: isChallengesError,
    error: challengesError,
    data: challenges,
  } = useQuery<Challenge[], Error, Challenge[], (string | undefined)[]>({
    queryKey: [
      'GameCenterData-Challenges',
      undefined,
      undefined,
      undefined,
      ['LITE', 'PROMO'].join(','),
    ],
    queryFn: getChallengesV2(windowValues),
  });

  const {
    isLoading: leaguesLoading,
    isError: isLeaguesError,
    data: leagues,
    error: leaguesError,
    refetch: refetchLeagues,
  } = useQuery<League[], Error>({
    queryKey: ['GameCenterData-Leagues'],
    queryFn: getLeaguesV2(windowValues),
  });

  const [selectedLeagueType, setSelectedLeagueType] = useState('');
  const [selectedGamesList, setSelectedGamesList] = useState<Challenge['games']>([]);
  const [leagueName, setLeagueName] = useState('');
  const [userName, setUserName] = useState('admin');
  const [submitting, setSubmitting] = useState(false);
  const [errorMessageForCreate, setErrorMessageForCreate] = useState('');
  const [showSelectLeagueType, setShowSelectLeagueType] = useState(true);

  if (challengesLoading || leaguesLoading) {
    return <LoadingPage />;
  }

  if (isChallengesError || isLeaguesError) {
    return <ErrorPage error={(challengesError || leaguesError)?.message || 'Unknown error'} />;
  }

  const createLeague = async () => {
    setSubmitting(true);
    try {
      const league = await createLeagueV2(windowValues)({
        name: leagueName,
        definition: {},
        type: selectedLeagueType,
        admin: { userId: windowValues.accountId, name: userName },
      });
      await refetchLeagues();
      setSubmitting(false);
      props.setSelectedLeague(league);
      props.onCancel();
    } catch (err) {
      setSubmitting(false);
      const leagueAlreadyExists = (err as { status: number }).status === 400;
      setErrorMessageForCreate(
        leagueAlreadyExists
          ? `You already have a ${leagueOptionFromType(
              selectedLeagueType,
            )} league called "${leagueName}."`
          : 'Could not create league.',
      );
    }
  };

  const confirmLeagueType = () => {
    let leagueNumber = 1;
    const getDefaultLeagueName = () => {
      const leagueName = leagueOptionFromType(selectedLeagueType) || 'league';
      return leagueName.toLowerCase() + '-' + leagueNumber;
    };
    while (leagues.some((league) => league.name === getDefaultLeagueName())) {
      leagueNumber++;
    }
    setLeagueName(getDefaultLeagueName());
    setShowSelectLeagueType(false);
  };

  const buttonWidth = (props.width - 40) / 2;

  const renderErrorMessageForCreate = (errorMessageForCreate: string) => (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: props.width,
        minHeight: 64,
        padding: 8,
        borderRadius: 8,
        backgroundColor: Colors.white10,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Margin height={12} />
      <DText style={{ padding: 36 }} center={true} fontSize={Fonts.font24} color="white">
        There was an error creating the league, please try later.
      </DText>
      <DText center={true} fontSize={Fonts.font12} color="white">
        {'Error: ' + errorMessageForCreate}
      </DText>
      <Margin height={36} />
      <CtaButton
        outline={true}
        width={buttonWidth}
        active={true}
        text="Try Again"
        onPress={() => setErrorMessageForCreate('')}
      />
      <Margin height={16} />
    </View>
  );

  const gameType = ({ type, games }: { type: Challenge['type']; games: Challenge['games'] }) => {
    const gameHasNoAnswer = games?.some(
      (game) => game.definition.questions.filter((question) => !question.answerId).length > 0,
    );
    if (pickEmChallengeTypes.includes(type.split(' ')[0])) {
      return 'pickem';
    }
    // If the game isnt a sports game but one of the games has no answer, odds are its a curated poll and we'll know the answer when admin/SQS settles it
    if (gameHasNoAnswer) {
      return 'curated_poll';
    }
    return 'trivia';
  };

  const leagueOptionFromType = (leagueType: Challenge['type'], games?: Challenge['games']) => {
    const type = leagueType.toUpperCase();

    if (leagueType === 'NCAA TOURNAMENT MENS') {
      return `${new Date().getFullYear()} NCAA Tournament Mens`;
    }
    if (leagueType === 'NCAA TOURNAMENT WOMENS') {
      return `${new Date().getFullYear()} NCAA Tournament Womens`;
    }

    const selectedType = gameType({ type, games: games || selectedGamesList });

    // If its a sports category from pickEmChallengeTypes we know its a pickem. We havent had Sports Trivia (yet?)
    if (selectedType === 'pickem') {
      return `${type} ${GameTypesToGameString[selectedType]}`;
    }

    if (selectedType === 'curated_poll') {
      return `${capitalize(type)} ${GameTypesToGameString[selectedType]}`;
    }
    // Everything else is probably trivia
    return `${capitalize(type)} ${GameTypesToGameString[selectedType]} ${new Date().getFullYear()}`;
  };

  const oneChallengePerType = (challenge: Challenge, index: number) =>
    challenges.findIndex(({ type }) => challenge.type.toUpperCase() === type.toUpperCase()) ===
    index;

  if (showSelectLeagueType) {
    return (
      <View
        key={'selectLeaderboard'}
        style={{
          width: props.width,
          minHeight: 64,
          padding: 8,
          borderRadius: 8,
          backgroundColor: Colors.white10, // colorPalette.neutral['700'], //rgba(255,255,255,.1)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Margin height={24} />
        <DText color="white" fontSize={Fonts.font20} lineHeight={'125%'} fontWeight={900}>
          Create a League
        </DText>
        <Margin height={4} />
        <DText
          center={true}
          color={colorPalette.neutral['200']}
          fontSize={Fonts.font14}
          lineHeight={'150%'}
        >
          {'Step 1: Select a game to play.'}
        </DText>
        <Margin height={12} />
        {challenges.filter(oneChallengePerType).map(({ id, type, definition, ...challenge }) => {
          const active = selectedLeagueType === type;
          return (
            <BiggerTouchableOpacity
              onPress={() => {
                setSelectedLeagueType(type);
                setSelectedGamesList(challenge.games);
              }}
              key={id}
              style={{
                width: props.width - 32,
                minHeight: 60,
                paddingLeft: 16,
                paddingRight: 24,
                paddingTop: 16,
                paddingBottom: 16,
                marginBottom: 16,
                borderRadius: 8,
                borderColor: active ? colorPalette.cobalt['400'] : 'transparent',
                borderWidth: 2,
                backgroundColor: active ? 'rgba(255,255,255,.2)' : 'rgba(255,255,255,.1)',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 20,
                    overflow: 'hidden',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    source={{ uri: definition.image }}
                    style={{
                      width: 45,
                      height: 45,
                    }}
                  />
                </View>
                <Margin width={16} />
                <DText
                  fontWeight={900}
                  center={true}
                  color={'white'}
                  fontSize={Fonts.font16}
                  lineHeight={'150%'}
                >
                  {leagueOptionFromType(type, challenge.games)}
                </DText>
              </View>
            </BiggerTouchableOpacity>
          );
        })}
        <Margin height={24} />
        <View
          style={{ justifyContent: 'space-between', flexDirection: 'row', width: props.width - 32 }}
        >
          <CtaButton
            outline={true}
            width={buttonWidth}
            active={true}
            text="Cancel"
            onPress={props.onCancel}
          />
          <CtaButton onPress={confirmLeagueType} active={!!selectedLeagueType} text="Next" />
        </View>
        <Margin height={16} />
        <BiggerTouchableOpacity
          disabled={submitting}
          onPress={props.onCancel}
          style={{
            position: 'absolute',
            top: 12,
            right: 8,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Close height={18} width={18} tintColor={'white'}></Close>
        </BiggerTouchableOpacity>
        {errorMessageForCreate && renderErrorMessageForCreate(errorMessageForCreate)}
      </View>
    );
  }

  return (
    <View
      key={'enterleagueBoard'}
      style={{
        width: props.width,
        minHeight: 64,
        padding: 8,
        borderRadius: 8,
        backgroundColor: Colors.white10, // colorPalette.neutral['700'], //rgba(255,255,255,.1)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Margin height={16} />
      <View
        style={{
          width: 48,
          height: 48,
          borderRadius: 24,
          backgroundColor: 'rgba(255,255,255,.1)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DText
          style={{ backgroundColor: 'transparent' }}
          fontSize={32}
          color="white"
          fontWeight={900}
        >
          {getChallengeIcon(selectedLeagueType)}
        </DText>
      </View>
      <Margin height={24} />
      <DText color="white" fontSize={Fonts.font20} lineHeight={'125%'} fontWeight={900}>
        Create a League?
      </DText>
      <Margin height={4} />
      <DText
        center={true}
        color={colorPalette.neutral['200']}
        fontSize={Fonts.font14}
        lineHeight={'150%'}
      >
        {challenges
          ? 'Step 2: Name your league and choose a display name for yourself.'
          : 'Come up with a name for your league below.\nYou’ll be able to invite friends after.'}
      </DText>
      <Margin height={10} />
      <TextBox
        label={'League Name'}
        value={leagueName}
        onChangeText={setLeagueName}
        width={props.width - 32}
      />
      <Margin height={10} />
      <TextBox
        label={'Leaderboard Display Name'}
        value={userName}
        onChangeText={setUserName}
        width={props.width - 32}
      />
      <Margin height={32} />
      <View
        style={{ justifyContent: 'space-between', flexDirection: 'row', width: props.width - 32 }}
      >
        <CtaButton
          outline={true}
          width={buttonWidth}
          active={true}
          text={'Go Back'}
          onPress={() => setShowSelectLeagueType(true)}
        />
        <CtaButton
          onPress={() => createLeague()}
          width={buttonWidth}
          active={!!(leagueName && userName)}
          text="Create League"
        />
      </View>
      <Margin height={16} />
      <BiggerTouchableOpacity
        disabled={submitting}
        onPress={props.onCancel}
        style={{
          position: 'absolute',
          top: 12,
          right: 8,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Close height={18} width={18} tintColor={'white'}></Close>
      </BiggerTouchableOpacity>
      {submitting && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: Colors.betchaBlack80Percent,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator animating={true} size={'large'} color={Colors.white} />
        </View>
      )}
      {errorMessageForCreate && renderErrorMessageForCreate(errorMessageForCreate)}
    </View>
  );
};
