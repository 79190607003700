const tintColor = '#2f95dc';

export default {
  tintColor,
  appleBlue: 'rgb(14, 122, 254)',
  transparentBackground: 'rgba(0,0,0,.20)',
  mainBlack: '#001526',
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  lightGray: '#edf2f6',
  textBlack: '#001526',
  textWhite: '#ffffff',
  lightTextBlack: '#515456',
  primaryGreenBackground: '#01aa5d',
  betGreen: '#00c382',
  textDarkGray: '#818181',
  quickBetBorderColor: '#064120',
  disableText: '#d0d0d0',
  darkText: '#064120',
  darkBlackText: '#303336',
  inactiveTab: '#d7dee3',
  lightWhite: '#f4f5f6',
  logoBorder: '#e0e1e1',
  darkGreenBorder: '#009b67',
  buttonBlack: '#131415',
  coolGrey1: '#eaeef0',
  coolGrey2: '#d4d8d9',
  coolGrey3: '#b8bbbd',
  coolGrey4: '#9c9e9f',
  coolGrey5: '#808283',
  coolGrey6: '#616363',
  coolGrey7: '#242525',
  coolGrey8: '#1c1d1d',
  coolGrey9: '#717a82',
  userNameGray: '#b1b9bf',
  newBorderGray: '#dfdfdf',
  backgroundGrey: '#e5e9ed',
  quickBetGreen: '#00e599',
  redError: '#de2b2b',
  lineBorder: '#e9e9e9',
  strawberry: '#e93d6c',
  titleLight: 'white',
  titleLightOpaque: 'rgba(255,255,255,.7)',

  purple: '#783c93', // 120 60 147
  blue: '#438aff',
  confirmBetPurple: '#54435c',
  gradientTitlePurple: '#563d61',
  gradientGreenStart: '#24ab83',
  gradientGreenEnd: '#19f5a9',
  buttonWhite: '#f8f8f8',
  darkPurple: '#4c3058', // 77 49 89
  green: '#1ed69f',
  brightGreen: '#19f5a9',
  darkGreen: '#168a67',
  red: '#de2b2b',
  darkRed: '#cf3a30',
  black: '#26172d',
  white: '#ffffff',
  grey500Trans: 'rgba(110,95,117,.4)',
  grey900: '#1d2939',
  grey800: '#273446',
  grey700: '#354152',
  grey600: '#3e4a5b',
  grey500: '#505b6b',
  grey400: '#5c6676',
  grey300: '#d4d2d9',
  newGrey300: '#667080',
  grey200: '#757f8d',
  grey100: '#7f8a98',
  greyText: '#9097a2',
  cloud300: '#ddd9d9',
  cloud200: '#ece9e9',
  cloud100: '#f5f5f5',
  cloud700: '#354152',
  betchaBlack: '#001526',
  betchaBlack95Percent: 'rgba(0,21,38,.95)',
  betchaBlack80Percent: 'rgba(0,21,38,.80)',
  betchaBlack40Percent: 'rgba(0,21,38,.40)',
  betchaAqua: '#32eee2',
  betchaYellow: '#febf53',
  lightEggplant: '#75448b',
  purple500: '#5c4a65',
  yellow: '#ffc04c',
  yellow30: 'rgba(254,191,83,.3)',
  yellowProcessing: '#fff099',
  greenProcessing: '#bef2cd',
  redProcessing: '#FFB1BA',
  betchaOrange: '#ff4b38',
  darkGrey: '#152133',
  inActiveGrey: '#666f7d',
  grey: '#b1bcca',
  lightGrey: '#e8ecf2',
  greyTime: '#8a8f98',
  brown: '#fa7c50',
  followGrey: '#b1bcca',
  betchaPurple: '#5353c2',
  lineSeparator: '#273446',
  prizeoutBlue: '#00A7FF',
  magenta400: '#CE3197',
  lightPurple: '#8094DB',
  white10: '#343854', // 'rgba(255,255,255,.1)',
  white20: '#484E67', //'rgba(255,255,255,.2)',
  white50: '#8E91A0', //'rgba(255,255,255,.5)'
  newBlue: '#277cf4',
  newGreen: '#4ac76d',
  primaryGrey: '#b1bcca',
  lostBlack: '#343754',
  pointNotActive: '#484C65',
  cardColor: '#343854',
  tieColor: '#FFC000',
  questionWrong: '#752c3e',
  questionWrongHighlight: '#f93c3a',
  questionRight: '#2f6453',
  questionRightHighlight: '#4ac76d',
  borderBlue: '#75aaf6',
  textBlue: '#28aef0',

  // New GameCenter colors

  gameCenterQuestionWrong: 'rgba(249, 60, 58, 1)',
  gameCenterQuestionWrongHighlight: 'rgba(249, 60, 58, 0.5)',
  gameCenterQuestionRight: 'rgba(74, 199, 109, 1)',
  gameCenterQuestionRightHighlight: 'rgba(74, 199, 109, 0.5)',
};
