import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { Animated, ViewStyle } from 'react-native';
type FadeInViewProps = PropsWithChildren<{ style: ViewStyle; question: string, direction: 'forward' | 'backward', type: 'pull' | 'tap', offset: number}>;

export const SlideInView: React.FC<FadeInViewProps> = (props) => {
  const slideAnimation = useRef(new Animated.Value(props.offset)).current; // Initial value for opacity: 0
  
  const fromToValue = useMemo(() => {
    if (props.direction === 'backward') return [650, -650]
    else return [-650, 650]
  }, [props.direction]);

  useEffect(() => {
    if (props.type === 'tap') {
      Animated.timing(slideAnimation, {
        toValue: fromToValue[0],
        duration: 200,
        useNativeDriver: true,
      }).start(() =>
        Animated.timing(slideAnimation, {
          toValue: fromToValue[1],
          duration: 0,
          useNativeDriver: true,
        }).start(() => {
          Animated.timing(slideAnimation, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
          }).start();
        }),
      );
    }
    if (props.type === 'pull') {
      // Change the value of slideAnimation to the offset that comes from the panResponder direction value
      Animated.timing(slideAnimation, {
        toValue: props.offset,
        duration: 0,
        useNativeDriver: true,
      }).start();
    }
  }, [slideAnimation, props.question, fromToValue, props.type, props.offset]);

  return (
    <Animated.View
      style={{
        ...props.style,
        left: slideAnimation,
      }}
    >
      {props.children}
    </Animated.View>
  );
};
