/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import BiggerTouchableOpacity from '../components/BiggerTouchableOpacity';
import DText from './DText';
import { ActivityIndicator, Modal, StyleSheet, View } from 'react-native';
import { colorPalette } from '../themes/variables';
import { Check, Close, Copy, Money } from '../components/images';
import Fonts from '../constants/Fonts';
import Margin from './Margin';
import Initials from './Initials';
import { useQuery } from '@tanstack/react-query';
import { getPrizesV2, getUserV2, postRedemption } from '../api';
import { ErrorPage } from './ErrorPage';
import { Prize, RedeemPrizeResponse, User } from '../interfaces';
import Colors from '../constants/Colors';
import Utils from '../Utils';
import { WindowContext, queryClient } from '../App';
import { VSProgressBar } from './VSProgressBar';

export function TokenCard() {
  const [redeemVisible, setRedeemVisible] = useState(false);

  const toggleVisible = useCallback(() => {
    setRedeemVisible((prev) => !prev);
  }, []);

  return (
    <BiggerTouchableOpacity
      style={{ width: '100%' }}
      onPress={() => {
        setRedeemVisible(true);
      }}
    >
      <TokenCardDetails />
      {redeemVisible && <RedeemTokensModal toggleVisible={toggleVisible} />}
    </BiggerTouchableOpacity>
  );
}

function TokenCardDetails() {
  const windowValues = useContext(WindowContext);
  const {
    isLoading: isUserLoading,
    isError: isUserError,
    error,
    data: user,
  } = useQuery<User, Error, User, string[]>({
    queryKey: ['User', windowValues.accountId],
    queryFn: getUserV2(windowValues),
    initialData: {} as User,
  });

  const {
    isLoading: isPrizesLoading,
    isError: isPrizesError,
    error: prizesError,
    data: prizes,
  } = useQuery<Prize[], Error, Prize[], string[]>({
    queryKey: ['Prizes', windowValues.accountId],
    queryFn: getPrizesV2(windowValues),
  });

  if (isUserLoading || isPrizesLoading) {
    return (
      <View style={{ margin: 8 }}>
        <ActivityIndicator />
      </View>
    );
  }

  if (isUserError || isPrizesError) {
    return <ErrorPage error={error?.message || prizesError?.message || 'Unknown error'} />;
  }

  const hasRedeemablePrize = prizes?.some((prize) => prize.cost <= user.pointsBalance);
  const color = hasRedeemablePrize ? colorPalette.yellow['800'] : Colors.white50;

  return (
    <View
      style={{
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <View
        style={{
          paddingTop: 16,
          paddingRight: 16,
          paddingLeft: 16,
          paddingBottom: 32,
          borderRadius: 8,
          backgroundColor: 'rgba(255,255,255,.1)',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          overflow: 'hidden',
          minHeight: 100,
          marginBottom: 10,
        }}
      >
        <View
          style={{
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              width: '100%',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {/* Note: We no longer have initials from our new DB */}
              <Initials initials={''} />
              <View
                style={{
                  height: 24,
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  borderRadius: 5,
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <DText
                    fontFamily={'GT-Walsheim-Black'}
                    fontSize={Fonts.font24}
                    fontWeight={900}
                    color={colorPalette.yellow['800']}
                  >
                    {user?.pointsBalance}
                  </DText>
                  <Margin width={4} />
                  <Money
                    style={{ marginBottom: 2 }}
                    height={16}
                    width={16}
                    tintColor={colorPalette.yellow['800']}
                  />
                </View>
                <DText fontSize={Fonts.font12} fontWeight={900} color={colorPalette.yellow['800']}>
                  {'TOKENS'}
                </DText>
              </View>
            </View>
            <View
              style={{
                borderRadius: 4,
                borderWidth: 1,
                borderColor: color,
                paddingHorizontal: 12,
                paddingVertical: 6,
              }}
            >
              <DText
                color={color}
                style={{
                  textAlign: 'center',
                  color: color,
                  fontSize: Fonts.font14,
                  fontWeight: 900,
                }}
              >
                {'Choose Prize'}
              </DText>
            </View>
          </View>
        </View>
        <Margin height={8} />
        <ProgressBar user={user} />
      </View>
    </View>
  );
}

export function RedeemTokensModal({
  toggleVisible,
  alternateTitle,
}: {
  toggleVisible: () => void;
  alternateTitle?: string;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const tandcLink = 'https://gamecenter.vividseats.com/rewards';

  const windowValues = useContext(WindowContext);

  const {
    isLoading: isUserLoading,
    isError: isUserError,
    error,
    data: user,
  } = useQuery<User, Error, User, string[]>({
    queryKey: ['User', windowValues.accountId],
    queryFn: getUserV2(windowValues),
    initialData: {} as User,
  });

  const {
    isLoading: isPrizesLoading,
    isError: isPrizesError,
    error: prizesError,
    data: prizes,
  } = useQuery<Prize[], Error, Prize[], string[]>({
    queryKey: ['Prizes', windowValues.accountId],
    queryFn: getPrizesV2(windowValues),
  });

  if (isUserLoading || isPrizesLoading) {
    return (
      <View style={{ margin: 8 }}>
        <ActivityIndicator />
      </View>
    );
  }

  if (isUserError || isPrizesError) {
    <ErrorPage error={error?.message || prizesError?.message || 'Unknown error'} />;
  }

  const someTokensRedeemable = prizes?.some((prize) => prize.cost <= user.pointsBalance);

  const [selectedPrize, setSelectedPrize] = React.useState<Prize | null>(null);

  const [redemption, setRedemption] = React.useState<RedeemPrizeResponse | null>(null);

  useEffect(() => {
    setSubmitError(null);
  }, [selectedPrize]);

  const submitPrizeRequest = useCallback(async () => {
    function refreshPage() {
      queryClient.refetchQueries(['User']);
      queryClient.refetchQueries(['ChallengeHistoryData']);
      queryClient.refetchQueries(['RedemptionHistoryData']);
    }
    try {
      if (selectedPrize) {
        setSubmitting(true);
        const result = await postRedemption(windowValues)({ prize: selectedPrize });
        setSubmitting(false);
        if (result) {
          refreshPage();
          setSelectedPrize(null);
          setRedemption(result);
        } else {
          setSubmitError(
            'Something went wrong when trying to redeem your prize. Please try again or contact support',
          );
          refreshPage();
          setRedemption(null);
        }
      } else {
        return;
      }
    } catch (e: any) {
      console.error(e);
      setRedemption(null);
      setSubmitError(
        'Something went wrong when trying to redeem your prize. Please try again or contact support',
      );
      setSubmitting(false);
    }
  }, [selectedPrize, windowValues]);

  return (
    <Modal
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,.2)',
        alignSelf: 'center',
      }}
      animationType={'fade'}
      transparent={true}
      onRequestClose={toggleVisible}
    >
      <View
        style={{
          alignItems: 'center',
          width: '100%',
          height: '100%',
          maxWidth: 475,
          paddingTop: 90,
          backgroundColor: 'rgba(0, 21, 38, 0.8)',
          alignSelf: 'center',
        }}
      >
        <View style={{ width: '100%', paddingHorizontal: 16 }}>
          <View style={[styles.container, { backgroundColor: '#1d2141', borderRadius: 4 }]}>
            <DText
              lineHeight={'150%'}
              style={{ paddingHorizontal: 16 }}
              fontSize={Fonts.font20}
              fontWeight={900}
              center={true}
              marginTop={40}
              marginBottom={12}
              color="white"
            >
              {alternateTitle || 'Redeem Your Tokens'}
            </DText>
            <DText
              lineHeight={'150%'}
              style={{ paddingHorizontal: 16 }}
              fontSize={Fonts.font14}
              marginBottom={24}
              center={true}
              color="white"
            >
              Tokens are the sum of all the entries and bonuses <br /> you&apos;ve earned during
              game play.
            </DText>
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <View style={{ flexDirection: 'row' }}>
                <DText
                  fontFamily={'GT-Walsheim-Black'}
                  fontSize={Fonts.font32}
                  fontWeight={900}
                  color={colorPalette.yellow['800']}
                >
                  {user?.pointsBalance || '-'}
                </DText>
                <Margin width={2} />
                <Money
                  style={{ marginBottom: 2 }}
                  height={22}
                  width={22}
                  tintColor={colorPalette.yellow['800']}
                />
              </View>
              <Margin width={4} />
              <DText fontSize={Fonts.font16} fontWeight={900} color={colorPalette.yellow['800']}>
                TOKENS
              </DText>
            </View>
            {someTokensRedeemable ? (
              <DText
                lineHeight={'150%'}
                style={{ paddingLeft: 16, paddingRight: 16 }}
                fontSize={Fonts.font14}
                center={true}
                color="white"
              >
                {'Redeem for ticket credits to your next event\nwithin the Vivid Seats app.'}
              </DText>
            ) : (
              <DText
                style={{ paddingLeft: 16, paddingRight: 16 }}
                fontSize={Fonts.font14}
                center={true}
                color="white"
              >
                {'Keep playing games and earning tokens to\nunlock ticket credits.'}
              </DText>
            )}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 8,
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 24,
                marginBottom: 24,
                paddingHorizontal: 16,
                width: '100%',
                flexWrap: 'wrap',
              }}
            >
              {prizes
                ?.sort((a, b) => a.cost - b.cost)
                .map((prize) => {
                  return (
                    <PrizeButton
                      key={prize.id}
                      active={user.pointsBalance >= prize.cost}
                      prize={prize}
                      setSelectedValue={setSelectedPrize}
                      selected={selectedPrize?.id === prize.id}
                    />
                  );
                })}
            </View>
            {selectedPrize && (
              <DText
                lineHeight={'150%'}
                style={{ paddingLeft: 16, paddingRight: 16 }}
                fontSize={Fonts.font14}
                center={true}
                color="white"
              >
                {`Redeem ${selectedPrize.cost} tokens for $${
                  selectedPrize.value
                } off your next ticket purchase of ${selectedPrize.minPurchase.toLocaleString(
                  'en-US',
                  { style: 'currency', currency: 'USD' },
                )} or more (before taxes and fees).`}
              </DText>
            )}
            {redemption && (
              <PromoCode
                toggleVisible={toggleVisible}
                prizes={prizes || []}
                redemption={redemption}
              />
            )}
            <View
              style={{
                paddingHorizontal: 12,
                width: '100%',
                marginVertical: 8,
              }}
            >
              <BiggerTouchableOpacity
                disabled={!selectedPrize || submitting}
                onPress={submitPrizeRequest}
                style={{
                  height: 48,
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 4,
                  backgroundColor: selectedPrize ? colorPalette.yellow.yellow : Colors.white10,
                  paddingVerical: 6,
                  paddingHorizontal: 12,
                  flexDirection: 'row',
                }}
              >
                {submitting ? (
                  <ActivityIndicator style={{ marginRight: 12 }} color="white" size={'small'} />
                ) : (
                  <DText
                    color={selectedPrize ? colorPalette.white['800'] : colorPalette.neutral['200']}
                    fontWeight={600}
                    fontSize={Fonts.font14}
                  >
                    {'Redeem Prize'}
                  </DText>
                )}
              </BiggerTouchableOpacity>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginVertical: 4,
              }}
            >
              <DText
                style={{
                  textAlign: 'center',
                }}
                color={Colors.red}
                fontSize={Fonts.font14}
              >
                {submitError}
              </DText>
            </View>
            <View
              key="tandc"
              style={{
                width: '100%',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <BiggerTouchableOpacity
                disabled={submitting}
                style={{
                  borderBottomColor: colorPalette.neutral['200'],
                  borderBottomWidth: 0.5,
                  paddingBottom: 4,
                }}
                onPress={() => {
                  window.open(tandcLink);
                }}
              >
                <DText
                  style={{ position: 'relative', top: 4 }}
                  fontSize={Fonts.font12}
                  color={colorPalette.neutral['200']}
                >
                  See all terms and conditions.
                </DText>
              </BiggerTouchableOpacity>
            </View>
            <Margin height={24} />
          </View>
          <BiggerTouchableOpacity
            disabled={submitting}
            onPress={toggleVisible}
            style={{
              position: 'absolute',
              top: 12,
              right: 32,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Close height={18} width={18} tintColor={'white'}></Close>
          </BiggerTouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

function PrizeButton({
  selected,
  active,
  prize,
  setSelectedValue,
}: {
  selected: boolean;
  active: boolean;
  prize: Prize;
  setSelectedValue: (prize: Prize) => void;
}) {
  const setSelectedPrize = useCallback(() => {
    setSelectedValue(prize);
  }, [prize, setSelectedValue]);
  return (
    <BiggerTouchableOpacity
      disabled={!active}
      onPress={setSelectedPrize}
      style={{
        width: 100,
        height: 51,
        opacity: active ? 1 : 0.4,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,.1)',
      }}
    >
      <DText style={{ lineHeight: '150%' }} fontSize={Fonts.font14} fontWeight={900} color="white">
        {'$' + prize.value + ' Off'}
      </DText>
      <DText
        style={{ lineHeight: '150%' }}
        fontSize={Fonts.font12}
        fontWeight={900}
        color="rgba(255,255,255,.7)"
      >
        {prize.cost + ' Tokens'}
      </DText>
      {selected && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 100,
            height: 52,
            borderColor: colorPalette.yellow.yellow,
            borderRadius: 4,
            borderWidth: 2,
          }}
        >
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: 10,
              height: 10,
              justifyContent: 'center',
              alignItems: 'center',
              borderBottomRightRadius: 4,
              backgroundColor: colorPalette.yellow.yellow,
              overflow: 'hidden',
            }}
          >
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: colorPalette.yellow.yellow,
              }}
            >
              <Check
                style={{ position: 'relative', left: -1, top: -2 }}
                width={10}
                height={10}
                tintColor={Colors.betchaBlack}
              />
            </View>
          </View>
        </View>
      )}
    </BiggerTouchableOpacity>
  );
}

function PromoCode({
  toggleVisible,
  redemption,
  prizes,
}: {
  toggleVisible: () => void;
  redemption: RedeemPrizeResponse;
  prizes: Prize[];
}) {
  const prize = prizes.find((p: Prize) => p.id === redemption.prizeId);
  return (
    <Modal
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,.2)',
        alignSelf: 'center',
      }}
      animationType={'fade'}
      transparent={true}
      onRequestClose={toggleVisible}
    >
      <View
        style={{
          alignItems: 'center',
          width: '100%',
          height: '100%',
          maxWidth: 475,
          paddingTop: 90,
          backgroundColor: 'rgba(0, 21, 38, 0.8)',
          alignSelf: 'center',
        }}
      >
        <View style={{ width: '100%', paddingHorizontal: 16 }}>
          <View
            style={[
              styles.container,
              { backgroundColor: '#1d2141', borderRadius: 4, paddingHorizontal: 12 },
            ]}
          >
            <BiggerTouchableOpacity
              onPress={toggleVisible}
              style={{
                position: 'absolute',
                top: 12,
                right: 12,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Close height={18} width={18} tintColor={'white'}></Close>
            </BiggerTouchableOpacity>
            <Margin height={48} />
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                display: 'flex',
                gap: 8,
              }}
            >
              <DText
                style={{ wordBreak: 'break-all' }}
                fontSize={Fonts.font24}
                fontWeight={900}
                color={colorPalette.neutral['50']}
              >
                {redemption.code}
              </DText>

              <BiggerTouchableOpacity
                onPress={() => {
                  Utils.copyTextToClipboard(redemption.code);
                }}
                activeOpacity={0.5}
                style={{
                  borderRadius: 4,
                  borderWidth: 1,
                  borderColor: 'white',
                  flexDirection: 'row',
                  width: 84,
                  height: 25,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Copy width={14} height={14} />
                <Margin width={4} />
                <DText fontWeight={600} fontSize={Fonts.font14} color="white">
                  Copy
                </DText>
              </BiggerTouchableOpacity>
            </View>
            <Margin height={26} />
            <DText
              style={{ lineHeight: '150%' }}
              center={true}
              color="white"
              fontWeight={600}
              fontSize={Fonts.font14}
            >
              {`Use this code to receive ${prize?.value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })} off your next ticket\npurchase of ${prize?.minPurchase.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })} or more (before taxes and fees).`}
            </DText>
            <Margin height={24} />
            <DText
              style={{ lineHeight: '150%' }}
              center={true}
              color="white"
              fontWeight={600}
              fontSize={Fonts.font14}
            >
              {
                'You’ll be able to find this code any time under the\n“History” tab in the Game Center'
              }
            </DText>
            <Margin height={24} />
            <DText
              style={{ lineHeight: '150%' }}
              center={true}
              color="white"
              fontWeight={600}
              fontSize={Fonts.font14}
            >
              {'Expires: ' +
                Utils.shortDateFormat(
                  redemption.expiresAt ? new Date(redemption.expiresAt) : new Date(),
                )}
            </DText>
            <Margin height={16} />
            <BiggerTouchableOpacity
              onPress={() => {
                const deeplink =
                  'vividseats://home?utm_source=game_center&utm_campaign=game_center&utm_promo=##PROMOCODE'.replace(
                    '##PROMOCODE',
                    redemption.code,
                  );
                location.href = deeplink;
              }}
              style={{
                height: 48,
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
                backgroundColor: colorPalette.indigo['400'],
                paddingVertidal: 6,
                paddingHorizontal: 12,
                flexDirection: 'row',
              }}
            >
              <DText color={'white'} fontWeight={600} fontSize={Fonts.font14}>
                Shop For Tickets ➜
              </DText>
            </BiggerTouchableOpacity>
            <Margin height={16} />
            <BiggerTouchableOpacity
              onPress={toggleVisible}
              style={{
                height: 48,
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
                backgroundColor: 'transparent',
                borderWidth: 1,
                borderColor: Colors.borderBlue,
                paddingVertical: 6,
                paddingHorizontal: 12,
                flexDirection: 'row',
              }}
            >
              <DText color={Colors.borderBlue} fontWeight={600} fontSize={Fonts.font16}>
                Back to Game Center
              </DText>
            </BiggerTouchableOpacity>
            <Margin height={16} />
          </View>
        </View>
      </View>
    </Modal>
  );
}

function ProgressBar({ user }: { user: User }) {
  const windowValues = useContext(WindowContext);
  const {
    isLoading: isPrizesLoading,
    isError: isPrizesError,
    error: prizesError,
    data: prizes,
  } = useQuery<Prize[], Error, Prize[], string[]>({
    queryKey: ['Prizes', windowValues.accountId],
    queryFn: getPrizesV2(windowValues),
  });

  if (isPrizesError) {
    console.error(prizesError);
    return null;
  }
  if (isPrizesLoading) {
    return <ActivityIndicator />;
  }

  if (!prizes?.filter(Boolean).length) return null;

  if (!prizes) {
    return (
      <DText
        center={true}
        fontSize={Fonts.font14}
        color={colorPalette.neutral['200']}
        fontWeight={900}
      >
        {'No Prizes Available'}
      </DText>
    );
  }

  const sortedPrizes = prizes.sort((a, b) => a.cost - b.cost);

  const nextPrizeAmount = sortedPrizes.find((prize) => prize.cost > user.pointsBalance)?.cost;
  const pointsToNextPrize = (nextPrizeAmount || 0) - user.pointsBalance;

  const progress =
    (user.pointsBalance - (500 + (user.pointsBalance / prizes[prizes.length - 1].cost) * 20)) /
    1000;

  return (
    <View style={{ display: 'flex', flexDirection: 'column', gap: 4, width: '100%' }}>
      <DText fontSize={Fonts.font16} color={Colors.white} fontWeight={900}>
        {'TICKET CREDITS UNLOCKED'}
      </DText>
      <DText fontSize={Fonts.font14} color={colorPalette.neutral[200]}>
        {nextPrizeAmount ? `${pointsToNextPrize} Tokens To Next Prize` : 'Redeem a prize now!'}
      </DText>
      <VSProgressBar
        progressTiers={[
          ...sortedPrizes.map((prize) => ({
            marker: { value: '•', color: colorPalette.neutral[800] },
            label: { value: '$' + prize.value.toString(), color: Colors.white },
          })),
        ]}
        progress={progress}
        height={16}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 475,
  },
});
