import { Dimensions, PixelRatio } from 'react-native'

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height

export default {
	window: {
		width,
		height
	},
	isSmallDevice: (PixelRatio.get() <= 2 && height < 736) || height / width < 1.9,

	screenWidth: width,
	screenHeight: height,
	backBarHeight: 40,
	toolBarHeight: 60,
	bottomNotchHeight: 0,
	topNotchHeight: 0
}
