import { BrowseTicketsCard } from './BrowseTicketsCard';
import { ChallengeList } from './ChallengeList';
import { RedeemCard } from '../components/lite/RedeemComponent';
import { TokenCard } from '../components/TokenCard';
import { PromotionCard } from '../components/PromotionCard';
import { useQuery } from '@tanstack/react-query';
import { getChallengesV2 } from '../api';
import { ErrorPage } from '../components/ErrorPage';
import { LoadingPage } from '../components/LoadingPage';
import { Challenge } from '../interfaces';
import Fonts from '../constants/Fonts';
import DText from './DText';
import { useContext } from 'react';
import { WindowContext } from '../App';
import { isAuthValid } from '../Utils';

export function GameCenterGames() {
  const windowValues = useContext(WindowContext);
  const authValid = isAuthValid(windowValues);

  const {
    isLoading: challengesLoading,
    isError: isChallengesError,
    error,
    data: challenges,
  } = useQuery<Challenge[], Error, Challenge[], (string | undefined)[]>({
    queryKey: [
      'GameCenterData-Challenges',
      '20',
      undefined,
      undefined,
      'LITE',
      windowValues.accountId,
    ],
    queryFn: getChallengesV2(windowValues),
  });

  if (challengesLoading) {
    return <LoadingPage />;
  }

  if (isChallengesError) {
    if (!authValid) {
      return <ErrorPage error={'Please make sure you are logged in.'} />;
    }
    return <ErrorPage error={error?.message || 'Unknown error'} />;
  }

  const playedGames = challenges.map((challenge) => {
    const allGames =
      challenge.games &&
      challenge.games.sort((a, b) => {
        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
      });
    // The playable games are those who are active
    const playableGames = allGames?.filter((game) => {
      return game.active === true;
    });
    // Return the first game that has no plays
    const playableGame = playableGames?.find((game) => {
      return game.plays?.length === 0;
    });

    // Check if they've played at least one game in the challenge
    const hasAtLeastOnePlayedGame = allGames?.some((game) => {
      return game.plays?.length > 0;
    });

    // Played games are games where there is NOT a playable game BUT there has been at least one played game
    // If there is NOT a playable game and they've played at least one game, return the challenge for the Played Games section.
    if (!playableGame && hasAtLeastOnePlayedGame && challenge.active) {
      return challenge;
    }
  });

  const availableGames = challenges
    .map((challenge) => {
      const allGames =
        challenge.games &&
        challenge.games.sort((a, b) => {
          return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
        });
      // The playable games are those who are active challenge.games[0].startDate < new Date()
      const playableGames = allGames?.filter((game) => {
        if (challenge.definition.featuredLeagueAdminId) {
          return true;
        }
        return game.active === true;
      });
      // Return the first game that has no plays
      const playableGame = playableGames?.find((game) => {
        return game.plays?.length === 0;
      });

      const activeGame = playableGames?.find((game) => {
        return game.active === true;
      });

      // if the current active game has beeen played return false
      if (activeGame && activeGame.plays.length > 0) {
        // return;
      }
      if (playableGame && challenge.active) {
        // If there is a playable game, return the challenge for the Available Games section
        return challenge;
      }
    })
    .filter((challenge) => {
      if (playedGames.includes(challenge)) {
        return;
      }
      return challenge;
    });

  return (
    <>
      <DText color="white" fontSize={Fonts.font16} marginBottom={16}>
        Select a game to earn entries toward the drawing for that game's grand prize as well as
        tokens you can redeem for ticket credit.
      </DText>
      <ChallengeList title={'AVAILABLE GAMES'} data={availableGames} />
      <TokenCard />
      <RedeemCard />
      <BrowseTicketsCard />
      <ChallengeList title={'PLAYED GAMES'} data={playedGames} />
      <PromotionCard />
    </>
  );
}
